import React, { useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useNavigate } from "react-router-dom";
import Link from '@material-ui/core/Link';
import { BlackTriangleShowMore } from "../../shared/BlackTriangle";
import PIInfo from '../../shared/PIInfo';
import {ContentService} from '../Service/ContentService'
import Thumbnail from '../../shared/Thumbnail';
 const RelatedContent = ({relatedVideos, SetPositionMainPlayer, setVideoEnd, filter, translations, contentType}) => {
    const [hasShowMore, sethasShowMore] = React.useState(false);
    const [firstLoadRelatedContent, setFirstLoadRelatedContent] = React.useState([]);
    const navigate = useNavigate();

    const showMoreRelatedContent = () => {

        let firstLoadData = [...relatedVideos];
        setFirstLoadRelatedContent(firstLoadData)

        sethasShowMore(false)
    }

    const contentClickHandler = useCallback((content) => {
        const contentService = new ContentService();
    
        const isPlaylist = content.content_type === 'playlist';
        const contentURL = isPlaylist 
            ? `/content-watch/${content.kaltura_id}-${content.playlist_id}/${content.playlist_id}` 
            : `/content-watch/${content.kaltura_id}/${content.content_id}`;
        const contentId = isPlaylist ? content.playlist_id : content.content_id;

        SetPositionMainPlayer(0);
        setVideoEnd(false);
        contentService.VideoSelectedGAEvent(contentId, null);
        contentService.NavigatedToVideoFromContentPageEvent(contentId, 'Navigated to video from another content page', filter);
        navigate(contentURL)
    }, [filter, navigate, SetPositionMainPlayer, setVideoEnd]);

    React.useEffect(() => {
        let relatedVideoCount = [...relatedVideos];

        let firstLoadData = [];
        if (relatedVideoCount.length > 6) {
            sethasShowMore(true);
            for (let i = 0; i < 6; i++) {
                firstLoadData.push(relatedVideoCount[i])
            }
        }
        else {
            sethasShowMore(false);
            firstLoadData = relatedVideoCount;
        }
        setFirstLoadRelatedContent(firstLoadData)
    }, [relatedVideos]);



    return (
        <>
            <Grid>
                <Grid className='related_content-wrapper'>
                    <Paper className='shared-topTag'>{translations['Related Content'] ? translations['Related Content'] : 'Related Contents'}</Paper>
                    {firstLoadRelatedContent.length ? firstLoadRelatedContent.map((content, index) => {
                        let contentTitle = content.content_type === 'video' ? content.title :
                            content.content_type === 'playlist' ? content.title :
                                content.content_type === 'podcast' ? content.title : null;
                        let contentSpeakers = content.speakers_id ? content.speakers_id.join(' ') : '';

                        return (
                            <div key={index} className='related-content-card'>
                                <div className='related-content-container'>
                                    <div>
                                        <Thumbnail content={content} onClick={() => contentClickHandler(content)} />
                                    </div>
                                    <div className='related-contents-pl10'>
                                        <div>
                                            <Paper className={'custom-shared-video-tag shared-' + content.content_type + 'Tag-content'}>{content.content_type}
                                            </Paper>
                                        </div>
                                        <div className='medium-device-contents'>
                                            <div className='custom-clickableLink shared-cont-title clickableLink' onClick={() => contentClickHandler(content)}>{contentTitle.length > 25 ? <BlackTriangleShowMore title={`${contentTitle.slice(0, 24)}...`} /> : <BlackTriangleShowMore title={contentTitle} />}</div>
                                            <div className='custom-clickableLink shared-upName-content clickableLink' onClick={() => navigate('/speaker-detail/' + contentSpeakers)}>{contentSpeakers.length > 20 ? `${contentSpeakers.slice(0, 19)}...` : contentSpeakers}</div>
                                        </div>
                                        <div className='large-device-contents'>
                                            <div className='custom-clickableLink shared-cont-title clickableLink' onClick={() => contentClickHandler(content)}>{contentTitle.length > 35 ? <BlackTriangleShowMore title={`${contentTitle.slice(0, 30)}...`} /> : <BlackTriangleShowMore title={contentTitle} />}</div>
                                            <div className='custom-clickableLink shared-upName-content clickableLink' onClick={() => navigate('/speaker-detail/' + contentSpeakers)}>{contentSpeakers.length > 40 ? `${contentSpeakers.slice(0, 39)}...` : contentSpeakers}</div>
                                        </div>
                                        <div className='small-device-contents'>

                                            <div className='custom-clickableLink shared-cont-title clickableLink' onClick={() => contentClickHandler(content)}>{contentTitle.length > 30 ? <BlackTriangleShowMore title={`${contentTitle.slice(0, 30)}...`} /> : <BlackTriangleShowMore title={contentTitle} />}</div>
                                            <div className='custom-clickableLink shared-upName-content clickableLink' onClick={() => navigate('/speaker-detail/' + contentSpeakers)}>{contentSpeakers.length > 29 ? `${contentSpeakers.slice(0, 28)}...` : contentSpeakers}</div>

                                        </div>
                                        {content?.category_1_id?.length ?
                                            <div className="cont-piinfo">
                                                <PIInfo category1Ids={content.category_1_id} />
                                            </div>
                                            : ""}
                                    </div>

                                </div>
                            </div>

                        )
                    }) : <Grid className='shared-cont-no-data'>{translations['There is no content related to this ' + contentType] ? translations['There is no content related to this ' + contentType] : 'There is no content related to this ' + contentType}</Grid>}
                    {hasShowMore ? <Grid item lg={6} xs={12} sm={6} className=''>
                        <Link onClick={() => showMoreRelatedContent()} className='showBtn'>{translations['Show more'] ? translations['Show more'] : 'Show more'}</Link>
                    </Grid> : ""}
                </Grid>
            </Grid>
        </>
    )
}

export default RelatedContent