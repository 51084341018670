
import React, { useState, useEffect, Fragment } from 'react';
import arrowLeft from '../../../public/img/arrow-left.svg';
import arrowRight from '../../../public/img/arrow-right.svg';
import arrowLeftDisabled from '../../../public/img/arrow-left-disabled.svg';
import arrowRightDisabled from '../../../public/img/arrow-right-disabled.svg';

const ButtonPagination = ({ count, page, onPageChange, totalLength }) => {
    const [currentPage, setCurrentPage] = useState(page);
    const [totalPages, setTotalPages] = useState(count);

    const calculateDisplayPages = (currentPage, totalPages) => {
        let pages = [];
        const totalVisiblePages = 5;
        if (totalPages <= totalVisiblePages) {
            pages = Array.from({ length: totalPages }, (_, i) => i + 1);
        } else {
            const startPage = currentPage + 1;// Math.max(1, currentPage - 2);
            const endPage = Math.min(totalPages, startPage + 4);
            if (startPage > 1) {
                pages.push('...');
            }
            if (currentPage >= totalPages - 4) {
                pages = [...pages, ...Array.from({ length: 5 }, (_, i) => totalPages - 4 + i)];
            } else {
                pages = [...pages, ...Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i)];
            }
            if (endPage < totalPages) {
                pages.push('...');
            }
        }
        return pages;
    }
    const [displayPages, setDisplayPages] = useState([]);
    useEffect(() => {
        const newDisplayPages = calculateDisplayPages(page, count);
        setDisplayPages(newDisplayPages)
        setCurrentPage(page);
        setTotalPages(count)

    }, [page, count])
    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            const newDisplayPages = calculateDisplayPages(newPage, totalPages);
            setDisplayPages(newDisplayPages)
        }
        setCurrentPage(newPage);
        onPageChange(newPage);
    }

    return (
        <div className='pagination-component'>
            {currentPage === 0 ?
                <button
                    className='previous'
                    disabled>
                    <img src={arrowLeftDisabled} alt='previous button arrow' />
                    Previous
                </button> :
                <button
                    className='previous'
                    onClick={() => handlePageChange(currentPage - 1)}>
                    <img src={arrowLeft} alt='previous button arrow' />
                    Previous
                </button>}
            <div className='pages'>
                {
                    displayPages?.map((page) => (
                        <Fragment key={page}>
                            {
                                page == currentPage + 1 ? (
                                    <button
                                        className='current'>
                                        <strong>{currentPage + 1}</strong>
                                    </button>
                                ) : (
                                    <button>
                                        {page}
                                    </button>
                                )
                            }
                        </Fragment>

                    ))
                }
            </div>
            {(currentPage + 1 == totalPages) || (totalLength == 0) ?
                <button
                    className='next'
                    disabled>
                    Next
                    <img src={arrowRightDisabled} alt='next button arrow' />
                </button> :
                <button
                    className='next'
                    onClick={() => handlePageChange(currentPage + 1)}>
                    Next
                    <img src={arrowRight} alt='next button arrow' />
                </button>
            }
        </div>
    )
}
export default ButtonPagination