import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import FilterListIcon from '@material-ui/icons/FilterList';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Breadcrumb from '../../../layout/breadcrumb';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { EventService } from '../Services/event-service';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import { Button } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import '../event.css';
import { useNavigate } from "react-router-dom";
import deleteImg from '../../../public/img/CloseGrey@3x.png';
import deleteImgBlue from '../../../public/img/new-img/RedClose@3x.png';
import FormLabel from '@material-ui/core/FormLabel';
import {ListboxComponent, filterOptions} from '../../shared/SearchOptimize';

// Define Prototype
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  videobuttonremove: {
    backgroundImage: `url(${deleteImg})`,
    height: '20px',
    width: '20px',
    border: '0',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '0px 0px',
    backgroundColor: 'transparent',
    marginLeft: '10px',
    cursor: 'pointer',
    '&:hover': {
      backgroundImage: `url(${deleteImgBlue})`
    }
  }
}));

// Define Data Table Header
const headCells = [
  { id: 'event_id', numeric: false, disablePadding: false, label: 'Event Id', sorting: true },
  { id: 'event_title', numeric: false, disablePadding: false, label: 'Event Title', sorting: true },
  { id: 'event_desc', numeric: false, disablePadding: false, label: 'Event Description', sorting: true },
  { id: 'event_start_time', numeric: false, disablePadding: false, label: 'Event Start Time', sorting: true },
  { id: 'published_country', numeric: false, disablePadding: false, label: 'Published Country', sorting: true },
  { id: 'action', numeric: true, disablePadding: false, label: 'Action', sorting: false },
];

// To Create Data Table Rows
function createData(event_id, event_title, event_desc, event_start_time, published_country, action, owner, second_owner, created_at, countries) {
  return {event_id, event_title, event_desc, event_start_time, published_country, action, owner, second_owner, created_at, countries };
}

// Functions for Apply Sorting of Data Table
function descendingComparator(a, b, orderBy) {
  if(orderBy === 'event_start_time' ){
    let aDate = new Date(a[orderBy]);
    a[orderBy] = aDate.getTime();
    let bDate = new Date(b[orderBy]);
    b[orderBy] = bDate.getTime();
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={!headCell.sorting}
              className={((!headCell.sorting) ? "nonClickable MuiButtonBase-root MuiTableSortLabel-root" : "MuiButtonBase-root MuiTableSortLabel-root")}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className='visuallyHidden'>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// Event Management Component 
export default function EnhancedTable(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, SetRows] = React.useState([]);
  const [eventData, setEventData] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [fetchDataStatus, setFetchDataStatus] = React.useState(false);
  const [userId, SetUserId] = React.useState(1);
  let eventServices = new EventService();

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const BreadcrumbData = [
    { value: props.translations['Home'] ? props.translations['Home'] : 'Home', path: '/home' },
    { value: 'Event Management', path: '/event-management' }
  ];
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    let user_id = userId;
    if (props.selectorValueSession) {
      if (props.selectorValueSession.sessionValue.payload) {
        if (props.selectorValueSession.sessionValue.payload.user) {
          if (props.selectorValueSession.sessionValue.payload.user) {
            user_id = props.selectorValueSession.sessionValue.payload.user.id
            SetUserId(user_id)
          }
        }
      }
    }
  }, [props.selectorValueSession, userId])

  function getCountryCountByStatus(row, status) {
    let countriesStats = row.country_approved;
    let pendingCountry = [];
    let publishedCountry = [];
    let rejectedCountry = [];
    if(countriesStats) {
      if(Object.keys(countriesStats).length > 0) {
        Object.keys(countriesStats).map((countryId,i) => {
          if(countriesStats[countryId].status === "Pending"){
            pendingCountry.push(countriesStats[countryId].name);
          }
          if(countriesStats[countryId].status === "Active"){
            publishedCountry.push(countriesStats[countryId].name);
          }
          if(countriesStats[countryId].status === "Inactive"){
            rejectedCountry.push(countriesStats[countryId].name);
          }
          return true;
        });
      }
    }
    let countries = [];
    if(status === "Published") {
      countries = publishedCountry;
    } else if(status === "Pending") {
      countries = pendingCountry;
    } else if(status === "Rejected") {
      countries = rejectedCountry; 
    }
    return countries;
  }

  function getSetEvents(params = {}) {
    eventServices.GetEventsByQuery(params).then((data) => {
      setFetchDataStatus(true);
      let list = [];
      let events = data.data;
      if (events.length > 0) {
        for (let i = 0; i < events.length; i++) {
          let event = events[i];
          let event_start_date = new Date(event.eventstart);
          event_start_date = event_start_date.getTime();
          let publishedCountryCount = getCountryCountByStatus(event,'Published').length;
          let value = createData(event.event_id, event.title, event.description, event_start_date, publishedCountryCount, event, event.created_by, event.second_owner, new Date(event.updated_at), event.country_codes);
          list.push(value);
        }
      }
      list.sort((a, b) => ((b.created_at ? new Date(b.created_at) : new Date(8640000000000000)) - (a.created_at ? new Date(a.created_at) : new Date(8640000000000000))));
      SetRows(list);
      setEventData(list);
    }).catch((err) => {})
  }

  React.useEffect(() => {
    getSetEvents();
    window.scrollTo(0, 0);
    // To Get All Users
    eventServices.GetAllUsers().then((data) => {
      let jsondata = data.data;
      let arr = []
      for (let i = 0; i < jsondata.length; i++) {
        if (jsondata[i].user_name != null && jsondata[i].user_name !== ''
        && jsondata[i].first_name !== null && jsondata[i].first_name !== '' &&
          jsondata[i].last_name !== null && jsondata[i].last_name !== '') {
          jsondata[i].fullName = `${jsondata[i].first_name} ${jsondata[i].last_name} (${jsondata[i].user_name})`
          arr.push(jsondata[i])
        }
      }
      setUserData(arr);
    }).catch((err) => {});
    // To Get All Countries
    eventServices.GetAllAffiliates().then((data) => {
      let countryData = data.data;
      countryData.sort((a, b) => a.country_name.localeCompare(b.country_name));
      setCountries(countryData);
    }).catch((err) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle Reload Button
  const handleReloadBtn = () => {
    getSetEvents();
    setSearchTerm('');
  };

  // Handele Search
  const [open, setOpen] = React.useState(false);
  const [filteredEvents, setFilteredEvents] = React.useState([]);
  const [newEventTerm, setNewEventTerm] = React.useState('');
  const searchItem = event => {

    if (searchTerm.toString().length > 0) {
      const term = searchTerm;// event_title, event_desc
      let searchTermFromEvent =  term; //event.target.value;
      let eventList = Object.assign([],rows);
      let list = [];
      for(let i=0;i< eventList.length;i++){
        if(eventList[i].event_title.includes(searchTermFromEvent) || 
        eventList[i].event_desc.includes(searchTermFromEvent) ||
        eventList[i].event_id.includes(searchTermFromEvent)){
          
          list.push(eventList[i])
        }
      }
      SetRows(list);
      setSearchTerm(term);
    }
    // getSetEvents({ searchKeyword: term })
  };
  const handleSearchTerm = (e, value) => {
    if (e.keyCode === 13) {
      let term = e.target.value;
      if (value != null) {
        term = value.title;
      }
      setSearchTerm(term);
      searchItem(e);
      // getSetEvents({ searchKeyword: term })
    }
  }
  const [openDelConfirmModal, setopenDelConfirmModal] = React.useState(false);
  const [eventID, setEventID] = React.useState(0);
  const [eventName, setEventName] = React.useState('');

  const handleDelete = () => {
    eventServices.DeleteEvents(eventID).then((data) => {
      getSetEvents();
      setopenDelConfirmModal(false);
    }).catch((err) => {});
  }
  const openDelConfirm = (id, lpId, event_name) => {
    setEventID(lpId)
    setEventName(event_name)
    setopenDelConfirmModal(true);
  };
  const handleDelConfirmClose = () => {
    setopenDelConfirmModal(false);
  };

  // Handle Filter
  const [openFilterModal, setopenFilterModal] = React.useState(false);
  const [countries, setCountries] = React.useState([]);
  const [userData, setUserData] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState('');
  const [selectedUser, setSelectedUser] = React.useState('');
  const handleFilterClose = () => {
    setopenFilterModal(false)
  };
  const handleFilterBtn = () => {
    setopenFilterModal(true)
  };
  const handleFilterReset = () => {
    setSelectedCountry("");
    setSelectedUser("");
  }
  const handleFilterApply = () => {
    let allData = Object.assign([],eventData);
    let fullFilterList = [];
    if (selectedCountry.toString().length > 0) {
      const filteredCountry = allData.filter(elem => {
        if (elem.countries && elem.countries !== null) {
          return elem.countries.includes(selectedCountry);
        }
        return false;
      });
      fullFilterList = fullFilterList.concat(filteredCountry)
    }
    if (selectedUser.toString().length > 0) {
      let userId = selectedUser.id
      const filteredUsers = allData.filter(elem => {
        if (elem.owner && elem.owner !== null) {
          return elem.owner === userId;
        }
        return false;
      });
      fullFilterList = fullFilterList.concat(filteredUsers)
    }
    SetRows(fullFilterList);
    if(selectedCountry === "" && selectedUser === "") {
      getSetEvents();
    }
    setPage(0);
   
    setopenFilterModal(false)
  }
  return (
    <>
      <Modal open={openFilterModal} onClose={handleFilterClose}>
        <Grid className='paper modalstyle'>
          <Grid className="modal-title">
            Choose Filter
            <button className='closeModal' onClick={() => handleFilterClose()}></button>
          </Grid>
          <Grid className="modal-body">
            <form className='filter_form'>
              <FormLabel component="legend">Select Country</FormLabel>
              <FormControl variant="outlined" className='formControl' size="small">
                <InputLabel id="demo-simple-select-outlined-label">Select Country</InputLabel>
                <Select
                  label="Select Country"
                  value={selectedCountry} name="country"
                  onChange={(e) => { setSelectedCountry(e.target.value) }}>
                  <MenuItem key={0} disabled value='' displayEmpty={true}>Select Country</MenuItem>
                  {countries.map((country, i) =>
                    <MenuItem key={i+2} value={country.country_code}>{country.country_name}</MenuItem>
                  )}
                </Select>
              </FormControl>
              <FormControl component="fieldset" className='formControl'>
                <FormLabel component="legend">Select User</FormLabel>
                <Autocomplete
                  ListboxComponent={ListboxComponent}
                  filterOptions={filterOptions}
                  options={userData}
                  value={selectedUser}
                  onChange={(event, newValue) => {
                    setSelectedUser(newValue);
                  }}
                  //onInputChange={(e, value) => { setSelectedUser(value) }}
                  getOptionLabel={(option) => option.fullName || ""}
                  renderInput={(params) => <TextField {...params} className="autocompleteInput" placeholder="Search by name of user" name="second_owner" size="small" InputLabelProps={{ shrink: false }} variant="outlined" />}
                />
              </FormControl>
            </form>
          </Grid>
          <Grid className="modal1FoBtn modal-footer text-right">
            <button type="button" onClick={() => handleFilterClose()} className="modalBtn closeBtn">Close</button>
            <button type="button" onClick={() => handleFilterReset()} className="modalBtn resetBtn">Reset Filter</button>
            <button type="button" onClick={() => handleFilterApply()} className="modalBtn applyBtn">Apply Filter</button>
          </Grid>
        </Grid>
      </Modal>
      <Modal open={openDelConfirmModal} onClose={handleDelConfirmClose}>
        <Grid className='paper modalstyle'>
          <Grid className="modal-title">
            <button className='closeModal' onClick={() => handleDelConfirmClose()}></button>
          </Grid>
          <Grid className="modal-body">
            <div className="m20">
              <Alert severity="warning">Event <b>{eventName}</b> will be deleted, are you sure?</Alert>
            </div>
          </Grid>
          <Grid className="modal1FoBtn modal-footer">
            <button type="button" onClick={() => handleDelConfirmClose()} className="modalBtn">Cancel</button>
            <button type="button" onClick={() => handleDelete()} className="modalBtn">OK</button>
          </Grid>
        </Grid>
      </Modal>

      <Breadcrumb translations={props.translations} setFilter={props.setFilter} BreadcrumbData={BreadcrumbData} />
      <div className='content-mgmt'>
        <div className="main-wrp">
          <Grid className="mgmt_header">
            <Grid container className="row">
              <Grid item lg={8} xs={12}>
                <label className="label-hd">
                  Event Management <span className='user'> {rows.length} events</span>
                </label>
              </Grid>
              <Grid item lg={2} xs={12}>
                <div className="filter_icons text-center">
                  <button className='singleBtn' onClick={handleReloadBtn}><AutorenewIcon /></button>
                  <button className='singleBtn' onClick={handleFilterBtn}><FilterListIcon /></button>
                </div>
              </Grid>
              <Grid item lg={2} xs={12}>
                <div className='search_bar searchBx'>
                  <Autocomplete
                    open={open}
                    onOpen={() => { if (searchTerm) { setOpen(true); } }}
                    onClose={() => setOpen(false)}
                    inputValue={searchTerm}
                    onInputChange={(e, value) => {
                      setSearchTerm(value);
                      if (value == null) {
                        setFilteredEvents([]);
                      } else {
                        let newEventsData = eventData.filter(function (item) {
                          let username = item.event_title.toLowerCase();
                          return username.startsWith(value.toLowerCase());
                        });
                        setFilteredEvents(newEventsData);
                        const found = eventData.some(el => el.event_title.toLowerCase() === value.toLowerCase());
                        if (!found) { setNewEventTerm(value); } else { setNewEventTerm(''); }
                      }
                      if (!value) { setOpen(false); }
                    }}
                    value={searchTerm}
                    onChange={handleSearchTerm}
                    onKeyDown={handleSearchTerm}
                    freeSolo
                    options={filteredEvents.length > 0 || newEventTerm !== '' ? filteredEvents : eventData}
                    getOptionLabel={(option) => typeof option === 'string' ? option : option.event_title}
                    renderInput={(params) =>
                      <TextField {...params} placeholder="Search by name" name="search_name" size="small"
                        InputLabelProps={{ shrink: false }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                          endAdornment: <InputAdornment  onClick={(value) => searchItem(value)} position="end"><IconButton className="mp0"><SearchIcon /></IconButton></InputAdornment>
                        }}
                      />}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <TableContainer>
            <Table className='table'>
              <EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={rows.length} />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    let publishedCountry = getCountryCountByStatus(row.action,'Published');
                    return (
                      <TableRow hover
                        onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.event_id}
                        selected={isItemSelected}
                      >
                        <TableCell align="left">{row.event_id}</TableCell>
                        <TableCell align="left">{row.event_title}</TableCell>
                        <TableCell align="left">{row.event_desc}</TableCell>
                        <TableCell align="left">{(new Date(row.event_start_time)).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</TableCell>
                        <TableCell align="center">
                          <Tooltip title={publishedCountry.join(',')}><Button>{row.published_country}</Button></Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          {(row.owner === userId || (row.second_owner && row.second_owner && row.second_owner === userId)) ?

                            (<div className='eventActionStyle'><img
                              onClick={() => navigate('/event-edit/' + row.event_id, { state: { action: 'local', data: row.action } })}
                              className='actnBtn'
                              src={require('../../../public/img/GreyEdit@3x.png')}
                              alt='Edit' />
                              <button onClick={() => openDelConfirm(row.event_id, row.action.lpevent_id, row.event_title)} type="button" className={classes.videobuttonremove}></button>
                            </div>

                            ) : (
                              <div><img onClick={() => navigate('/event-edit/' + row.event_id, { state: { action: 'local', data: row.action } })}
                                className='actnBtn'
                                src={require('../../../public/img/search.png')}
                                alt='Edit' />

                              </div>
                            )
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    {rows.length <= 0 ?
                      <TableCell align="center" colSpan={8}>{fetchDataStatus ? 'No Records Found.' : 'Loading...'}</TableCell> :
                      <TableCell colSpan={8} />
                    }
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  )
}
