/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Grid } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';


const ContentAudience = (props)=> {
  const [professionList, setProfessionList] = React.useState(["HCP Speaker", "Physician Non Speaker", "Pharmacist", "Nurse", "Others"]);
  
  const handlerCheckbox = (event, affiliate_id) => {
    if(event.target.checked) {
      props.SetAudience([...props.audience, {country_code : affiliate_id, value : event.target.value}])
    } else {
      var array = [...props.audience];
      const filteredArray = array.filter((item) => {
        if(item.country_code === affiliate_id && item.value === event.target.value) {
          return false;
        }
        return true;
      });
      props.SetAudience(filteredArray);
    }
  }
  const isChecked = (affiliate_id, value) => {
    let  check = Array.isArray(props.audience) ? props.audience : props.audience ? JSON.parse(props.audience) : [] 
    if(typeof check == 'string'){
      check = JSON.parse(check)
    }
    return check.some(el => el.country_code === affiliate_id && el.value === value)
  }
    return (
      <>
        {props.affiliates?.length > 0 ?
          <Grid container className="content white_row">
            <Grid item lg={12} xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Restricted to audience (Public by default)</FormLabel>
                <div className='ml20 tableData'>
                  {props.affiliates.length > 0 ? props.affiliates.map((item, item_index) => (
                    <Grid container key={item_index}>
                      <Grid item lg={3} xs={12}>
                        {item.country_name}
                      </Grid>
                      <Grid item lg={9} xs={12}>
                        <ul className='ul_list'>
                          <Grid className='affiliates'>
                            {professionList.length > 0 ? professionList.map((audience, index) => (
                              <li className='ul_list_inner' key={index}>
                                <FormControlLabel
                                  value={audience}
                                  className='labelTxt-user'
                                  checked={isChecked(item.country_code, audience)}
                                  control={<Checkbox onChange={(event) => { handlerCheckbox(event, item.country_code); }} />}
                                  label={audience}
                                  labelPlacement="end"
                                />
                              </li>
                            )) : ""}
                          </Grid>
                        </ul>
                      </Grid>
                    </Grid>
                  )) : ""}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        : ('')}
      </>
    )
}
export default ContentAudience