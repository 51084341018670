import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles } from '@material-ui/core/styles';
import editImg from '../../../public/img/new-img/GreyEdit@3x.png';
import editImgBlue from '../../../public/img/new-img/BlueEdit@3x.png';
import deleteImg from '../../../public/img/CloseGrey@3x.png';
import deleteImgBlue from '../../../public/img/new-img/RedClose@3x.png';
import Grid from '@material-ui/core/Grid';
import { Link } from "react-router-dom";
import '../country-management/country-management.css';
import Breadcrumb from '../../../layout/breadcrumb';
import { CountryService } from '../Services/country-service';
import lillyLogo from '../../../public/img/logo.png';
import { useSelector } from 'react-redux';

// Define Prototype
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// Define Data Table Header
const headCells = [
  { id: 'logo', numeric: false, disablePadding: true, label: 'Logo', sorting: false },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name', sorting: true },
  { id: 'domain', numeric: false, disablePadding: false, label: 'Domain URL', sorting: true },
  { id: 'action', numeric: false, disablePadding: false, label: 'Action', sorting: false },
];

// To Create Data Table Rows
function createData(logo, name, domain, action, updated_on) {
  return { logo, name, domain, action, updated_on };
}

// Functions for Apply Sorting of Data Table
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            className='table-cell'
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={!headCell.sorting}
              className={((!headCell.sorting) ? "nonClickable MuiButtonBase-root MuiTableSortLabel-root" : "MuiButtonBase-root MuiTableSortLabel-root")}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className='visuallyHidden'>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const useStyles = makeStyles((theme) => ({
  videobuttonedit: {
    backgroundImage: `url(${editImg})`,
    height: '20px',
    width: '20px',
    border: '0',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '0 1px',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '&:hover': {
      backgroundImage: `url(${editImgBlue})`
    }
  },
  videobuttonremove: {
    backgroundImage: `url(${deleteImg})`,
    height: '20px',
    width: '20px',
    border: '0',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '0px 0px',
    backgroundColor: 'transparent',
    marginLeft: '10px',
    cursor: 'pointer',
    '&:hover': {
      backgroundImage: `url(${deleteImgBlue})`
    }
  }
}));

// Country Management Component 
export default function EnhancedTable(props) {

  // Default States & Functions for Listing
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, SetRows] = React.useState([]);
  const [fetchDataStatus, setFetchDataStatus] = React.useState(false);
  const classes = useStyles();
  const [imagearray, setimagearray] = React.useState([]);
  const [checkImageapi, setcheckImageapi] = React.useState(false);
  let selectorValue = useSelector(state => state.session);
  const [sessionState, SetSessionState] = React.useState(selectorValue);
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const BreadcrumbData = [
    { value: props.translations['Home'] ? props.translations['Home'] : 'Home', path: '/home' },
    { value: 'Country Management', path: '/country-management' }
  ];
  const handleRequestSort = (event, property) => {
    if(property === 'name' || property ==='domain'){
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // To Get All Country Data
  function getSetCountries() {
    let countryService = new CountryService();
    let country = []
    if(sessionState && sessionState.sessionValue && sessionState.sessionValue.payload && sessionState.sessionValue.payload.user && sessionState.sessionValue.payload.user.country_code ){
      country = sessionState.sessionValue.payload.user.country_code
    }
    countryService.GetAffiliateByAffiliateIds(country).then((data) => {
      let list = [];
      let countries = data.data;
      if (countries.length > 0) {
        for (let i = 0; i < countries.length; i++) {
          setFetchDataStatus(true);
          let country = countries[i];
          let value = createData(country.avatar_attachment_id, country.country_name, country.domain_url, country.country_code, country.updated_at);
          list.push(value);
        }
      }
      list.sort((a, b) => ((b.updated_on ? new Date(b.updated_on) : new Date(8640000000000000)) - (a.updated_on ? new Date(a.updated_on) : new Date(8640000000000000))));
      SetRows(list);
      setcheckImageapi(true)
      getImage(list)
    }).catch((err) => {})
  }
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  React.useEffect(() => {
    
    SetSessionState(selectorValue);
    getSetCountries();
  }, [selectorValue])

  React.useEffect(() => {
  }, [imagearray]);

  const getImage =(rows)=>{
    let countryService = new CountryService();
    let newArr = {}; 
    for(let i=0;i<rows.length;i++){
      if(rows[i].logo !== ''){
        countryService.GetS3File(rows[i].logo, 'Banner').then((data) => {
          newArr['image'+rows[i].name] = data
          newArr = JSON.parse( JSON.stringify(newArr) )
          setimagearray(...imagearray ,newArr);
          // setimagearray(newArr):
        })
      }
    }
  }

  return (
    <div className='country-root'>
      <Breadcrumb translations={props.translations} setFilter={props.setFilter} BreadcrumbData={BreadcrumbData} />
      <div className='content-mgmt'>
        <div className="main-wrp">
          <Grid className="mgmt_header">
            <Grid container className="row">
              <Grid item lg={12} xs={12}>
                <label className="label-hd">
                  Country Management <span className='user'> {rows.length} countries</span>
                </label>
              </Grid>
            </Grid>
          </Grid>
          <TableContainer>
            <Table
              className='country-table'
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected}
                      >
                       
                        <TableCell align="left" className='table-cell'>
                          <span className="logo">
                            {row.logo !== ''?<img width="120" src={imagearray['image'+row.name]} alt='img' />:<img width="120" src={lillyLogo} alt='img' />
                            }
                          </span>
                        </TableCell>
                        <TableCell align="left" className='table-cell'>{row.name}</TableCell>
                        <TableCell align="left" className='table-cell'>{row.domain}</TableCell>
                        <TableCell align="left" className='table-cell'>
                          <Link to={"/country-profile/" + row.action}>
                            <button type="button" className={classes.videobuttonedit}></button>
                          </Link>
                          {/* <button type="button" className={classes.videobuttonremove}></button> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell align="center" colSpan={6}>{!fetchDataStatus ? 'No Records Found.' : ''}</TableCell>
                    {/* <TableCell colSpan={6} /> */}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}