import React from 'react';
import PropTypes from 'prop-types';
import { Button } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import Menu from '@material-ui/core/Menu';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import ButtonPagination from '../Components/ButtonPagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import SearchIcon from '@material-ui/icons/Search';
import GetAppIcon from '@material-ui/icons/GetApp';
import InputAdornment from '@material-ui/core/InputAdornment';
import FilterListIcon from '@material-ui/icons/FilterList';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Breadcrumb from '../../../layout/breadcrumb';
import '../content-management/content-management.css';
import { useNavigate } from "react-router-dom";
import { ContentService } from '../Service/content-service';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '@material-ui/lab/Alert';
import { useSelector } from 'react-redux';
import { CSVLink } from "react-csv";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Link } from '@material-ui/core';
import { ContentStatus } from '../Components/ContentStatus';
import Telegram from '../../../public/img/send-01.svg';
import UploadIcon from '../../../public/img/upload-03.svg';
import MinusIcon from '../../../public/img/minus-circle.svg';
import InfoIcon from '../../../public/img/annotation-info.svg';
import GraphFileGray from '../../../public/img/graphfile-gray.svg';
import GraphFileRed from '../../../public/img/graphfile-red.svg';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import ContentPublishModal from '../Components/ContentPublishModal';
import ContentRejectModal from '../Components/ContentRejectModal';
import ContentSubmitPublishModal from '../Components/ContentSubmitPublishModal';
import ContentInactiveModal from '../Components/ContentInactiveModal';
import ContentManagementReportModal from '../Components/ContentManagementReportModal';
import Tooltip from '@mui/material/Tooltip';
import Multiselect from '../Components/Multiselect';
import Singleselect from '../Components/Singleselect';
import Snackbar from '@material-ui/core/Snackbar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

// Define Prototype
EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


// Define Data Table Header
const headCells = [
    { id: 'contenttitle', numeric: false, disablePadding: false, label: 'Video/Podcast Title', sorting: true },
    { id: 'uploadtime', numeric: false, disablePadding: false, label: 'Upload Time', sorting: true },
    { id: 'duration', numeric: false, disablePadding: false, label: 'Duration', sorting: true },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status', sorting: true },
    { id: 'action', numeric: true, disablePadding: false, label: 'Action', sorting: false },
];

// To Create Data Table Rows
function createData(id, contenttitle, countries, created_by_user_id, uploadtime, duration, status, content_approved, published, pending, rejected, action, typeofcontent, vvpm_details, isFrozen, isAllDraft, secondaryOwnerId, ownerId, accesslevels, created_at) {
    return { id, contenttitle, countries, created_by_user_id, uploadtime, duration, status, content_approved, published, pending, rejected, action, typeofcontent, vvpm_details, is_frozen: isFrozen ? 'Preview' : 'Edit', is_all_draft: isAllDraft, owner: ownerId, second_owner: secondaryOwnerId, access: accesslevels, created_at };
}

// Functions for Apply Sorting of Data Table
function descendingComparator(a, b, orderBy) {
    if (orderBy === 'uploadtime') {
        let aDate = new Date(a[orderBy]);
        a[orderBy] = aDate.getTime();
        let bDate = new Date(b[orderBy]);
        b[orderBy] = bDate.getTime();
    }
    if (b[orderBy] < a[orderBy]) {
        return 1;
    }
    if (b[orderBy] > a[orderBy]) {
        return -1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'asc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator, orderBy, order) {
    if (orderBy === 'status') {
        const statusFilterList = array.map((e1, index) => {
            let statusList = [];
            for (let i = 0; i < e1['countries'].length; i++) {
                statusList.push(e1['content_approved'][e1['countries'][i]].status === 'Active' ? "Published" : e1['content_approved'][e1['countries'][i]].status)
            }
            return ([e1, index, statusList.length > 1 ? 'Multiple Countries' : statusList[0]])
        })
        statusFilterList.sort((a, b) => {
            let sortOrder;
            if (a[2] > b[2]) {
                sortOrder = order === 'asc' ? 1 : -1;
            }
            else if (a[2] < b[2]) {
                sortOrder = order === 'asc' ? -1 : 1;
            }
            else {
                sortOrder = 0
            }
            return sortOrder;
        });
        return statusFilterList.map((el) => el[0]);
    }
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead position='relative'>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            disabled={true}
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            hideSortIcon={!headCell.sorting}
                            className={((!headCell.sorting) ? "nonClickable MuiButtonBase-root MuiTableSortLabel-root" : "MuiButtonBase-root MuiTableSortLabel-root")}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className='visuallyHidden'>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


// Content Management Component
export default function EnhancedTable(props) {
    const navigate = useNavigate();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, SetRows] = React.useState([]);
    const [countries, setCountries] = React.useState([]);
    const [years, setYears] = React.useState([]);
    const [brands, setBrands] = React.useState([]);
    const [therapeuticAreas, setTherapeuticAreas] = React.useState([]);
    const [speakers, setSpeakers] = React.useState([]);
    const [userData, setUserData] = React.useState([]);
    const [openFilterModal, setopenFilterModal] = React.useState(false);
    const [filterArr, SetfilterArr] = React.useState([]);
    const [filterCountry, seFilterCountry] = React.useState('');
    const [filterUserId, seFilterUserId] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [fetchDataStatus, setFetchDataStatus] = React.useState(false);
    const [newContentTerm, setNewContentTerm] = React.useState('');
    const [filteredContents, setFilteredContents] = React.useState([]);
    const [setBlueColor, setFilteredBluecolor] = React.useState(false);
    const [setRedColor, setFilteredRedcolor] = React.useState(false);
    const [activeId, setActiveId] = React.useState('');
    const [editRecordId, setEditRecordId] = React.useState('');
    const [exportModal, SetExportModal] = React.useState(false);
    const [openDelConfirmModal, setopenDelConfirmModal] = React.useState(false);
    const [selectedContent, setselectedContent] = React.useState('');
    const [selectedAction, setSelectedAction] = React.useState('Edit')
    const [selrowID, setselrowID] = React.useState('');
    const [toggleBool, setToggleBool] = React.useState(false);
    const [csvData, SetcsvData] = React.useState([]);
    const [VVPMReport30DaysCSVData, SetVVPMReport30DaysCSVData] = React.useState([]);
    const [VVPMReport60DaysCSVData, SetVVPMReport60DaysCSVData] = React.useState([]);
    const [limit, setLimit] = React.useState(50);
    const [offSet, setoffSet] = React.useState(0);
    const [allRow, setallRow] = React.useState([]);
    const [totalLength, settotalLength] = React.useState('');
    const [searchCountry, SetsearchCountry] = React.useState('');
    const [searchTitle, SetsearchTitle] = React.useState('');
    const [searchCreated_by, SetsearchCreated_by] = React.useState('');

    const [filterOn, SetfilterOn] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [menuopen, SetMenuopen] = React.useState(false);
    const [anchorReportEl, setAnchorReportEl] = React.useState(null);
    const [downloadReport, SetDownloadReport] = React.useState(false);

    const [openStatusModal, setOpenStatusModal] = React.useState(false);
    const [activeContentSelected, setActiveContentSelected] = React.useState({});

    const [openChangeStatusModal, setOpenChangeStatusModal] = React.useState(false);
    const [generalReportModal, setGeneralReportModal] = React.useState(false);
    const [countryFilter, setCountryFilter] = React.useState(false);
    const [brandFilter, setBrandFilter] = React.useState(false);
    const [taFilter, setTAFilter] = React.useState(false);
    const [ownerFilter, setOwnerFilter] = React.useState(false);
    const [speakerFilter, setSpeakerFilter] = React.useState(false);
    const [vvpmFilter, setVvpmFilter] = React.useState(false);
    const [contentTypeFilter, setContentTypeFilter] = React.useState(false);
    const [statusFilter, setStatusFilter] = React.useState(false);
    const [vvpmFilterText, setVvpmFilterText] = React.useState('');

    const [selectedFilters, setSelectFilters] = React.useState({
        'country': [],
        'brand': [],
        'ta': [],
        'owner': [],
        'speaker': [],
        'vvpm': '',
        'content_type': [],
        'status': []
    });
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarType, setSnackbarType] = React.useState('');
    const [snackbarMsg, setSnackbarMsg] = React.useState('');
    const closecountry = () => {
        setCountryFilter(false);
    }
    const handleFilterCloseClick = (value) => {
        if (value === 'country') {
            setCountryFilter(!countryFilter);
            setBrandFilter(false);
            setTAFilter(false);
            setOwnerFilter(false);
            setSpeakerFilter(false);
            setVvpmFilter(false);
            setContentTypeFilter(false);
            setStatusFilter(false);
        } else if (value === 'brand') {
            setCountryFilter(false);
            setBrandFilter(!brandFilter);
            setTAFilter(false);
            setOwnerFilter(false);
            setSpeakerFilter(false);
            setVvpmFilter(false);
            setStatusFilter(false);
            setContentTypeFilter(false);
        } else if (value === 'ta') {
            setCountryFilter(false);
            setBrandFilter(false);
            setTAFilter(!taFilter);
            setOwnerFilter(false);
            setSpeakerFilter(false);
            setVvpmFilter(false);
            setStatusFilter(false);
            setContentTypeFilter(false);
        } else if (value === 'owner') {
            setCountryFilter(false);
            setBrandFilter(false);
            setTAFilter(false);
            setOwnerFilter(!ownerFilter);
            setSpeakerFilter(false);
            setVvpmFilter(false);
            setContentTypeFilter(false);
            setStatusFilter(false);
        } else if (value === 'speaker') {
            setCountryFilter(false);
            setBrandFilter(false);
            setTAFilter(false);
            setOwnerFilter(false);
            setSpeakerFilter(!speakerFilter);
            setVvpmFilter(false);
            setContentTypeFilter(false);
            setStatusFilter(false);
        } else if (value === 'vvpm') {
            setCountryFilter(false);
            setBrandFilter(false);
            setTAFilter(false);
            setOwnerFilter(false);
            setSpeakerFilter(false);
            setVvpmFilter(!vvpmFilter);
            setContentTypeFilter(false);
            setStatusFilter(false);
        } else if (value === 'content_type') {
            setCountryFilter(false);
            setBrandFilter(false);
            setTAFilter(false);
            setOwnerFilter(false);
            setSpeakerFilter(false);
            setVvpmFilter(false);
            setContentTypeFilter(!contentTypeFilter);
            setStatusFilter(false);
        } else if (value === 'status') {
            setCountryFilter(false);
            setBrandFilter(false);
            setTAFilter(false);
            setOwnerFilter(false);
            setSpeakerFilter(false);
            setVvpmFilter(false);
            setContentTypeFilter(false);
            setStatusFilter(!statusFilter);
        }
    }
    const duration_timestamp = (sec) => {
        var hours = Math.floor(sec / 3600);
        (hours >= 1) ? sec = sec - (hours * 3600) : hours = '00';
        var min = Math.floor(sec / 60);
        (min >= 1) ? sec = sec - (min * 60) : min = '00';
        (sec < 1) ? sec = '00' : void 0;
        (min.toString().length === 1) ? min = '0' + min : void 0;
        (sec.toString().length === 1) ? sec = '0' + sec : void 0;
        return hours + ':' + min + ':' + sec;
    }
    function getDateTime(obj) {
        let d = new Date(obj);
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"]
        let datestring = ("0" + d.getDate()).slice(-2) + " " + months[d.getMonth()] + " " + d.getFullYear() + ", " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
        return datestring;
    }
    function getCountryCountByStatus(country_approved, status) {
        let countriesStats = country_approved;
        let pendingCountry = [];
        let publishedCountry = [];
        let rejectedCountry = [];
        if (countriesStats) {
            if (Object.keys(countriesStats).length > 0) {
                Object.keys(countriesStats).map((countryId, i) => {
                    if (countriesStats[countryId].status === "Pending") {
                        pendingCountry.push(countriesStats[countryId].name);
                    }
                    if (countriesStats[countryId].status === "Active") {
                        publishedCountry.push(countriesStats[countryId].name);
                    }
                    if (countriesStats[countryId].status === "Inactive") {
                        rejectedCountry.push(countriesStats[countryId].name);
                    }
                    return true;
                });
            }
        }
        let countries = [];
        if (status === "Published") {
            countries = publishedCountry;
        } else if (status === "Pending") {
            countries = pendingCountry;
        } else if (status === "Rejected") {
            countries = rejectedCountry;
        }
        return countries;
    }

    let selectorValue = useSelector(state => state.session);
    const [sessionState, SetSessionState] = React.useState(selectorValue);
    let contentService = new ContentService();

    React.useEffect(() => {
        SetSessionState(selectorValue);
    }, [selectorValue]);

    React.useEffect(() => {
        if (sessionState.sessionValue?.payload?.user && filterArr.length > 0) {
            let roleData = sessionState.sessionValue?.payload?.user.role_ids;
            if (roleData.includes(2) || roleData.includes(4)) {
                let allData = filterArr;
                const filteredData = allData.filter(elem => {
                    if (elem.created_by_user_id && elem.created_by_user_id !== null) {
                        return elem.created_by_user_id === (sessionState.sessionValue?.payload?.user?.id);
                    }
                });
                if (toggleBool) {
                    const filteredPending = filteredData.filter(elem => {
                        if (elem.status && elem.status !== null) {
                            return elem.status === 'Draft';
                        }
                    });
                    SetRows(filteredPending);
                } else {
                    SetRows(filteredData);
                }
            }
            if (roleData.includes(3)) {
                if (toggleBool) {
                    let allData = filterArr;
                    const filteredPending = allData.filter(elem => {
                        if (elem.status && elem.status !== null) {
                            return elem.status === 'Draft';
                        }
                    });
                    SetRows(filteredPending);
                } else {
                    SetRows(filterArr);
                }
            }
            if (roleData.length === 0) {
                SetRows([]);
            }
        };
        getSetContents()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectorValue, sessionState]);

    React.useEffect(() => {
        if (sessionState.sessionValue?.payload?.user && filterArr.length > 0) {
            let roleData = sessionState.sessionValue?.payload?.user.role_ids;
            if (roleData.includes(2) || roleData.includes(4)) {
                let allData = filterArr;
                const filteredData = allData.filter(elem => {
                    if (elem.created_by_user_id && elem.created_by_user_id !== null) {
                        return elem.created_by_user_id === (sessionState.sessionValue?.payload?.user?.id);
                    }
                });
                if (toggleBool) {
                    const filteredPending = allData.filter(elem => {
                        if (elem.status && elem.status !== null) {
                            return elem.status === 'Draft';
                        }
                    });
                    SetRows(filteredPending);
                } else {
                    SetRows(filterArr);
                }
            }
            if (roleData.includes(3)) {
                if (toggleBool) {
                    let allData = filterArr;
                    const filteredPending = allData.filter(elem => {
                        if (elem.status && elem.status !== null) {
                            return elem.status === 'Draft';
                        }
                    });
                    SetRows(filteredPending);
                } else {
                    SetRows(filterArr);
                }
            }
            if (roleData.length === 0) {
                SetRows([]);
            }
        }
        else {
            SetRows([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterArr]);
    // To Get All Contents Data

    function getSetContents(param = {}) {
        if (sessionState.sessionValue?.payload?.user) {
            let roleData = sessionState.sessionValue?.payload?.user.role_ids;
            if ((roleData.includes(2) || roleData.includes(4)) && !roleData.includes(3)) {
                // correcting pagination in content
                let created_by = "created_by=" + sessionState.sessionValue.payload.user.id + "&category_id=All&start=" + (param.start == 0 ? param.start : offSet) + "&end=" + limit
                // correcting pagination in content end
                contentService.GetAllContent(created_by).then((data) => {
                    let contents = data.data;
                    let list = []
                    if (contents.length > 0) {
                        for (let i = 0; i < contents.length; i++) {
                            let content = contents[i];
                            let content_duration = content.duration;
                            let contentDuration = duration_timestamp(content_duration)
                            if (content.created_by === sessionState.sessionValue.payload.user.id || content.second_owner === sessionState.sessionValue.payload.user.id) {
                                let status = "Draft";
                                let countriesStats = content.content_approved;
                                if (countriesStats) {
                                    if (Object.keys(countriesStats).length > 0) {
                                        Object.keys(countriesStats).map((countryId, i) => {
                                            if (countriesStats[countryId].status === "Active") {
                                                status = "Submitted"
                                            }
                                        });
                                    }
                                }
                                let upload_time = new Date(content.updated_at);
                                upload_time = upload_time.getTime();
                                let publishedCountryCount = getCountryCountByStatus(content.content_approved, 'Published').length;
                                let pendingCountryCount = getCountryCountByStatus(content.content_approved, 'Pending').length;
                                let rejectedCountryCount = getCountryCountByStatus(content.content_approved, 'Rejected').length;
                                let isFrozen = (Object.keys(content.content_approved)).map(x => ['Active', 'Pending', 'Published'].includes(content.content_approved[x].status)).some(val => val == true);
                                let isAllDraft = ((Object.keys(content.content_approved)).filter(x => content.content_approved[x].status !== 'Draft')).length > 0 ? false : true;

                                let accesslevels = true;
                                if (sessionState.sessionValue?.payload?.user.role_ids.includes(3)) {
                                    let publishedCountries = sessionState.sessionValue?.payload?.affiliate.map((e) => e.country_name);
                                    if ((Object.values(content.content_approved)).length > 0) {
                                        Object.values(content.content_approved).forEach(x => {
                                            if (!publishedCountries.includes(x.name)) {
                                                accesslevels = false
                                            }
                                        })
                                    }
                                }
                                let created_at = (new Date(content.created_at)).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' });
                                let value = createData(content.content_id, content.title, content.country_codes, content.created_by, upload_time, contentDuration, status, content.content_approved, publishedCountryCount, pendingCountryCount, rejectedCountryCount, content, content.content_type, content.vvpm_details, isFrozen, isAllDraft, content.second_owner, content.created_by, accesslevels, created_at);
                                list.push(value);
                                list.sort((a, b) => ((b.uploadtime ? new Date(b.uploadtime) : new Date(8640000000000000)) - (a.uploadtime ? new Date(a.uploadtime) : new Date(8640000000000000))));
                            }
                        }
                        let newarr = filterArr
                        if (param.start == 0) {
                            newarr = []
                        }
                        newarr = newarr.concat(list);
                        SetfilterArr(newarr);
                        setoffSet(offSet + 50)
                    }
                    getVideoByQueryCount(created_by, sessionState.sessionValue.payload.user.id)
                    setFetchDataStatus(true);
                }).catch((err) => { });
            }
            if (roleData.includes(3)) {
                // correcting pagination in content
                // let userStr = { "country_codes": sessionState.sessionValue.payload.user.country_code, "created_by": sessionState.sessionValue.payload.user.id, start: param.start == 0 ? param.start : offSet, end: limit }
                let userStr = { "country_codes": sessionState.sessionValue.payload.user.country_code, "created_by": sessionState.sessionValue.payload.user.id }
                // correcting pagination in content end
                contentService.GetVideoByCountry(userStr).then((data) => {
                    let contents = data.data;
                    let list = [];
                    if (contents.length > 0) {
                        for (let i = 0; i < contents.length; i++) {
                            let content = contents[i];
                            let content_duration = content.duration;
                            let contentDuration = duration_timestamp(content_duration)
                            if (content.status === "submitted" || content.created_by === sessionState.sessionValue.payload.user.id || content.second_owner === sessionState.sessionValue.payload.user.id) {
                                let status = "Draft";
                                let countriesStats = (content.content_approved);
                                if (countriesStats) {
                                    if (Object.keys(countriesStats).length > 0) {
                                        Object.keys(countriesStats).map((countryId, i) => {
                                            if (countriesStats[countryId].status === "Active") {
                                                status = "Submitted"
                                            }
                                        });
                                    }
                                }
                                let upload_time = new Date(content.updated_at);
                                upload_time = upload_time.getTime();
                                let publishedCountryCount = getCountryCountByStatus(content.content_approved, 'Published').length;
                                let pendingCountryCount = getCountryCountByStatus(content.content_approved, 'Pending').length;
                                let rejectedCountryCount = getCountryCountByStatus(content.content_approved, 'Rejected').length;
                                let isFrozen = (Object.keys(content.content_approved)).map(x => ['Active', 'Pending', 'Published'].includes(content.content_approved[x].status)).some(val => val == true);
                                let isAllDraft = ((Object.keys(content.content_approved)).filter(x => content.content_approved[x].status !== 'Draft')).length > 0 ? false : true;
                                let accesslevels = true;
                                if (roleData.includes(3)) {
                                    let publishedCountries = sessionState.sessionValue?.payload?.affiliate.map((e) => e.country_name);
                                    if ((Object.values(content.content_approved)).length > 0) {
                                        Object.values(content.content_approved).forEach(x => {
                                            if (!publishedCountries.includes(x.name)) {
                                                accesslevels = false
                                            }
                                        })
                                    }
                                }
                                let created_at = (new Date(content.created_at)).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' });
                                let value = createData(content.content_id, content.title, content.country_codes, content.created_by, upload_time, contentDuration, status, content.content_approved, publishedCountryCount, pendingCountryCount, rejectedCountryCount, content, content.content_type, content.vvpm_details,
                                    isFrozen,
                                    isAllDraft,
                                    content.second_owner,
                                    content.created_by, accesslevels, created_at);
                                list.push(value);
                                list.sort((a, b) => ((b.uploadtime ? new Date(b.uploadtime) : new Date(8640000000000000)) - (a.uploadtime ? new Date(a.uploadtime) : new Date(8640000000000000))));
                            }
                        }
                        let newarr = filterArr
                        if (param.start == 0) {
                            newarr = []
                        }
                        newarr = newarr.concat(list);
                        SetfilterArr(newarr);
                        setoffSet(offSet + 50)
                    }
                    getVideoByCountryCount(userStr)
                    setFetchDataStatus(true);
                }).catch((err) => { });
            }
        }
    }

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const users = await contentService.GetAllUsers();
                const therapeuticAreas = await contentService.GetAllArea();
                const brands = await contentService.GetAllBrands();
                const indications = await contentService.GetAllIndications();
                const speakers = await contentService.GetSpeakersByQuery();

                let TAjsondata = therapeuticAreas.data;
                let TAData = [];
                if (TAjsondata.length > 0) {
                    for (let t = 0; t < TAjsondata.length; t++) {
                        TAData[TAjsondata[t].therapeutic_area_id] = TAjsondata[t].therapeutic_area_name;
                    }
                }

                let Brandjsondata = brands.data;
                let BrandData = [];
                if (Brandjsondata.length > 0) {
                    for (let t = 0; t < Brandjsondata.length; t++) {
                        BrandData[Brandjsondata[t].category_1_id] = Brandjsondata[t].category_1_name;
                    }
                }

                let Indijsondata = indications.data;
                let IndicationData = [];
                if (Indijsondata.length > 0) {
                    for (let t = 0; t < Indijsondata.length; t++) {
                        IndicationData[Indijsondata[t].category_2_id] = Indijsondata[t].category_2_name;
                    }
                }

                let Speakerjsondata = speakers.data;
                let SpeakerData = [];
                if (Speakerjsondata.length > 0) {
                    for (let t = 0; t < Speakerjsondata.length; t++) {
                        SpeakerData[Speakerjsondata[t].speaker_id] = Speakerjsondata[t].name;
                    }
                }
                getReportData(users.data, TAData, BrandData, IndicationData, SpeakerData);
            } catch (e) {
            }
        };

        fetchData();
    }, []);

    const getReportData = (users, therapeuticAreas, brands, indications, speakers) => {
        let filterArr = {
            "country_codes": sessionState?.sessionValue?.payload?.user?.country_code,
            "created_by": sessionState?.sessionValue?.payload?.user?.id
        }
        contentService.GetContentReport('', filterArr).then((data) => {
            let contents = data.data;
            let hdrData = [
                "Content Id",
                "Content Title",
                "Content Tags",
                "Status",
                "Kaltura Id",
                "Content Owner",
                "Content Co-owner",
                "Submission Date",
                "Speaker Name",
                "Indication Name",
                "Product Name",
                "Therapuetic Name",
                "Country",
                "Lilly Play Start Date",
                "Lilly Play Expiration Date",
                "VVPM Expiration Date",
                "VVPM Approval Code"
            ];
            const csvData30Days = [hdrData]
            const csvData60Days = [hdrData]
            if (contents.length) {
                for (let i = 0; i < contents.length; i++) {
                    let content = contents[i];
                    let ownerName = content.created_by;
                    let secondOwnerName = content.second_owner;
                    let contentTags = content.content_tags ? content.content_tags.toString() : "";
                    if (users.length) {
                        for (let j = 0; j < users.length; j++) {
                            if (users[j].id === content.created_by) {
                                ownerName = `${users[j].first_name} ${users[j].last_name} (${users[j].user_name})`
                            }
                            if (users[j].id === content.second_owner) {
                                secondOwnerName = `${users[j].first_name} ${users[j].last_name} (${users[j].user_name})`
                            }
                        }
                    }
                    let TANames = [];
                    if (Object.keys(therapeuticAreas).length) {
                        if (content?.therapeutic_area_id?.length) {
                            for (let j = 0; j < content.therapeutic_area_id.length; j++) {
                                TANames.push(therapeuticAreas[content.therapeutic_area_id[j]]);
                            }
                        }
                    }
                    let BrandNames = [];
                    if (Object.keys(brands).length) {
                        if (content?.category_1_id?.length) {
                            for (let j = 0; j < content.category_1_id.length; j++) {
                                BrandNames.push(brands[content.category_1_id[j]]);
                            }
                        }
                    }
                    let IndicationNames = [];
                    if (Object.keys(indications).length) {
                        if (content?.category_2_id?.length) {
                            for (let j = 0; j < content.category_2_id.length; j++) {
                                IndicationNames.push(indications[content.category_2_id[j]]);
                            }
                        }
                    }
                    let SpeakerNames = [];
                    if (Object.keys(speakers).length) {
                        if (content?.speakers_id?.length) {
                            for (let j = 0; j < content.speakers_id.length; j++) {
                                SpeakerNames.push(speakers[content.speakers_id[j]]);
                            }
                        }
                    }
                    let vvpmDetails = content.vvpm_details;
                    let dateCheck30DaysArr = [];
                    let dateCheck60DaysArr = [];
                    let vvpmInfoDict = [];
                    if (vvpmDetails && Object.keys(vvpmDetails).length) {
                        for (let key in vvpmDetails) {
                            let lpFromDt = vvpmDetails[key]?.from;
                            let lpToDate = vvpmDetails[key]?.to;

                            let currentDt = Date.now();
                            let future30Dt = new Date(); future30Dt.setDate(future30Dt.getDate() + 30);
                            let future60Dt = new Date(); future60Dt.setDate(future60Dt.getDate() + 60);

                            let vvpmRows = vvpmDetails[key]?.rows;
                            let vvpmExpDate = vvpmRows[0]?.expiration_date__vs;
                            let vvpmAplCode = vvpmRows[0]?.document_number__v;

                            if (lpToDate !== "") {
                                var expDt = new Date(lpToDate).getTime();
                                // Check 30 Days Report
                                if (expDt > currentDt && expDt < future30Dt) {
                                    dateCheck30DaysArr.push(true);
                                } else {
                                    dateCheck30DaysArr.push(false);
                                }
                                // Check 60 Days Report
                                if (expDt > currentDt && expDt < future60Dt) {
                                    dateCheck60DaysArr.push(true);
                                } else {
                                    dateCheck60DaysArr.push(false);
                                }
                            }

                            vvpmInfoDict.push({
                                'country': key,
                                'start_date': lpFromDt,
                                'end_date': lpToDate,
                                'vvpm_expiry_date': vvpmExpDate,
                                'vvpm_approval_code': vvpmAplCode,
                            });
                        }
                    }

                    let vvpmInfo = JSON.stringify(vvpmInfoDict).replaceAll(',', ';');
                    let exist30Days = dateCheck30DaysArr.some((element) => element === true);
                    let exist60Days = dateCheck60DaysArr.some((element) => element === true);

                    if (vvpmInfoDict.length) {
                        for (let i = 0; i < vvpmInfoDict.length; i++) {
                            var createdDt = content.created_at !== '' ? getDateTime(new Date(content.created_at).getTime()) : '-';
                            var lpStartDt = vvpmInfoDict[i].start_date !== '' ? getDateTime(new Date(vvpmInfoDict[i].start_date).getTime()) : '-';
                            var lpEndDt = vvpmInfoDict[i].end_date !== '' ? getDateTime(new Date(vvpmInfoDict[i].end_date).getTime()) : '-';
                            var vvpmExpDt = vvpmInfoDict[i].vvpm_expiry_date !== '' ? getDateTime(new Date(vvpmInfoDict[i].vvpm_expiry_date).getTime()) : '-';
                            let cntData = [
                                content.content_id,
                                content.title,
                                contentTags,
                                content.status,
                                content.kaltura_id,
                                ownerName,
                                secondOwnerName,
                                content.created_at,
                                SpeakerNames.toString(),
                                IndicationNames.toString(),
                                BrandNames.toString(),
                                TANames.toString(),
                                vvpmInfoDict[i].country,
                                vvpmInfoDict[i].start_date,
                                vvpmInfoDict[i].end_date,
                                vvpmInfoDict[i].vvpm_expiry_date,
                                vvpmInfoDict[i].vvpm_approval_code,
                            ];
                            if (exist30Days) {
                                csvData30Days.push(cntData);
                            }
                            if (exist60Days) {
                                csvData60Days.push(cntData)
                            }
                        }
                    }

                }
            }
            SetVVPMReport30DaysCSVData(csvData30Days)
            SetVVPMReport60DaysCSVData(csvData60Days)
        }).catch((err) => { });
    };

    React.useEffect(() => {
        // correcting pagination in content
        getSetContents();
        // correcting pagination in content end
        window.scrollTo(0, 0);
        // To Get All Users
        contentService.GetAllUsers().then((data) => {
            let jsondata = data.data;
            let arr = []
            for (let i = 0; i < jsondata.length; i++) {
                if (jsondata[i].user_name != null && jsondata[i].user_name !== ''
                    && jsondata[i].first_name !== null && jsondata[i].first_name !== '' &&
                    jsondata[i].last_name !== null && jsondata[i].last_name !== '') {
                    jsondata[i].fullName = `${jsondata[i].first_name} ${jsondata[i].last_name} (${jsondata[i].user_name})`
                    arr.push(jsondata[i])
                }
            }
            if (sessionState?.sessionValue?.payload?.user.role_ids.includes(2)
                && !sessionState.sessionValue?.payload?.user.role_ids.includes(3)) {
                arr = arr.filter(x => x.id ===
                    sessionState?.sessionValue?.payload?.user.id);
            }
            setUserData(arr);
        }).catch((err) => { });

        try {
            let user_country = sessionState.sessionValue?.payload?.affiliate;
            setCountries(user_country);
        } catch (err) {
        };
        try {
            const finalyear = new Date().getFullYear() + 10;
            let list = [];
            // get current year add 10 to it
            for (let i = 2018; i < finalyear; i++) {
                list.push({ key: i, label: `${i}` })
            }
            // make a list from 200 to the above year
            setYears(list);
        } catch (err) {
        };

        contentService.GetAllBrands().then((data) => {
            let brandData = data.data;
            brandData.sort((a, b) => a.category_1_name.localeCompare(b.category_1_name));
            setBrands(brandData);
        }).catch((err) => { });

        contentService.GetAllArea().then((data) => {
            let taData = data.data;
            taData.sort((a, b) => a.therapeutic_area_name.localeCompare(b.therapeutic_area_name));
            setTherapeuticAreas(taData)
        }).catch((err) => { });

        contentService.GetAllSpeakerByQuery().then((data) => {
            let speakerData = data.data;
            speakerData.sort((a, b) => a.name.localeCompare(b.name));
            setSpeakers(speakerData)
        }).catch((err) => { });

        contentService.GetAllQuizAttempts().then((data) => {
            let data1 = data.data;
            const csv = [
                ["quiz_id", "quiz_title", "okta_id", "quiz_score", "total_questions", "correct_ans", "started_at", "completed_at"]
            ]
            for (let i = 0; i < data1.length; i++) {
                csv.push([data1[i].quiz_id, data1[i].quiz_title, data1[i].okta_id, data1[i].quiz_score, data1[i].total_questions, data1[i].correct_ans, data1[i].started_at, data1[i].completed_at])
            }
            SetcsvData(csv);
        }).catch((err) => { });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(() => {
        if (countries.length > 0 &&
            years.length > 0 &&
            brands.length > 0 &&
            therapeuticAreas.length > 0 &&
            speakers.length > 0)
            setopenFilterModal(true);
    }, [countries, years, brands, therapeuticAreas, speakers]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        // correcting pagination in content start
        if (totalLength > newPage * 10) {
            // if selected filters are empty 
            if (
                searchTitle === '' &&
                selectedFilters['country'].length === 0 &&
                selectedFilters['brand'].length === 0 &&
                selectedFilters['ta'].length === 0 &&
                selectedFilters['owner'].length === 0 &&
                selectedFilters['speaker'].length === 0 &&
                selectedFilters['vvpm'] === '' &&
                selectedFilters['content_type'].length === 0 &&
                selectedFilters['status'].length === 0
            ) {
                if (Math.ceil(totalLength / 50) > newPage) {
                    getSetContents();
                } else {
                    setoffSet(offSet + 50)
                    getFilteredContent(
                        {
                            country: filterCountry,
                            title: searchTitle,
                            created_by: filterUserId.id,
                        },
                        offSet + 50
                    );
                }
            }
        };
    }

    const handleFilterBtn = () => {
        setopenFilterModal(!openFilterModal)
    };
    const resetFilter = () => {
        setSearchTerm('');
        handleFilterApply({ searchterm: '' });
    };
    //On VVPM Search Enter
    const handleVvpmKeydown = () => {
        if (vvpmFilterText != '') {
            handleSelected({ 'vvpm': [{ label: vvpmFilterText, value: vvpmFilterText }] });
            setVvpmFilter(!vvpmFilter)
        }
    }
    //On VVPM Search Enter
    const handleVvpmSearch = (e) => {
        setVvpmFilterText(e.target.value);
    }
    // On Filter Select 
    const handleSelected = (values) => {
        let modifiedkey = Object.keys(values)[0];
        let list = [];
        for (let i = 0; i < values[modifiedkey].length; i++) {
            if (list.map(x => x.key).includes(values[modifiedkey][i].key)) {

            } else {
                list = [...list, values[modifiedkey][i]]
            }
        }
        let filters = selectedFilters;
        filters[modifiedkey] = list;
        setSelectFilters({ ...filters });
    }
    const handleDeleted = (key, value) => {
        if (key === 'vvpm') {
            setVvpmFilterText('')
        }
        let filters = selectedFilters;
        let arr = [];
        for (let i = 0; i < filters[key].length; i++) {
            if (filters[key][i].key !== value) {
                arr = [...arr, filters[key][i]];
            }
        }
        filters[key] = arr;
        setSelectFilters({ ...filters });
    }
    const handleMainPageSubmitModal = (value) => {
        const keylist = Object.keys(value?.vvpm_details);
        if (value.vvpm_details[keylist[0]].rows.length === 0) {
            // setIsValid(false);
            setSnackbarOpen(true);
            setSnackbarType('error');
            setSnackbarMsg('There are some mandatory fields missing: VVPM Document');
        } else if (value.vvpm_details[keylist[0]].rows.length > 0) {

        }
        else {
            // LILLYPLAY-3579 start
            let model_data = activeContentSelected.content_approved;
            // Iterate through each country object
            for (let countryCode in model_data) {
                if (model_data.hasOwnProperty(countryCode)) {
                    // Remove the 'prevstatus' property from each country object
                    delete model_data[countryCode].prevstatus;
                }
            }
            activeContentSelected.content_approved = model_data;
            // LILLYPLAY-3579 end
            contentService.UpdateContentStatus({
                'content_id': activeContentSelected.id,
                'country_code': Object.keys(activeContentSelected.content_approved)[0],
                'content_approved': JSON.stringify(activeContentSelected.content_approved),
                'status': activeContentSelected.record_type === 'SubmitToPublish' ? 'Pending' : 'Active',
                'updated_on': new Date()
            }).then((data) => {
                if (data.status == 200) {
                    // setIsValid(true)
                }
            }).catch((err) => { });
        }
    }
    const getVideoByCountryCount = (userStr) => {
        contentService.GetVideoByCountryCountData(userStr).then((data) => {
            setallRow(data.data);
            settotalLength(data.data.length);
        }).catch((err) => console.log(err));
    }
    const getVideoByQueryCount = (userStr, userid) => {
        let created_by = userStr + "&category_id=All&start=" + offSet + "&end=" + limit
        contentService.GetVideosByQueryCountData(created_by, userid).then((data) => {
            setallRow(data.data)
            settotalLength(data.data.length)
        }).catch((err) => { });

    }

    const handleFilterApply = ({ searchterm }) => {
        // to prevent reordering of content even when no filters are selected
        if (searchTitle === '' &&
            searchterm === '' &&
            selectedFilters['country'].length === 0 &&
            selectedFilters['brand'].length === 0 &&
            selectedFilters['ta'].length === 0 &&
            selectedFilters['owner'].length === 0 &&
            selectedFilters['speaker'].length === 0 &&
            selectedFilters['vvpm'] === '' &&
            selectedFilters['content_type'].length === 0 &&
            selectedFilters['status'].length === 0) {
            const param = {};
            param.start = 0;
            getSetContents(param);
        }
        SetfilterOn(true);
        setPage(0);
        setoffSet(0);
        //close all boxes
        setCountryFilter(false);
        setBrandFilter(false);
        setTAFilter(false);
        setOwnerFilter(false);
        setSpeakerFilter(false);
        setVvpmFilter(false);
        setContentTypeFilter(false);
        setStatusFilter(false);
        setVvpmFilter(false);
        setVvpmFilterText('');
        let data = {
            country:
                selectedFilters.country.length > 0
                    ? selectedFilters["country"].map((x) => x.key)
                    : sessionState.sessionValue.payload.user.country_code,
            ta:
                selectedFilters.ta.length > 0
                    ? selectedFilters.ta.map((x) => x.key)
                    : null,
            brand:
                selectedFilters.brand.length > 0
                    ? selectedFilters["brand"].map((x) => x.key)
                    : null,
            speaker:
                selectedFilters.speaker.length > 0
                    ? selectedFilters["speaker"].map((x) => x.key)
                    : null,
            created_by:
                selectedFilters.owner.length > 0
                    ? selectedFilters["owner"].map((x) => x.key)[0]
                    : null,
            content_type:
                selectedFilters.content_type.length > 0
                    ? selectedFilters.content_type.map((x) => x.key)
                    : null,
            vvpm_code: selectedFilters.vvpm.length > 0 ? `%${vvpmFilterText}%` : null,
            status:
                selectedFilters.status.length > 0
                    ? selectedFilters.status.map((x) => `%${x.key}%`)
                    : null,
            title: searchterm.toString(),
            start: 0,
            end: null,
        };
        if (sessionState.sessionValue?.payload?.user) {
            let roleData = sessionState.sessionValue?.payload?.user.role_ids;
            if ((roleData.includes(2) || roleData.includes(4)) && !roleData.includes(3)) {
                data.created_by = sessionState?.sessionValue?.payload?.user?.id
                data.country = null
            }
            contentService
                .FilterContent(data)
                .then((data) => {
                    let contents = data?.data?.rows;
                    let list = [];
                    let sessionCountry =
                        sessionState.sessionValue.payload.user.country_code;
                    if (contents.length > 0) {
                        for (let i = 0; i < contents.length; i++) {
                            if (
                                Array.isArray(contents[i].country_codes) 
                                // &&
                                // contents[i].country_codes.some((i) => sessionCountry.includes(i))
                            ) {
                                let content = contents[i];
                                let content_duration = content.duration;
                                let contentDuration = duration_timestamp(content_duration);
                                let status = "Draft";
                                let countriesStats = content.content_approved;
                                if (countriesStats) {
                                    if (Object.keys(countriesStats).length > 0) {
                                        Object.keys(countriesStats).map((countryId, i) => {
                                            if (countriesStats[countryId].status === "Active") {
                                                status = "Submitted";
                                            }
                                        });
                                    }
                                }
                                let upload_time = new Date(content.updated_at);
                                upload_time = upload_time.getTime();
                                let isFrozen = (Object.keys(content.content_approved)).map(x => ['Active', 'Pending', 'Published'].includes(content.content_approved[x].status)).some(val => val == true);
                                let isAllDraft = ((Object.keys(content.content_approved)).filter(x => content.content_approved[x].status !== 'Draft')).length > 0 ? false : true;
                                let accesslevels = true;
                                if (sessionState.sessionValue?.payload?.user.role_ids.includes(3)) {
                                    let publishedCountries = sessionState.sessionValue?.payload?.affiliate.map((e) => e.country_name);
                                    if ((Object.values(content.content_approved)).length > 0) {
                                        Object.values(content.content_approved).forEach(x => {
                                            if (!publishedCountries.includes(x.name)) {
                                                accesslevels = false
                                            }
                                        })
                                    }
                                }
                                let created_at = (new Date(content.created_at)).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' });
                                let value = createData(
                                    content.content_id,
                                    content.title,
                                    content.country_codes,
                                    content.created_by,
                                    upload_time,
                                    contentDuration,
                                    status,
                                    content.content_approved,
                                    0,
                                    0,
                                    0,
                                    content,
                                    content.content_type,
                                    content.vvpm_details,
                                    isFrozen,
                                    isAllDraft,
                                    content.second_owner,
                                    content.created_by,
                                    accesslevels,
                                    created_at
                                );
                                list.push(value);
                                list.sort(
                                    (a, b) =>
                                        (b.uploadtime
                                            ? new Date(b.uploadtime)
                                            : new Date(8640000000000000)) -
                                        (a.uploadtime
                                            ? new Date(a.uploadtime)
                                            : new Date(8640000000000000))
                                );
                                // }
                            }
                        }
                    }
                    let newarr = [];
                    newarr = newarr.concat(list);

                    SetfilterArr(newarr);
                    SetRows(newarr);
                    // Correcting total filter count in content management after handle filter apply
                    settotalLength(data?.data?.count);
                    setoffSet(50);
                })
                .catch((err) => { });
        }
    };

    const handleFilterReset = () => {
        setPage(0);
        setoffSet(0);
        SetfilterOn(false);
        //close all boxes
        setCountryFilter(false);
        setBrandFilter(false);
        setTAFilter(false);
        setOwnerFilter(false);
        setSpeakerFilter(false);
        setVvpmFilter(false);
        setContentTypeFilter(false);
        setStatusFilter(false);
        setVvpmFilter(false);
        setSelectFilters({
            country: [],
            brand: [],
            ta: [],
            owner: [],
            speaker: [],
            vvpm: "",
            content_type: [],
            status: [],
        });
        setVvpmFilterText("");
        const param = {};
        param.start = 0;
        getSetContents(param);
    };

    const getFilteredContent = (param = {}, start) => {
        if (start) {
            param.start = start;
        } else {
            param.start = 0;
        }
        let data = {
            country:
                selectedFilters.country.length > 0
                    ? selectedFilters["country"].map((x) => x.key)
                    : sessionState.sessionValue.payload.user.country_code,
            ta:
                selectedFilters.ta.length > 0
                    ? selectedFilters.ta.map((x) => x.key)
                    : null,
            brand:
                selectedFilters.brand.length > 0
                    ? selectedFilters["brand"].map((x) => x.key)
                    : null,
            speaker:
                selectedFilters.speaker.length > 0
                    ? selectedFilters["speaker"].map((x) => x.key)
                    : null,
            content_type:
                selectedFilters.content_type.length > 0
                    ? selectedFilters.content_type.map((x) => x.key)
                    : null,
            created_by:
                selectedFilters.owner.length > 0
                    ? selectedFilters["owner"].map((x) => x.key)[0]
                    : null,
            vvpm:
                selectedFilters.vvpm.length > 0
                    ? selectedFilters.status.map((x) => `%${x.key}%`)
                    : null,
            status:
                selectedFilters.status.length > 0
                    ? selectedFilters.status.map((x) => `%${x.key}%`)
                    : null,
            title: searchTerm.toString(),
            start: param.start,
            end: 100,
        };
        if (sessionState.sessionValue?.payload?.user) {
            let roleData = sessionState.sessionValue?.payload?.user.role_ids;
            if ((roleData.includes(2) || roleData.includes(4)) && !roleData.includes(3)) {
                data.created_by = sessionState?.sessionValue?.payload?.user?.id
                data.country = null
            }
        }
        if ((Object.values(selectedFilters)).some(value => value.length > 0) || searchTerm.length > 0) {
            contentService
                .FilterContent(data)
                .then((data) => {
                    let contents = data?.data?.rows;
                    let list = [];
                    let sessionCountry =
                        sessionState.sessionValue.payload.user.country_code;
                    if (contents.length > 0) {
                        for (let i = 0; i < contents.length; i++) {
                            if (
                                Array.isArray(contents[i].country_codes) 
                                // &&
                                // contents[i].country_codes.some((i) => sessionCountry.includes(i))
                            ) {
                                let content = contents[i];
                                let content_duration = content.duration;
                                let contentDuration = duration_timestamp(content_duration);
                                let status = "Draft";
                                let countriesStats = content.content_approved;
                                if (countriesStats) {
                                    if (Object.keys(countriesStats).length > 0) {
                                        Object.keys(countriesStats).map((countryId, i) => {
                                            if (countriesStats[countryId].status === "Active") {
                                                status = "Submitted";
                                            }
                                        });
                                    }
                                }
                                let upload_time = new Date(content.updated_at);
                                upload_time = upload_time.getTime();
                                let isFrozen = (Object.keys(content.content_approved)).map(x => ['Active', 'Pending', 'Published'].includes(content.content_approved[x].status)).some(val => val == true);
                                let isAllDraft = ((Object.keys(content.content_approved)).filter(x => content.content_approved[x].status !== 'Draft')).length > 0 ? false : true;
                                let accesslevels = true;
                                if (sessionState.sessionValue?.payload?.user.role_ids.includes(3)) {
                                    let publishedCountries = sessionState.sessionValue?.payload?.affiliate.map((e) => e.country_name);
                                    if ((Object.values(content.content_approved)).length > 0) {
                                        Object.values(content.content_approved).forEach(x => {
                                            if (!publishedCountries.includes(x.name)) {
                                                accesslevels = false
                                            }
                                        })
                                    }
                                }
                                let created_at = (new Date(content.created_at)).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' });
                                let value = createData(
                                    content.content_id,
                                    content.title,
                                    content.country_codes,
                                    content.created_by,
                                    upload_time,
                                    contentDuration,
                                    status,
                                    content.content_approved,
                                    0,
                                    0,
                                    0,
                                    content,
                                    content.content_type,
                                    content.vvpm_details,
                                    isFrozen,
                                    isAllDraft,
                                    content.second_owner,
                                    content.created_by,
                                    accesslevels,
                                    created_at
                                );
                                list.push(value);
                                list.sort(
                                    (a, b) =>
                                        (b.uploadtime
                                            ? new Date(b.uploadtime)
                                            : new Date(8640000000000000)) -
                                        (a.uploadtime
                                            ? new Date(a.uploadtime)
                                            : new Date(8640000000000000))
                                );
                            }
                            // }
                        }
                    }
                    let newarr = filterArr;
                    // console.log("list", list);
                    newarr = newarr.concat(list);
                    // console.log("newarr", newarr);
                    setoffSet(start + 50);
                    SetfilterArr(newarr);
                    SetRows(newarr);
                    settotalLength(data?.data?.count);
                })
                .catch((err) => { });
        }
    };

    const searchItem = (event) => {
        if (searchTerm.toString().length > 0) {
            let allData = allRow;
            const filteredSearch = allData.filter((elem) => {
                if (elem.contenttitle && elem.contenttitle !== null) {
                    return elem.contenttitle.includes(searchTerm);
                }
            });
            settotalLength(filteredSearch.length);
            // SetRows(filteredSearch);
            handleFilterApply({ searchterm: searchTerm.toString()});
            SetsearchTitle(searchTerm.toString());
            // SetsearchCountry('');
            // SetsearchCreated_by('');
            setPage(0);
            setoffSet(0);
            // SetfilterOn(true);
        } else {
            getSetContents({ start: 0 });
            setPage(0);
            setoffSet(0);
            SetsearchTitle("");
            SetsearchCountry("");
            SetsearchCreated_by("");
            SetfilterOn(false);
        }
    };

    const handleSearchTerm = (e, value) => {
        if (e.keyCode === 13) {
            SetfilterOn(true)
            let term = e.target.value;
            if (term.length > 0) {
                if (value != null) {
                    term = value;
                }
                let allData = allRow;
                if (term.toString().length > 0) {
                    handleFilterApply({ searchterm: term.toString() });
                }

            } else {
                SetRows(filterArr);
                settotalLength(allRow.length)
                setPage(0)
                setoffSet(0)
                getSetContents({ start: 0 })
                SetsearchTitle("")
                SetsearchCountry('')
                SetsearchCreated_by('')
                SetfilterOn(false)
            }
        }
    }

    const handleTextInputChange = event => {
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const BreadcrumbData = [
        { value: 'Home', path: '/home' },
        { value: 'Content Management', path: '/content-management' }
    ];

    const handleDelete = () => {
        if (editRecordId.length !== 0) {
            contentService.deleteContentById(editRecordId).then((data) => {
                if (data.status === 200) {
                    //getSetContents(selrowID);
                    let allData = filterArr;
                    let filteredData = allData.filter(elem => {
                        if (elem.id && elem.id !== null) {
                            return elem.id !== editRecordId;
                        }
                        return false;
                    });
                    SetRows(filteredData);
                    let rowsData = JSON.parse(JSON.stringify(rows))
                    let allRowsData = JSON.parse(JSON.stringify(allRow))
                    rowsData = rowsData.filter(elem => {
                        if (elem?.id && elem?.id !== null) {
                            return elem.id !== editRecordId;
                        }
                        return false;
                    });
                    SetRows(rowsData)
                    allRowsData = allRowsData.filter(elem => {
                        if (elem?.id && elem?.id !== null) {
                            return elem.id !== editRecordId;
                        }
                        return false;
                    });
                    setallRow(allRowsData)
                    setPage(0);
                    setSearchTerm('');
                    let totalrowcount = totalLength;
                    settotalLength(totalrowcount - 1);
                    handleDelConfirmClose();
                }
            }).catch((err) => { });
        }
        // window.location.reload();
    }
    
    const handleDelConfirmClose = () => {
        setopenDelConfirmModal(false);
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
        SetMenuopen(true);
    };

    const handleMenuClose = (event) => {
        setAnchorEl(null);
        SetMenuopen(false);
    };

    const handleReportClick = (event) => {
        setAnchorReportEl(event.currentTarget);
        SetDownloadReport(true);
    };

    const handleReportClose = (event) => {
        setAnchorReportEl(null);
        SetDownloadReport(false);
    };

    return (
        <>
            <Snackbar
                open={snackbarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={2000}
                onClose={() => { setSnackbarOpen(false) }}>
                <div className={`snackbar ${snackbarType}`}>
                    <div>
                        {snackbarType === 'success' ?
                            <img src={require('../../../public/img/download-01-white.png')}
                                alt='Download' />
                            :
                            <IconButton>
                                <ErrorOutlineIcon style={{ fill: "white" }} />
                            </IconButton>}</div>
                    <div>
                        {snackbarMsg}
                    </div>
                    <div className='snakcbar-close'
                        onClick={() => { setSnackbarOpen(false) }}>
                        <IconButton>
                            <CloseIcon style={{ fill: "white" }} />
                        </IconButton>
                    </div>
                </div>
            </Snackbar>
            <Modal open={openDelConfirmModal} onClose={handleDelConfirmClose}>
                <Grid className='paper modalstyle'>
                    <Grid className="modal-title">
                        <button className='closeModal' onClick={() => handleDelConfirmClose()}></button>
                    </Grid>
                    <Grid className="modal-body">
                        <div className="m20">
                            <Alert severity="warning">Content <b>{selectedContent}</b> will be deleted, are you sure?</Alert>
                        </div>
                    </Grid>
                    <Grid className="modal1FoBtn modal-footer">
                        <button type="button" onClick={() => handleDelConfirmClose()} className="modalBtn">Cancel</button>
                        <button type="button" onClick={() => handleDelete()} className="modalBtn">OK</button>
                    </Grid>
                </Grid>
            </Modal>
            <Modal open={exportModal} onClose={() => SetExportModal(false)}>
                <Grid className='paper modalstyle'>
                    <Grid className="modal-title">Expiration Date Report</Grid>
                    <Grid className="modal-body">
                        <div className="m20 text-center">
                            <CSVLink filename={"vvpm-report-30days.csv"} data={VVPMReport30DaysCSVData} id="vvpmReport">
                                <Button className='content-grn-Btn download-csv'>
                                    <GetAppIcon /> 30 days
                                </Button>
                            </CSVLink>
                            <CSVLink filename={"vvpm-report-60days.csv"} data={VVPMReport60DaysCSVData} id="vvpmReport">
                                <Button className='content-grn-Btn download-csv'>
                                    <GetAppIcon /> 60 days
                                </Button>
                            </CSVLink>
                        </div>
                    </Grid>
                    <Grid className="modal1FoBtn modal-footer">
                        <button type="button" onClick={() => SetExportModal(false)} className="modalBtn">Close</button>
                    </Grid>
                </Grid>
            </Modal>
            <Dialog
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "800px"
                        },
                    },
                }} open={openStatusModal} className='status-modal' >
                <div className='modal-heading'>
                    <div className='info-modal'><img src={InfoIcon} alt='info-icon' /></div>
                    <div className='modal-heading-text'>
                        <div>Status Definition</div>
                        <div className='modal-heading-subtext'>These are definitions of content status</div>
                    </div>
                    <div className='close-modal'>
                        <IconButton
                            aria-label="close"
                            onClick={() => setOpenStatusModal(false)}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <DialogContent sx={{ p: 1 }}>
                    <Grid className='status-definition'>
                        <div className='custom-tooltip'>
                            <div className='tooltip-headers' >
                                <div>Status </div>
                                <div>Description </div>
                            </div>
                            <div className='tooltip-body content-status'>
                                <div className='content-head'>
                                    <div className='single-content Draft'>
                                        <div className='dot' />
                                        <div className='text'>
                                            Draft
                                        </div>
                                    </div>
                                    <div>When you first upload content, it's labeled as a "Draft". This means it's saved and ready, but not yet submitted for review. It's your content's starting point.</div>
                                </div>
                                <div className='content-head'>
                                    <div className='single-content Pending'>
                                        <div className='dot' />
                                        <div className='text'>
                                            Pending
                                        </div>
                                    </div>
                                    <div>Once you have submitted for approval, your content moves to "Pending". This means that the Publisher from your affiliate is currently reviewing it. Once its "Approved" or "Rejected", you will see the new status for the content. </div>
                                </div>
                                <div className='content-head'>
                                    <div className='single-content Rejected'>
                                        <div className='dot' />
                                        <div className='text'>
                                            Rejected
                                        </div>
                                    </div>
                                    <div>Your content has been rejected by the Publisher of your affiliate for some reason. Please, reach out to him/her in order to understand why, and apply, if needed, the necessary changes. Your content will not be visible to the viewers. </div>
                                </div>
                                <div className='content-head'>
                                    <div className='single-content Published'>
                                        <div className='dot' />
                                        <div className='text'>Published
                                        </div>
                                    </div>
                                    <div>Your content its now live and it will be marked "Published". This means its now accessible for the viewers in the platform.</div>
                                </div>
                                <div className='content-head'>
                                    <div className='single-content Inactive'>
                                        <div className='dot' />
                                        <div className='text'>
                                            Inactive
                                        </div>
                                    </div>
                                    <div>Content might move to "Inactive" in two scenarios: it reaches its set expiration date, or a Publisher chooses to take it offline for a period.</div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "800px"
                        },
                    },
                }}
                open={generalReportModal}
                className='status-modal content-report-modal' >
                <div className='modal-heading'>
                    <div className='info-modal'>
                        <img src={GraphFileRed} alt='reject-icon' />
                    </div>
                    <div className='modal-heading-text'>
                        <div> Content Report </div>
                        <div className='modal-heading-subtext'>Select the filters to generate your content report.</div>
                    </div>
                    <div className='close-modal'>
                        <IconButton
                            aria-label="close"
                            onClick={() => { setGeneralReportModal(false) }}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <ContentManagementReportModal
                    openSnackbar={(value, message, type) => { setSnackbarMsg(message); setSnackbarType(type); setSnackbarOpen(value) }}
                    countryList={countries}
                    categoryList={brands}
                    therapeuticAreaList={therapeuticAreas}
                    contentTypeList={[{ id: 'video', name: 'Video' }, { id: 'podcast', name: 'Podcast' }]}
                    ownerList={userData}
                    yearList={years}
                    created_by={{
                        'user_name':
                            sessionState.sessionValue?.payload?.user?.user_name,
                        'user_id':
                            sessionState.sessionValue?.payload?.user?.id
                    }}
                    handleClose={() => { setGeneralReportModal(false) }}
                />
            </Dialog>
            <>
                {Object.keys(activeContentSelected).length > 0
                    && (
                        <>
                            {
                                activeContentSelected.record_type === 'SubmitToPublish' && (
                                    <ContentSubmitPublishModal
                                        open={openChangeStatusModal}
                                        handleClose={(value) => {
                                            setOpenChangeStatusModal(value); setActiveContentSelected({});
                                        }}
                                        content={activeContentSelected}
                                        onCloseRefresh={true}
                                        onSubmitApi={true}
                                    />
                                )
                            }
                            {
                                activeContentSelected.record_type === 'Publish' && (
                                    <ContentPublishModal
                                        open={openChangeStatusModal}
                                        handleClose={(value) => {
                                            setOpenChangeStatusModal(value); setActiveContentSelected({});
                                        }}
                                        content={activeContentSelected}
                                        onCloseRefresh={true}
                                        onSubmitApi={true}
                                    />
                                )
                            }
                            {
                                activeContentSelected.record_type === 'Reject' && (
                                    <ContentRejectModal
                                        open={openChangeStatusModal}
                                        handleClose={(value) => {
                                            setOpenChangeStatusModal(value); setActiveContentSelected({});
                                        }}
                                        content={activeContentSelected}
                                        onSubmitApi={true}
                                        onCloseRefresh={true}
                                    />
                                )
                            }
                            {
                                activeContentSelected.record_type === 'Inactive' && (
                                    <ContentInactiveModal
                                        open={openChangeStatusModal}
                                        handleClose={(value) => {
                                            setOpenChangeStatusModal(value); setActiveContentSelected({});
                                        }}
                                        content={activeContentSelected}
                                        onSubmitApi={true}
                                        onCloseRefresh={true}
                                    />
                                )
                            }
                        </>
                    )
                }
            </>
            <Breadcrumb translations={props.translations} setFilter={props.setFilter} BreadcrumbData={BreadcrumbData} />
            <div className='content-mgmt'>
                <div className="main-wrp">
                    <Grid className="mgmt_header">
                        <Grid container className="row content-section">
                            <Grid item lg={6} sm={6} xs={12}>
                                <label className="label-title">
                                    <span className='label-hd-title'> Content Management </span>
                                    <span className='content-count'>| {totalLength} contents</span>
                                </label>
                                &nbsp;&nbsp;&nbsp;
                            </Grid>
                            <Grid item lg={6} sm={6} xs={12}>
                                <div className='content-buttons'>
                                    <button className='statusbutton' onClick={() => { setOpenStatusModal(true) }}>
                                        <img className='actnBtn' src={require('../../../public/img/status-1x.png')} alt='Download' /> Status Definition
                                    </button>
                                    <div>
                                        <button className='downloadbutton'
                                            onClick={handleReportClick}
                                        >
                                            <img className='actnBtn' src={GraphFileGray} alt='Download' /> Generate Report
                                        </button>
                                        <Menu
                                            className='downloadMenu'
                                            elevation={0}
                                            id="demo-customized-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'demo-customized-button',
                                                onMouseLeave: handleReportClose
                                            }}
                                            anchorEl={anchorReportEl}
                                            open={downloadReport}
                                            onClose={handleReportClose}
                                        >
                                            <MenuItem onClick={() => { handleReportClose(); setGeneralReportModal(true) }} disableRipple>
                                                <span>
                                                    <span><img className='actnBtn' src={require('../../../public/img/download-1x.png')} alt='Download' /> Content Report</span>
                                                </span>
                                            </MenuItem>
                                            <MenuItem onClick={handleReportClose} disableRipple>
                                                <CSVLink className='reportBtn' filename={"quiz-report.csv"} data={csvData} id="quizReport">
                                                    <span><img className='actnBtn' src={require('../../../public/img/download-1x.png')} alt='Download' /> Quiz Report</span>
                                                </CSVLink>
                                            </MenuItem>
                                        </Menu>
                                    </div>
                                    <button className='uploadbutton' onClick={() => navigate('/content-update')}>
                                        <img className='actnBtn' src={require('../../../public/img/upload-1x.png')} alt='Download' /> Upload Content
                                    </button>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container className="row search-section">
                            <Grid item lg={3} sm={4} xs={12}>
                                <div className='search_bar searchBx'>
                                    <Autocomplete
                                        open={open}
                                        onOpen={() => { if (searchTerm) { setOpen(true); } }}
                                        onClose={() => setOpen(false)}
                                        inputValue={searchTerm}
                                        onInputChange={(e, value) => {
                                            setSearchTerm(value);
                                            if (value == null) {
                                                setFilteredContents([]);
                                            } else {
                                                let newSpeakersData = allRow.filter((item) => {
                                                    if (item.title != null) {
                                                        item.contenttitle = item.title
                                                        let title = item.title.toLowerCase();
                                                        return title.includes(value.toLowerCase());
                                                    }
                                                });
                                                setFilteredContents(newSpeakersData);
                                                const found = allRow.some(el => { if (el.title != null) { return el.title.toLowerCase() === value.toLowerCase() } });
                                                if (!found) { setNewContentTerm(value); } else { setNewContentTerm(''); }
                                            }
                                            if (!value) { setOpen(false); }
                                        }}
                                        value={searchTerm}
                                        onChange={handleSearchTerm}
                                        onKeyDown={handleSearchTerm}
                                        freeSolo
                                        options={filteredContents.length > 0 || newContentTerm != '' ? filteredContents : filterArr}
                                        getOptionLabel={(option) => typeof option === 'string' ? option : option.contenttitle}
                                        renderInput={(params) =>
                                            <TextField {...params} placeholder="Search Podcast or Video name" onInput={handleTextInputChange} name="search_name" size="small"
                                                InputLabelProps={{ shrink: false }}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    disableUnderline: true,
                                                    startAdornment: <InputAdornment onClick={() => searchItem()} position="start"><IconButton className="mp0" ><SearchIcon /></IconButton></InputAdornment>
                                                }}
                                            />}
                                    />
                                </div>
                            </Grid>
                            <Grid item lg={9} sm={8} xs={12}>
                                <div className='search-section-subsection'>

                                    <div></div>
                                    {openFilterModal ?
                                        <div className='filter'>
                                            <button className='handIcon' onClick={handleFilterBtn}>
                                                Close <CloseIcon />
                                            </button>
                                        </div>
                                        :
                                        <div className='filter'>
                                            <button className='handIcon' onClick={handleFilterBtn}>
                                                <FilterListIcon /> Filters
                                            </button>
                                        </div>
                                    }
                                    <div className='reset'>
                                        <button
                                            className='handIcon'
                                            onClick={resetFilter}>
                                            <img
                                                src={require('../../../public/img/2x-reload.png')}
                                                alt='Download' />
                                        </button>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <div className={openFilterModal ? 'filter-section open' : 'filter-section close'} >
                            {openFilterModal ?
                                <div className="filter-grid">
                                    <ClickAwayListener onClickAway={() => {
                                        setCountryFilter(false)
                                    }}>
                                        <div className='filter-dropdown'>
                                            <div className='filter-clickarea' onClick={() => { handleFilterCloseClick('country') }}>
                                                <div className='filter-title' > Country </div>
                                                <KeyboardArrowDownIcon className={countryFilter ? 'rotate-arrow filter-arrow-icon' : 'filter-arrow-icon'} />
                                            </div>
                                            <Multiselect
                                                title={'Country'}
                                                tags={selectedFilters['country']}
                                                open={countryFilter}
                                                list={countries.length > 0 ? countries.map(x => ({ label: x.country_name, key: x.country_code })) : []}
                                                handleSelected={(value) => { handleSelected({ 'country': value }) }}
                                            />
                                        </div>
                                    </ClickAwayListener>
                                    <ClickAwayListener onClickAway={() => {
                                        setBrandFilter(false)
                                    }}>
                                        <div className='filter-dropdown'>
                                            <div className='filter-clickarea' onClick={() => { handleFilterCloseClick('brand') }}>
                                                <div className='filter-title' > Category </div>
                                                <KeyboardArrowDownIcon className={brandFilter ? 'rotate-arrow filter-arrow-icon' : 'filter-arrow-icon'} />
                                            </div>
                                            <Multiselect
                                                title={'Category'}
                                                tags={selectedFilters['brand']}
                                                open={brandFilter}
                                                list={brands.length > 0 ? brands.map(x => ({ label: `${x.category_1_name} (${x.category_management_tum.therapeutic_area_name})`, key: x.category_1_id })) : []}
                                                handleSelected={(value) => { handleSelected({ 'brand': value }) }}
                                            />
                                        </div>
                                    </ClickAwayListener>
                                    <ClickAwayListener onClickAway={() => {
                                        setTAFilter(false)
                                    }}>
                                        <div className='filter-dropdown'>
                                            <div className='filter-clickarea' onClick={() => { handleFilterCloseClick('ta') }}>
                                                <div className='filter-title' > TA </div>
                                                <KeyboardArrowDownIcon className={taFilter ? 'rotate-arrow filter-arrow-icon' : 'filter-arrow-icon'} />
                                            </div>
                                            <Multiselect
                                                title={'TA'}
                                                tags={selectedFilters['ta']}
                                                open={taFilter}
                                                list={therapeuticAreas.length > 0 ? therapeuticAreas.map(x => ({ label: x.therapeutic_area_name, key: x.therapeutic_area_id })) : []}
                                                handleSelected={(value) => { handleSelected({ 'ta': value }) }}
                                            />
                                        </div>
                                    </ClickAwayListener>
                                    <ClickAwayListener onClickAway={() => {
                                        setOwnerFilter(false)
                                    }}>
                                        <div className='filter-dropdown'>
                                            <div className='filter-clickarea' onClick={() => { handleFilterCloseClick('owner') }}>
                                                <div className='filter-title' > Owner </div>
                                                <KeyboardArrowDownIcon className={ownerFilter ? 'rotate-arrow filter-arrow-icon' : 'filter-arrow-icon'} />
                                            </div>
                                            <Singleselect
                                                title={'Owner'}
                                                tags={selectedFilters['owner']}
                                                open={ownerFilter}
                                                list={userData.length > 0 ? userData.map(x => ({ label: x.fullName, key: x.id })) : []}
                                                handleSelected={(value) => { handleSelected({ 'owner': value }) }}
                                            />
                                        </div>
                                    </ClickAwayListener>
                                    <ClickAwayListener onClickAway={() => {
                                        setSpeakerFilter(false)
                                    }}>
                                        <div className='filter-dropdown'>
                                            <div className='filter-clickarea' onClick={() => { handleFilterCloseClick('speaker') }}>
                                                <div className='filter-title' > Speaker </div>
                                                <KeyboardArrowDownIcon className={speakerFilter ? 'rotate-arrow filter-arrow-icon' : 'filter-arrow-icon'} />
                                            </div>
                                            <Multiselect
                                                title={'Speaker'}
                                                tags={selectedFilters['speaker']}
                                                open={speakerFilter}
                                                list={speakers.length > 0 ? speakers.map(x => ({ label: x.name, key: x.speaker_id })) : []}
                                                handleSelected={(value) => { handleSelected({ 'speaker': value }) }}
                                            />
                                        </div>
                                    </ClickAwayListener>
                                    <ClickAwayListener onClickAway={() => {
                                        setVvpmFilter(false)
                                    }}>
                                        <div className='filter-dropdown'>
                                            <div className='filter-clickarea' onClick={() => { handleFilterCloseClick('vvpm') }}>
                                                <div className='filter-title' > VVPM Code </div>
                                                <KeyboardArrowDownIcon className={vvpmFilter ? 'rotate-arrow filter-arrow-icon' : 'filter-arrow-icon'} />
                                            </div>
                                            <>
                                                {vvpmFilter ?
                                                    <div className='filter-dropdown-popout'>
                                                        <div>
                                                            <div>
                                                                <label className='filterLabel'>
                                                                    VVPM Code
                                                                </label>
                                                                <div className='filterInputWrapping text-input'>
                                                                    <input
                                                                        placeholder={`Enter VVPM Code`}
                                                                        value={vvpmFilterText}
                                                                        onChange={(value) => handleVvpmSearch(value)}
                                                                    />
                                                                    <button onClick={() => handleVvpmKeydown()}>
                                                                        OK
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ''}
                                            </>
                                        </div>
                                    </ClickAwayListener>
                                    <ClickAwayListener onClickAway={() => {
                                        setContentTypeFilter(false)
                                    }}>
                                        <div className='filter-dropdown'>
                                            <div className='filter-clickarea' onClick={() => { handleFilterCloseClick('content_type') }}>
                                                <div className='filter-title' > Content Type </div>
                                                <KeyboardArrowDownIcon className={contentTypeFilter ? 'rotate-arrow filter-arrow-icon' : 'filter-arrow-icon'} />
                                            </div>
                                            <Multiselect
                                                title={'Content Type'}
                                                tags={selectedFilters['content_type']}
                                                open={contentTypeFilter}
                                                list={[{ id: 'video', name: 'Video' }, { id: 'podcast', name: 'Podcast' }].map(x => ({ label: x.name, key: x.id }))}
                                                handleSelected={(value) => { handleSelected({ 'content_type': value }) }}
                                            />
                                        </div>
                                    </ClickAwayListener>
                                    <ClickAwayListener onClickAway={() => {
                                        setStatusFilter(false)
                                    }}>
                                        <div className='filter-dropdown'>
                                            <div className='filter-clickarea' onClick={() => { handleFilterCloseClick('status') }}>
                                                <div className='filter-title' > Status </div>
                                                <KeyboardArrowDownIcon className={statusFilter ? 'rotate-arrow filter-arrow-icon' : 'filter-arrow-icon'} />
                                            </div>
                                            <Multiselect
                                                title={'Status'}
                                                tags={selectedFilters['status']}
                                                open={statusFilter}
                                                list={[
                                                    { label: 'Draft', id: 'Draft' },
                                                    { label: 'Inactive', id: 'Inactive' },
                                                    { label: 'Pending', id: 'Pending' },
                                                    { label: 'Published', id: 'Active' },
                                                    { label: 'Rejected', id: 'Rejected' },
                                                ].map(x => ({ label: x.label, key: x.id }))}
                                                handleSelected={(value) => { handleSelected({ 'status': value }) }}
                                            />
                                        </div>
                                    </ClickAwayListener>
                                </div>
                                : ''}
                            {openFilterModal ?
                                <div className="filter-selected-grid">
                                    {selectedFilters
                                        && Object.keys(selectedFilters).length > 0
                                        && Object.keys(selectedFilters).map(x => (
                                            selectedFilters[x].length > 0 && selectedFilters[x].map(s => (
                                                <div className='selected-filter' key={s.key}>
                                                    <span>{s.label}</span>
                                                    <CloseIcon onClick={() => handleDeleted(x, s.key)} />
                                                </div>
                                            ))
                                        ))}
                                </div> : ''}
                            {openFilterModal ? <div className="filter-buttons">
                                <button className='pill red' onClick={() => handleFilterApply({ searchterm: searchTerm })}>Apply Filters</button>
                                <button className='pill' onClick={() => handleFilterReset()} >Clear all Filters</button>
                            </div> :
                                ''}
                        </div>
                    </Grid>
                    <TableContainer>
                        <Table className='table'>
                            <EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={rows.length} />
                            <TableBody>
                                {stableSort(rows, getComparator(order, orderBy), orderBy, order)
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row.name);
                                        return (
                                            <TableRow
                                                hover
                                                className="content-management-table-body"
                                                onClick={(event) => handleClick(event, row.name)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.id}
                                                selected={isItemSelected}
                                            >
                                                <TableCell align="left"><b>{row.contenttitle}</b><br />{row.typeofcontent}</TableCell>
                                                <TableCell align="left">{getDateTime(row.uploadtime)}</TableCell>
                                                <TableCell align="left">{row.duration}</TableCell>
                                                <TableCell align="left">
                                                    <div onClick={() => {
                                                        if (row.id === selrowID) {
                                                            setselrowID('')
                                                        } else {
                                                            setselrowID(row.id)
                                                        }
                                                    }}>
                                                        <ContentStatus
                                                            open={row.id === selrowID}
                                                            content={row.content_approved} />
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <div className='action-items'>
                                                        {Object.keys(row.content_approved).length === 1 ?
                                                            <div>
                                                                {
                                                                    (row.content_approved[Object.keys(row.content_approved)[0]].status === 'Pending') && sessionState.sessionValue?.payload?.user.role_ids.includes(3) && row.access ? (
                                                                        <div className='no-bg action-icon '
                                                                            onClick={() => {
                                                                                setOpenChangeStatusModal(true);
                                                                                setActiveContentSelected({
                                                                                    id: row.id,
                                                                                    content_approved: row.content_approved,
                                                                                    title: row.contenttitle,
                                                                                    type: row.typeofcontent,
                                                                                    record_type: 'Reject',
                                                                                    country: 'single',
                                                                                    vvpm_details: row.vvpm_details,
                                                                                    secondary_owner: row.second_owner,
                                                                                    owner: row.owner,
                                                                                    created: row.created_at,
                                                                                })
                                                                            }}
                                                                        >
                                                                            <Tooltip title="Reject" placement='bottom' arrow>
                                                                                <img src={MinusIcon} alt='unpublish-icon' />
                                                                            </Tooltip>
                                                                        </div>
                                                                    ) : ''
                                                                }
                                                                {
                                                                    row.content_approved[Object.keys(row.content_approved)[0]].status === 'Pending' && sessionState.sessionValue?.payload?.user.role_ids.includes(3) && row.access ? (
                                                                        <div className='action-icon'
                                                                            onClick={() => {
                                                                                setOpenChangeStatusModal(true);
                                                                                setActiveContentSelected({
                                                                                    id: row.id,
                                                                                    content_approved: row.content_approved,
                                                                                    title: row.contenttitle,
                                                                                    type: row.typeofcontent,
                                                                                    record_type: 'Publish',
                                                                                    country: 'single',
                                                                                    vvpm_details: row.vvpm_details,
                                                                                    secondary_owner: row.second_owner,
                                                                                    owner: row.owner,
                                                                                    created: row.created_at,
                                                                                })
                                                                            }}
                                                                        >
                                                                            <Tooltip title="Publish" placement='bottom' arrow>
                                                                                <img src={Telegram} alt='publish-icon' />
                                                                            </Tooltip>
                                                                        </div>
                                                                    ) : ''
                                                                }
                                                                {
                                                                    row.content_approved[Object.keys(row.content_approved)[0]].status === 'Draft' && sessionState.sessionValue?.payload?.user.role_ids.includes(3) && row.access ? (
                                                                        <div className='action-icon'
                                                                            onClick={() => {
                                                                                setOpenChangeStatusModal(true);
                                                                                setActiveContentSelected({
                                                                                    id: row.id,
                                                                                    content_approved: row.content_approved,
                                                                                    title: row.contenttitle,
                                                                                    type: row.typeofcontent,
                                                                                    record_type: 'SubmitToPublish',
                                                                                    country: 'single',
                                                                                    vvpm_details: row.vvpm_details,
                                                                                    secondary_owner: row.second_owner,
                                                                                    owner: row.owner,
                                                                                    created: row.created_at,
                                                                                })
                                                                            }}
                                                                        >
                                                                            <Tooltip title={"Submit to Publish"} placement='bottom' arrow>
                                                                                <img src={UploadIcon} alt='upload-icon' />
                                                                            </Tooltip>
                                                                        </div>
                                                                    ) : ''
                                                                }
                                                                {row.content_approved[Object.keys(row.content_approved)[0]].status === 'Draft' && sessionState.sessionValue?.payload?.user.role_ids.includes(3) && !row.access ?
                                                                    (
                                                                        <div className='action-icon'
                                                                            onClick={() => {
                                                                                setOpenChangeStatusModal(true);
                                                                                setActiveContentSelected({
                                                                                    id: row.id,
                                                                                    content_approved: row.content_approved,
                                                                                    title: row.contenttitle,
                                                                                    type: row.typeofcontent,
                                                                                    record_type: 'SubmitToPublish',
                                                                                    country: 'single',
                                                                                    vvpm_details: row.vvpm_details,
                                                                                    secondary_owner: row.second_owner,
                                                                                    owner: row.owner,
                                                                                    created: row.created_at,
                                                                                })
                                                                            }}
                                                                        >
                                                                            <Tooltip title={"Submit for Approval"} placement='bottom' arrow>
                                                                                <img src={UploadIcon} alt='upload-icon' />
                                                                            </Tooltip>

                                                                        </div>
                                                                    ) : ''}
                                                                {
                                                                    row.content_approved[Object.keys(row.content_approved)[0]].status === 'Draft' && !sessionState.sessionValue?.payload?.user.role_ids.includes(3) ? (
                                                                        <div className='action-icon'
                                                                            onClick={() => {
                                                                                setOpenChangeStatusModal(true);
                                                                                setActiveContentSelected({
                                                                                    id: row.id,
                                                                                    content_approved: row.content_approved,
                                                                                    title: row.contenttitle,
                                                                                    type: row.typeofcontent,
                                                                                    record_type: 'SubmitToPublish',
                                                                                    country: 'single',
                                                                                    vvpm_details: row.vvpm_details,
                                                                                    secondary_owner: row.second_owner,
                                                                                    owner: row.owner,
                                                                                    created: row.created_at,
                                                                                })
                                                                            }}
                                                                        >
                                                                            <Tooltip title={"Submit for Approval"} placement='bottom' arrow>
                                                                                <img src={UploadIcon} alt='upload-icon' />
                                                                            </Tooltip>
                                                                        </div>
                                                                    ) : ''
                                                                }
                                                                {
                                                                    ((row.content_approved[Object.keys(row.content_approved)[0]].status === 'Active' || row.content_approved[Object.keys(row.content_approved)[0]].status === 'Published') && sessionState.sessionValue?.payload?.user.role_ids.includes(3) && row.access ? (
                                                                        <div className='no-bg action-icon '
                                                                            onClick={() => {
                                                                                setOpenChangeStatusModal(true);
                                                                                setActiveContentSelected({
                                                                                    id: row.id,
                                                                                    content_approved: row.content_approved,
                                                                                    title: row.contenttitle,
                                                                                    type: row.typeofcontent,
                                                                                    record_type: 'Inactive',
                                                                                    country: 'single',
                                                                                    vvpm_details: row.vvpm_details,
                                                                                    secondary_owner: row.second_owner,
                                                                                    owner: row.owner,
                                                                                    created: row.created_at,
                                                                                })
                                                                            }}
                                                                        >
                                                                            <Tooltip title="Inactive" placement='bottom' arrow>
                                                                                <img src={MinusIcon} alt='unpublish-icon' />
                                                                            </Tooltip>
                                                                        </div>
                                                                    )
                                                                        : '')
                                                                }
                                                            </div> :
                                                            <div>
                                                            </div>
                                                        }
                                                        <IconButton
                                                            aria-label="more"
                                                            id="long-button"
                                                            aria-controls={menuopen ? 'long-menu' : undefined}
                                                            aria-expanded={menuopen ? 'true' : undefined}
                                                            aria-haspopup="true"
                                                            onClick={handleMenuClick}
                                                            onMouseEnter={() => {
                                                                setselectedContent(row.contenttitle);
                                                                setselrowID(row.id)
                                                                setEditRecordId(row.id)
                                                                setSelectedAction(row.is_frozen);
                                                            }}
                                                            onMouseLeave={() => {
                                                                setselrowID('');
                                                                setEditRecordId(row.id);
                                                                setSelectedAction(row.is_frozen);
                                                            }}
                                                        >
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                        <Menu
                                                            id="long-menu"
                                                            MenuListProps={{
                                                                'aria-labelledby': 'long-button',
                                                                onMouseLeave: handleMenuClose
                                                            }}
                                                            anchorEl={anchorEl}
                                                            open={menuopen}
                                                        >
                                                            <MenuItem>
                                                                {setBlueColor && activeId === row.id ? (
                                                                    <Link onClick={() => navigate('/content-update/' + editRecordId)}>
                                                                        <img onMouseLeave={() => { setFilteredBluecolor(false); setActiveId('') }} className='actnBtn' src={require('../../../public/img/blue-edit-2x.png')} alt='Edit' />
                                                                        <span className='blue_button' >{selectedAction}</span>
                                                                    </Link>
                                                                ) : (
                                                                    <Link onClick={() => navigate('/content-update/' + editRecordId)}>
                                                                        <img onMouseEnter={() => { setFilteredBluecolor(true); setActiveId(row.id) }} className='actnBtn' src={require('../../../public/img/black-edit-2x.png')} alt='Edit' />
                                                                        <span className={'blue_button'} >{selectedAction}</span>
                                                                    </Link>
                                                                )}
                                                            </MenuItem>
                                                            <MenuItem >
                                                                {setRedColor && activeId === row.id ? (
                                                                    <div
                                                                        onMouseLeave={() => {
                                                                            setFilteredRedcolor(false);
                                                                            setActiveId('');
                                                                        }}
                                                                        onClick={() => {
                                                                            //setselectedContent(row.contenttitle);
                                                                            setopenDelConfirmModal(true); //setselrowID(row.id)
                                                                        }}>
                                                                        <img
                                                                            className='actnBtnDel'
                                                                            src={require('../../../public/img/red-trash-2x.png')}
                                                                            alt='Delete' />
                                                                        <span className='red_button'>Delete</span>
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        onMouseEnter={() => {
                                                                            setFilteredRedcolor(true);
                                                                            setActiveId(row.id);
                                                                        }}
                                                                        onClick={() => {
                                                                            //setselectedContent(row.contenttitle);
                                                                            setopenDelConfirmModal(true);
                                                                            //setselrowID(row.id)
                                                                        }}>
                                                                        <img
                                                                            className='actnBtnDel'
                                                                            src={require('../../../public/img/black-trash-2x.png')}
                                                                            alt='Delete' />
                                                                        <span className='grey_button'>Delete</span>
                                                                    </div>
                                                                )}
                                                            </MenuItem>
                                                        </Menu>
                                                    </div>
                                                </TableCell>
                                                {/* <TableCell align="left">{row.typeofcontent}</TableCell> */}
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        {rows.length <= 0 ?
                                            <TableCell align="center" colSpan={9}>{fetchDataStatus ? 'No Records Found.' : 'Loading...'}</TableCell> :
                                            <TableCell colSpan={9} />
                                        }
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <ButtonPagination
                        count={totalLength % rowsPerPage === 0 ?
                            Math.floor(totalLength / rowsPerPage) : Math.floor(totalLength / rowsPerPage) + 1}
                        page={page}
                        onPageChange={(page) => handleChangePage('', page)}
                        totalLength={10}
                    />
                </div>
            </div >
        </>
    );
}