import React from 'react';
import { Grid } from "@material-ui/core";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import { CSVLink } from "react-csv";
import Modal from '@material-ui/core/Modal';
import Alert from '@material-ui/lab/Alert';

const TranslationExport = (props) => {
    const [personName, setPersonName] = React.useState('Select Locale');
    const [names, setName] = React.useState([]);
    const [csvData, SetcsvData] = React.useState([]);
    const [validationPopup, setvalidationPopup] = React.useState(false);

    const theme = useTheme();

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }
    const handleChange = (event) => {
        let value = event.target.value;
        const csv = [
            ["no", "locale", "text", "key"]
        ]
        if (value !== "All") {
            for (let i = 0; i < props.data.length; i++) {
                if (props.data[i].locale === value) {
                    csv.push([i + 1, props.data[i].locale, props.data[i].text, props.data[i].key])
                }
            }
        } else {
            for (let i = 0; i < props.data.length; i++) {
                csv.push([i + 1, props.data[i].locale, props.data[i].text, props.data[i].key])
            }
        }
        SetcsvData(csv)
        setPersonName(event.target.value)

    };
    React.useEffect(() => {

        let nameArray = [];
        nameArray.push('Select Locale')
        nameArray.push('All')
        for (let i = 0; i < props.data.length; i++) {
            if (!nameArray.includes(props.data[i].locale)) {
                nameArray.push(props.data[i].locale)
            }
        }

        setName(nameArray)

    }, [props]);

    /**Function submits all the data for the created user */
    const handleSubmit = () => {

    }
    const handlvalidationPopup = () => {
        setvalidationPopup(false)
    }


    /**Function Alter Roles */


    return (
        <>
            <Modal open={validationPopup} onClose={handlvalidationPopup}>
                <Grid className='paper modalstyle'>
                    <Grid className="modal-title">
                        <button className='closeModal' onClick={() => handlvalidationPopup()}></button>
                    </Grid>
                    <Grid className="modal-body">
                        <div className="m20">


                            <Alert severity="warning">Select Locale: Select Locale cannot be left empty.</Alert>


                        </div>
                    </Grid>
                    <Grid className="modal1FoBtn modal-footer">
                        {/* <button type="button" onClick={() => handleDelConfirmClose()} className="modalBtn">Cancel</button> */}
                        <button type="button" onClick={() => handlvalidationPopup()} className="modalBtn">Close</button>
                    </Grid>
                </Grid>
            </Modal>
            <Grid className='paper modalstyle'>
                <Grid className="modal-title">
                    Translation Export:
                    <button className='closeModal' onClick={() => props.closePopUp(false)}></button>
                </Grid>
                {/**Create User Profile Section */}
                <Grid className="modal-body">
                    <label className='modalLabel lableTranslationEdit'>Select Locale</label>
                    <Select
                        labelId="demo-mutiple-name-label"
                        id="demo-mutiple-name"
                        className="formControl selectClas"
                        value={personName}
                        onChange={handleChange}
                        input={<Input />}
                        MenuProps={MenuProps}
                    >
                        {names.map((name) => (
                            <MenuItem key={name} value={name} style={getStyles(name, personName, theme)}>
                                {name}
                            </MenuItem>
                        ))}
                    </Select>

                </Grid>
                <Grid className="modal1FoBtn modal-footer">
                    {/* <button type="button" className="modalBtn" onClick={() => { props.closePopUp(false); }}>Cancel</button> */}
                    <button type="button" className="modalBtn" onClick={() => { handleSubmit(); props.closePopUp(false); }}>Cancel</button>

                    <CSVLink type="button" onClick={event => {
                        if (personName == 'Select Locale') {
                            setvalidationPopup(true)
                        } else {
                            return true;
                        }
                        return false; // 👍🏻 You are stopping the handling of component
                    }} className="modalBtn" data={csvData}>Export</CSVLink>
                </Grid>
                {/**End Create User Profile Section */}
            </Grid>
        </>
    )
}
export default TranslationExport;






