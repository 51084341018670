/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import {SessionService} from '../modules/login/Services/SessionService';
import config from '../../config/footerConfig.json';

const CookieBanner = (props) => {
    const [bannerVisible, setBannerVisible] = React.useState(false);
    const acceptCookie = () => {
        window.Syrenis_AcceptAllCookies();
        setBannerVisible(false);
        (new SessionService()).AcceptAllCookies()
    }
    const rejectCookie = () => {
        window.Syrenis_RejectAllCookies();
        setBannerVisible(false);
        (new SessionService()).DeleteAllGACookies()
    }
    const openCookie = () => {
        window.Syrenis_OpenCookieOptionsModal();
    }

    React.useEffect(() => {
        window.scrollTo(0, 0);
        let head = document.head;

        // let cookieLink = document.createElement("link");
        // cookieLink.type = "text/css";
        // cookieLink.rel = "stylesheet";
        // cookieLink.href = window.location.origin + "/styles/css/cookie_banner.css";
        // head.appendChild(cookieLink);

        // let cookieESLink = document.createElement("link");
        // cookieESLink.type = "text/css";
        // cookieESLink.rel = "stylesheet";
        // cookieESLink.href = window.location.origin + "/styles/css/cookie-banner-es.css";
        // head.appendChild(cookieESLink);

        // let cookieStyleLink = document.createElement("link");
        // cookieStyleLink.type = "text/css";
        // cookieStyleLink.rel = "stylesheet";
        // cookieStyleLink.href = "https://cscript-cdn-use." + props.countryDomainURL + "/V2/css/style.css";
        // head.appendChild(cookieStyleLink);

        let cookieScriptLink = document.createElement("script");
        cookieScriptLink.onload = function () {
            // eslint-disable-next-line no-unused-vars
            let cassieJsonObj = [];
            // eslint-disable-next-line no-unused-vars
            let identityServiceData;
            window.GetCookieWidget(props.countryWidgetID, '2BF772B5-F170-46F2-807B-100F7BB4059C', '8A121E9D-D624-4D89-863A-34B6957A1FCF', 'cookie-widget', '1', props.countryLocale, '', '');
            window.onmessage = function (e) {
                if (e.data !== '' && e.origin === 'https://ids-use.' + props.countryDomainURL) {
                    cassieJsonObj = JSON.parse(e.data);
                    identityServiceData = e.data;
                }
            };
        }
        cookieScriptLink.async = true;
        cookieScriptLink.src = "https://cscript-cdn-use." + props.countryDomainURL + "/widgetV3.js";
        head.appendChild(cookieScriptLink);

        setTimeout(() => {
            // if (document.getElementById('cookie-widget')) {
            //     document.getElementById('cookie-widget').style.display = "block";
            // }
            let cookieVariable = document.cookie.indexOf('consent_google_analytics');
            if (cookieVariable === -1) {
                setBannerVisible(true);
                const syrenisSettingsButton = document.querySelector('.View_all.pre-banner-button');
                if (syrenisSettingsButton) {
                    let oldCookieBanner = ['nl-BE','fr-BE','de-CH','fr-CH'];
                    if(!oldCookieBanner.includes(props.countryLocale)) {
                        syrenisSettingsButton.click();
                    }
                }
            } else {
                setBannerVisible(false);
            }
        }, 3000);

        return () => {
            // if (document.getElementById('cookie-widget')) {
            //     document.getElementById('cookie-widget').style.display = "none";
            // }
            // head.removeChild(cookieLink);
            // head.removeChild(cookieESLink);
            // head.removeChild(cookieStyleLink);
            head.removeChild(cookieScriptLink);
        }
    }, [props.displayOnTop, props.countryDomainURL, props.countryLocale, props.countryWidgetID]);

    window.OnCookieWidgetLoaded = () => {
        let oldCookieBanner = ['nl-BE','fr-BE','de-CH','fr-CH'];
        if(oldCookieBanner.includes(props.countryLocale)) {
            document.getElementById('save_preference').onclick = function rejectall() {
                setBannerVisible(false);
                (new SessionService()).DeleteAllGACookies()
            }
            if(document.querySelector('#strictly-necessary .switch')) {
                document.querySelector('#strictly-necessary .switch').style.display = "none";
            }
            if(document.querySelector('#strictly-necessary .inner_switch')) {
                document.querySelector('#strictly-necessary .inner_switch').style.display = "none";
            }
            if(document.querySelector('.syrenis-cookie-widget .popup .close')) {
                document.querySelector('.syrenis-cookie-widget .popup .close').style.display = "block";
            }
            if(document.querySelector('.syrenis-cookie-widget .popup')) {
                document.querySelector('.syrenis-cookie-widget .popup').style.width = "55%";
            }
            return false;
        }
        if(props.countryLocale === 'sv-SE' || props.countryLocale === 'fi-FI'){
            if(document.querySelector('#strictly-necessary .switch')) {
                document.querySelector('#strictly-necessary .switch').style.display = "none";
            }
            if(document.querySelector('#strictly-necessary .inner_switch')) {
                document.querySelector('#strictly-necessary .inner_switch').style.display = "none";
            }
        }
        if(props.countryLocale === 'it-IT') {
            const footerContent = document.getElementById('footer-content');
            const pMsg = document.createElement('p');
            pMsg.innerHTML = 'Per maggiori informazioni, può consultare la nostra ”Informativa sulla privacy” <a href="https://www.lillysite.net/it/lilly/informativa-sulla-privacy-pubblica.html" target="_blank">cliccando qui</a>.';
            pMsg.setAttribute('id', 'cookie-message');
            pMsg.style.padding = "0 48px";
            footerContent.before(footerContent.appendChild(pMsg));
        }
        if(props.countryLocale === 'en-GB') {
            var strictlyContent = document.getElementById('label_strictly');
            strictlyContent.textContent += ' (Always Active)';
        }
        let AcceptAllTxt = document.getElementById('accept-all-text').innerText;
        let RejectAllTxt = document.getElementById('reject-all-text').innerText;
        if(props.countryLocale === 'de-AT'){
            AcceptAllTxt = 'Alle Cookies akzeptieren';
            RejectAllTxt = 'Alles ablehnen';
        }
        if(props.countryLocale === 'nl-BE'){
            AcceptAllTxt = 'Alle cookies aanvaarden';
            RejectAllTxt = 'Alle cookies weigeren';
        }
        if(props.countryLocale === 'fr-BE'){
            AcceptAllTxt = 'Accepter tous les cookies';
            RejectAllTxt = 'Refuser tous les cookies';
        }
        if(props.countryLocale === 'pt-BR'){
            AcceptAllTxt = 'Aceitar todos cookies';
            RejectAllTxt = 'Aceitar apenas cookies necessários';
        }
        if(props.countryLocale === 'cs-CZ'){
            AcceptAllTxt = 'Přijmout všechny soubory cookie';
            RejectAllTxt = 'Odmítnout všechny soubory cookie';
        }
        if(props.countryLocale === 'it-IT'){
            AcceptAllTxt = 'Accetta Tutti';
            RejectAllTxt = 'Rifiuta Tutti';
        }
        if(props.countryLocale === 'nl-NL'){
            AcceptAllTxt = 'Alle cookies accepteren';
            RejectAllTxt = 'Alle cookies weigeren';
        }
        if(props.countryLocale === 'pl-PL'){
            AcceptAllTxt = 'Akceptuj wszystkie pliki cookie';
            RejectAllTxt = 'Odrzuć wszystkie pliki cookie';
        }
        if(props.countryLocale === 'sk-SK'){
            AcceptAllTxt = 'Prijať všetky súbory cookie';
            RejectAllTxt = 'Odmietnuť všetky súbory cookie';
        }
        if(props.countryLocale === 'es-ES'){
            AcceptAllTxt = 'Aceptar todas las cookies';
            RejectAllTxt = 'Rechazar todas las cookies';
        }
        AcceptAllTxt = (AcceptAllTxt === 'N/A' || AcceptAllTxt === 'empty') ? "Accept All Cookies" : AcceptAllTxt;
        RejectAllTxt = (RejectAllTxt === 'N/A' || RejectAllTxt === 'empty') ? "Reject All Cookies" : RejectAllTxt;

        const savePreference = document.getElementById('save_preference');
        const rejectButton = document.createElement('BUTTON');
        rejectButton.textContent = RejectAllTxt;
        rejectButton.classList.add('save');
        rejectButton.setAttribute('id', 'reject-new');
        savePreference.before(savePreference.appendChild(rejectButton));

        const acceptButton = document.createElement('BUTTON');
        acceptButton.textContent = AcceptAllTxt;
        acceptButton.classList.add('save');
        acceptButton.setAttribute('id', 'accept-new');
        savePreference.after(savePreference.appendChild(acceptButton));

        document.getElementById('accept-new').onclick = function acceptall() {
            window.Syrenis_AcceptAllCookies();
            window.Syrenis_CloseCookieOptionsModal();
            setBannerVisible(false);
            (new SessionService()).AcceptAllCookies()
        };
        document.getElementById('reject-new').onclick = function rejectall() {
            window.Syrenis_RejectAllCookies();
            window.Syrenis_CloseCookieOptionsModal();
            setBannerVisible(false);
            (new SessionService()).DeleteAllGACookies()
        };
        document.getElementById('save_preference').onclick = function rejectall() {
            setBannerVisible(false);
            // (new SessionService()).DeleteAllGACookies()
        }
    }

    // let iframeURL = "https://ids-use."+props.domain+"/Home/?accesskey=8A121E9D-D624-4D89-863A-34B6957A1FCF&domain=*.qa.lillyplay3.aws.lilly.com"
    return (
        <>
            {/* <iframe id="CassieIdentityIFrame" src={iframeURL} title={props.local} width="0" height="0" style={{border:'none'}} ></iframe> */}
            {/* <iframe id="CassieIdentityIFrame" src="https://ids-use.lillyplay.es/Home/?accesskey=8A121E9D-D624-4D89-863A-34B6957A1FCF&domain=*.lillyplay.es, *.lilly.com" width="0" style="border:none" height="0" > </iframe> */}
            {props.displayOnTop ?
                bannerVisible ?
                    <Grid className='cookieBanner'>
                        <Grid className="cookie-consent">
                            <Grid container className="cookie-consent-content-container wrapper">
                                {props.countryLocale === 'nl-BE' ?
                                    <>
                                        <Grid item xs={12} lg={8} className="cookie-consent-text-container">
                                            <p>
                                                We willen u hierbij inlichten dat we ons voor de goede werking van deze website verplicht zien gebruik te maken van "cookies". Deze "cookies" kunnen dus worden opgeslagen op uw toestel (PC, tablet, smartphone enz.). Door verder op deze website te blijven, geeft u aan dat u akkoord gaat met het gebruik van deze "cookies". Dit gebruik stelt ons in staat de inhoud van onze website zo precies mogelijk aan te passen en zorgt meteen voor een betere en makkelijkere surfervaring op onze site. Meer informatie hierover vindt u in de rubriek <a href="https://www.lillyprivacy.com/BE-nl/hcp" target="_self" class="lds-link" style={{ textDecoration: 'underline' , color: '#00749e' }}>Privacyverklaring</a>.
                                                {/* Wij maken gebruik van cookies om uw surfervaring te optimaliseren. Sommige cookies zijn noodzakelijk voor het functioneren van de website, andere cookies zijn optioneel. Door op Accepteren te klikken stemt u in met het gebruik van alle cookies en soortgelijke technologieën. U kunt de instellingen voor optionele cookies hier wijzigen indien u dit wenst. Raadpleeg onze <a style={{ textDecoration: 'underline' }} href="https://www.lillyprivacy.com/BE-nl/hcp" target="_blank" rel="noreferrer">privacyverklaring</a> voor meer informatie. */}
                                                <br /><br />
                                                <a href='#' onClick={openCookie} rel="noreferrer" style={{ textDecoration: 'underline' , color: '#00749e' }}>Cookie-instellingen</a>
                                                <br /><br />
                                                PP-MG-BE-0074
                                            </p>
                                        </Grid>
                                        <Grid item xs={12} lg={4} className="cookie-consent-btn-container">
                                            <button type="button" className='cookieBtn be' id="rejectAllBtnCB" onClick={() => rejectCookie()} data-cookie-consent="false">
                                                Alle cookies weigeren
                                            </button>
                                            <button type="button" className='cookieBtn be' id="acceptAllBtnCB" onClick={() => acceptCookie()} data-cookie-consent="true">
                                                Alle cookies aanvaarden
                                            </button>
                                        </Grid>
                                    </>
                                : null}

                                {props.countryLocale === 'fr-BE' ?
                                    <>
                                        <Grid item xs={12} lg={8} className="cookie-consent-text-container">
                                            <p>
                                                Nous vous informons que ce site Internet nécessite l’utilisation de « cookies » pour fonctionner correctement. Par conséquent, des « cookies » peuvent être stockés sur le matériel que vous utilisez (PC, tablette numérique, smartphone etc.). En continuant à naviguer sur ce site Internet, vous consentez donc à l’utilisation de ces « cookies ». Cette utilisation nous permet d’optimiser le contenu ainsi que la navigation sur notre site. Pour davantage d’information, vous pouvez consulter <a href={config?.lillyPrivacy  + "/BE-fr/hcp"} target="_self" class="lds-link" style={{ textDecoration: 'underline' , color: '#00749e'  }}>Déclaration de confidentialité</a>.
                                                {/* Nous utilisons des cookies pour améliorer votre expérience de navigation. Certains cookies sont nécessaires pour naviguer sur le site internet, les autres sont optionnels. En cliquant sur accepter, vous acceptez de recevoir tous les cookies et les technologies similaires. Vous pouvez changer les paramètres de cookies optionnels ici si vous le souhaitez. Pour plus d'informations, veuillez lire notre <a style={{ textDecoration: 'underline' }} href="https://www.lillyprivacy.com/fr-fr/hcp" target="_blank" rel="noreferrer">charte de confidentalité.</a>. */}
                                                <br /><br />
                                                <a href='#' onClick={openCookie} rel="noreferrer" style={{ textDecoration: 'underline' , color: '#00749e'  }}>Paramètres de cookies</a>
                                                <br /><br />
                                                PP-MG-BE-0074
                                            </p>
                                        </Grid>
                                        <Grid item xs={12} lg={4} className="cookie-consent-btn-container">
                                            <button type="button" className='cookieBtn be' id="rejectAllBtnCB" onClick={() => rejectCookie()} data-cookie-consent="false">
                                                Refuser tous les cookies
                                            </button>
                                            <button type="button" className='cookieBtn be' id="acceptAllBtnCB" onClick={() => acceptCookie()} data-cookie-consent="true">
                                                Accepter tous les cookies
                                            </button>
                                        </Grid>
                                    </>
                                : null}
                                
                                {props.countryLocale === 'de-CH' ?
                                    <>
                                        <Grid item xs={12} lg={7} className="cookie-consent-text-container">
                                            <p>
                                                Wir verwenden Cookies, um Ihnen die bestmögliche Erfahrung auf unseren Webseiten zu bieten. Einige Cookies sind für die Funktion der Webseiten notwendig, andere sind optional. Indem Sie auf „Akzeptieren“ klicken, stimmen Sie der Verwendung aller Cookies zu. Sie können die Einstellungen der optionalen Cookies bei Bedarf in den "Cookie-Einstellungen" ändern. Weitere Informationen finden Sie in unserer <a style={{ textDecoration: 'underline' , color: '#00749e'  }} href={config?.lillyPrivacy  + "/ch-de/hcp"} target="_blank" rel="noreferrer">Datenschutzerklärung.</a>
                                                <br /><br />
                                                <a href='#' onClick={openCookie} rel="noreferrer" style={{ textDecoration: 'underline'  , color: '#00749e' }}>Cookie-Einstellungen</a>
                                            </p>
                                        </Grid>
                                        <Grid item xs={12} lg={5} className="cookie-consent-btn-container">
                                            <button type="button" className='cookieBtn' id="rejectAllBtnCB" onClick={() => rejectCookie()} data-cookie-consent="false">
                                                Alle Cookies ablehnen
                                            </button>
                                            <button type="button" className='cookieBtn' id="acceptAllBtnCB" onClick={() => acceptCookie()} data-cookie-consent="true">
                                                Alle Cookies akzeptieren
                                            </button>
                                        </Grid>
                                    </>
                                : null}

                                {props.countryLocale === 'fr-CH' ?
                                    <>
                                        <Grid item xs={12} lg={7} className="cookie-consent-text-container">
                                            <p>
                                                Nous utilisons des cookies pour vous offrir la meilleure expérience possible sur nos sites internet. Certains cookies sont nécessaires au fonctionnement de nos sites internet, d'autres sont facultatifs. En cliquant sur "Accepter", vous acceptez l'utilisation de tous les cookies. Vous pouvez modifier les paramètres des cookies facultatifs dans "Configuration des Cookies" si nécessaire. Pour plus d'informations, veuillez vous référer à notre <a style={{ textDecoration: 'underline'  , color: '#00749e'  }} href={config?.lillyPrivacy  + "/ch-fr/hcp"} target="_blank" rel="noreferrer">déclaration de confidentialité</a>.
                                                <br /><br />
                                                <a href='#' onClick={openCookie} rel="noreferrer" style={{ textDecoration: 'underline'  , color: '#00749e' }}>Paramètres des cookies</a>
                                            </p>
                                        </Grid>
                                        <Grid item xs={12} lg={5} className="cookie-consent-btn-container">
                                            <button type="button" className='cookieBtn' id="rejectAllBtnCB" onClick={() => rejectCookie()} data-cookie-consent="false">
                                                Refuser tous les cookies
                                            </button>
                                            <button type="button" className='cookieBtn' id="acceptAllBtnCB" onClick={() => acceptCookie()} data-cookie-consent="true">
                                                Accepter tous les cookies
                                            </button>
                                        </Grid>
                                    </>
                                : null}

                                {/* {props.countryLocale === 'en-GB' ?
                                    <>
                                        <Grid item xs={12} lg={7} className="cookie-consent-text-container">
                                            <p>
                                                We use cookies to enhance your browsing experience. Some cookies are necessary to run the website, others are optional. By clicking accept, you agree to receive all cookies and similar technologies. For more information, please see our <a style={{ textDecoration: 'underline'  , color: '#00749e' }} href='https://www.lillyprivacy.com/UK-en/hcp' target='_blank' rel="noreferrer">Privacy Statement</a>
                                            </p>
                                        </Grid>
                                        <Grid item xs={12} lg={5} className="cookie-consent-btn-container">
                                            <button type="button" className='cookieBtn' id="cookieSettingBtnCB" onClick={() => openCookie()}>
                                                Cookie Settings
                                            </button>
                                            <button type="button" className='cookieBtn' id="acceptAllBtnCB" onClick={() => acceptCookie()} data-cookie-consent="true">
                                                Accept All Cookies
                                            </button>
                                        </Grid>
                                    </>
                                : null} */}
                            </Grid>
                        </Grid>
                    </Grid>
                    : null
                : null}
        </>
    )
}

export default CookieBanner