import React, { forwardRef } from 'react';
import { Grid } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { SimulationService } from './Services/SimulationService';
import { useDispatch } from 'react-redux';
import countryListJson from '../CountryPassport/CountryPassport.json';
import { OFFLINE_COUNTRIES } from '../../constants/country';

const Simulation = forwardRef((props, ref) => {
  const [countries, SetCountries] = React.useState([]);
  const [specialties, SetSpecialties] = React.useState([]);
  const [profession, SetProfession] = React.useState([]);
  const [locales, SetLocales] = React.useState([]);
  const [country, SetCountry] = React.useState("");
  const [specialty, SetSpecialty] = React.useState("");
  const [professionSelected, SetProfessionSelected] = React.useState("");
  const [locale, SetLocale] = React.useState("");
  const [jsonObj, SetjsonObj] = React.useState(countryListJson);
  const dispatch = useDispatch();
  const GetSpecialtiesForCountry = (affiliateId) => {
    let simulationService = new SimulationService();
    simulationService.GetCountrySpecialty(affiliateId).then((data) => {
      if (Array.isArray(data)) {
        let list = data
        if (list.length > 0) {
          SetSpecialties(list);
        }
      }

    }).catch((err) => {

    });
  }
  const GetCountryProfession = (affiliateId) => {
    let simulationService = new SimulationService();
    simulationService.GetCountryProfession(affiliateId).then((data) => {
      if (Array.isArray(data)) {
        let List = data
        if (List.length > 0) {
          SetProfession(List);
        }
      }

    }).catch((err) => {

    });

  }
  React.useEffect(() => {
    /**Function will fetch all the roles that could be associated with user */
    let simulationService = new SimulationService();
    simulationService.GetCountries().then((data) => {
      if (Array.isArray(data)) {
        let countryList = data.map(v => v.country_name);
        if (countryList.length > 0) {
          data.sort(function (a, b) {
            return a.country_name < b.country_name ? -1 : a.country_name > b.country_name ? 1 : 0;
          });
          const filteredData = data.filter(v => !OFFLINE_COUNTRIES.includes(v.country_name));
          SetCountries(filteredData);
        }
      }

    }).catch((err) => {

    });

    simulationService.GetLocales().then((data) => {
      if (Array.isArray(data)) {
        let list = data.map(v => v.locale_info);
        if (list.length > 0) {
          SetLocales(list);
        }
      }

    }).catch((err) => {

    });
  }, []);
  const handleSubmit = () => {
    if (country === '')
      alert("Country: Country cannot be left empty");
    else if (locale === '')
      alert("Language: Language cannot be left empty");
    else {
      localStorage.setItem('lang', locale);
      localStorage.setItem('country', country);
      let simulationService = new SimulationService();
      let professionSend = professionSelected;
      let speciialSend = specialty;

      for(let  i = 0 ;i <specialties.length ; i++){
        if(specialties[i].specialty_name == specialty){
          speciialSend = specialties[i].specialty_key
        }
      }
      for(let  i = 0 ;i < profession.length ; i++){
        if(profession[i].profession_name == professionSelected){
          professionSend = profession[i].profession_name
        }
      }
      simulationService.StartSimulation(country, locale, professionSend, speciialSend, dispatch).then((data) => {

      }).catch((err) => {

      });
    }
  }

  function getCode(country_name) {
    return country_name != '' ? jsonObj.find(o => o.country_code === country_name) : '';
  }

  if(!countries.length) {
    return <></>
  }

  return (
      <Grid className='custom-modalstyle paper modalstyle' ref={ref}>
        <Grid className="modal-title">
          Simulation
          <button className='closeModal' onClick={() => props.closePopUp(false)}></button>
        </Grid>

        <Grid className="modal-body">
          <div className='field'>
            <FormControl variant="outlined" className='formControl' size="small">
              <InputLabel >Select Country</InputLabel>
              <Select label="Country" name="Country" value={country} onChange={(event, item) => {
                GetSpecialtiesForCountry(item.props.value);
                GetCountryProfession(item.props.value);
                SetCountry(item.props.value)
              }}>
                {countries.length > 0 && countries.map((cntry, i) => (
                  <MenuItem key={i} value={cntry.country_code}>{cntry.country_name}</MenuItem>
                ))}
              </Select>

            </FormControl>
          </div>
          <div className='field'>
            <FormControl variant="outlined" className='formControl' size="small">
              <InputLabel >Select Language</InputLabel>
              {country === 'CH' ?
                <Select label="Language" name="Language" value={locale} onChange={(event, item) => {
                  SetLocale(item.props.value)
                }}>
                  <MenuItem value=""><em>None</em></MenuItem>
                  <MenuItem value={"de-CH"}>{"German"}</MenuItem>
                  <MenuItem value={"fr-CH"}>{"French"}</MenuItem>
                </Select>
                : country === 'BE' ?
                  <Select label="Language" name="Language" value={locale} onChange={(event, item) => {
                    SetLocale(item.props.value)
                  }}>
                    <MenuItem value=""><em>None</em></MenuItem>
                    <MenuItem value={"nl-BE"}>{"Dutch"}</MenuItem>
                    <MenuItem value={"fr-BE"}>{"French"}</MenuItem>
                  </Select>
                  :
                  <Select label="Language" name="Language" value={locale} onChange={(event, item) => {
                    SetLocale(item.props.value)
                  }}>
                    <MenuItem value=""><em>None</em></MenuItem>
                    <MenuItem value={getCode(country).locale}>{getCode(country).language}</MenuItem>

                  </Select>
              }
            </FormControl>
          </div>
          <div className='field'>
            <FormControl variant="outlined" className='formControl' size="small">
              <InputLabel >Select Profession</InputLabel>
              <Select label="Profession" name="Profession" value={professionSelected} onChange={(event, item) => {
                SetProfessionSelected(item.props.value)
              }}>
                <MenuItem value=""><em>None</em></MenuItem>
                {profession.length > 0 && profession.map((cntry, i) => (
                  <MenuItem key={i} value={cntry.profession_name}>{cntry.profession_name}</MenuItem>
                ))}
              </Select>

            </FormControl>
          </div>
          <div className='field'>
            <FormControl variant="outlined" className='formControl' size="small">
              <InputLabel >Select Specialty</InputLabel>
              <Select label="Specialty" name="Specialty" value={specialty} onChange={(event, item) => {
                SetSpecialty(item.props.value)
              }}>
                <MenuItem value=""><em>None</em></MenuItem>
                {specialties.length > 0 && specialties.map((cntry, i) => (
                  <MenuItem key={i} value={cntry.specialty_name}>{cntry.specialty_name}</MenuItem>
                ))}
              </Select>

            </FormControl>
          </div>

        </Grid>
        <Grid className="modal1FoBtn modal-footer">
          <button type="button" className="modalBtn" onClick={() => { props.closePopUp(false); }}>Cancel</button>
          <button type="button" className="modalBtn" onClick={() => {
            handleSubmit();
          }}>OK</button>
        </Grid>
      </Grid>);
});
export default Simulation;






