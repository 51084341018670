import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Breadcrumb from '../../../layout/breadcrumb';
import { EventService } from '../Services/event-service';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import '../event.css';
import { useNavigate } from "react-router-dom";

// Define Prototype
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// Define Data Table Header
const headCells = [
  { id: 'event_id', numeric: false, disablePadding: false, label: 'Event Id', sorting: true },
  { id: 'event_desc', numeric: false, disablePadding: false, label: 'Description', sorting: true },
  { id: 'livestart', numeric: false, disablePadding: false, label: 'Event Start Time', sorting: true },
  { id: 'action', numeric: true, disablePadding: false, label: 'Action', sorting: false },
];

// To Create Data Table Rows
function createData(event_id, event_desc, event_start_time, action, livestart) {
  return { event_id, event_desc, event_start_time, action, livestart };
}

// Functions for Apply Sorting of Data Table
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={!headCell.sorting}
              className={((!headCell.sorting) ? "nonClickable MuiButtonBase-root MuiTableSortLabel-root" : "MuiButtonBase-root MuiTableSortLabel-root")}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className='visuallyHidden'>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// Live Event Management Component 
export default function EnhancedTable(props) {
  const navigate = useNavigate();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, SetRows] = React.useState([]);
  const [eventData, setEventData] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [fetchDataStatus, setFetchDataStatus] = React.useState(false);
  const [pagecount, setPageCount] = React.useState(0);
  const [currentpage, setCurrentPage] = React.useState(0);
  let eventServices = new EventService();

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const BreadcrumbData = [
    { value: props.translations['Home'] ? props.translations['Home'] : 'Home', path: '/home' },
    { value: 'Add ON24 Live Events', path: '/live-event' }
  ];
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // eslint-disable-next-line no-unused-vars
  const dateSorted = (value) => {
    if (Array.isArray(value) && value?.length > 0) {
      const newArray = value.sort(function (first, second) {
        let a = new Date(first.livestart);
        let b = new Date(second.livestart);

        if (a > b) {
          return 1;
        } else if (a < b) {
          return -1;
        } else {
          return 0;
        }
      });
      return newArray
    } else {
      return value;
    }
  }
  // To Get & Set All On24 Live Events Data
  function getSetEvents(params = {}) {
    let eventList = [];
    eventServices.GetEventsByQuery('').then((data) => {
      let events = data.data;
      if (events.length > 0) {
        for (let i = 0; i < events.length; i++) {
          eventList.push(events[i].event_id);
        }
      }
    })
    eventServices.GetOn24Events(params, 0).then((data) => {
      let pagecount = data.data.pagecount;
      let currentpage=data.data.currentpage;
      setPageCount(pagecount);
      setCurrentPage(currentpage);
      getAllEvents(params, currentpage,pagecount);
    }).catch((err) =>{})
    async function getAllEvents(params, currentpage, pagecount) {
      let list = [];
      let fulleventData = [];
      while (currentpage < pagecount) {
        await eventServices.GetOn24Events(params, currentpage).then((data) => {
          let events = data.data.events;
          if (events.length > 0) {
            for (let i = 0; i < events.length; i++) {
              if (!eventList.includes((events[i].eventid).toString())) {
                let event = events[i];
                let event_start_date = (new Date(event.livestart)).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
                let value = createData(event.eventid, (event.description).trim(), event_start_date, event, event.livestart);
                list.push(value);
                fulleventData.push(event);
              }
            }
          }
        }).catch((err) => {})
        currentpage = currentpage + 1;
      }
      setFetchDataStatus(true); // TODO: already mentioned on 194 remove
      SetRows(list);
      setEventData(fulleventData);
    }
  }
  React.useEffect(() => {
    getSetEvents();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle Search
  const [open, setOpen] = React.useState(false);
  const [filteredEvents, setFilteredEvents] = React.useState([]);
  const [newEventTerm, setNewEventTerm] = React.useState('');
  const searchItem = event => {
    const term = searchTerm;
    setSearchTerm(term);
    setSearchedList(term);
  };
  const handleSearchTerm = (e, value) => {
    if (e.keyCode === 13) {
      let term = e.target.value;
      if (value != null) {
        term = value.title;
      }
      setSearchTerm(term);
      setSearchedList(term);
    }
  }
  React.useEffect(() => {
    //setSearchedList(searchTerm);
  }, [searchTerm]);
  function setSearchedList(term) {
    let list = [];
    let filteredEvents = []
    filteredEvents = eventData.filter((item) => {
      return item.description.includes(term);
    });
    if (filteredEvents.length == 0) {
      for(let i = 0 ; i<eventData.length; i++){
        if(eventData[i].eventid == term){
          filteredEvents.push(eventData[i])
        }
      }
    }
    if (filteredEvents.length > 0) {
      for (let i = 0; i < filteredEvents.length; i++) {
        let event = filteredEvents[i];
        let event_start_date = (new Date(event.livestart)).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
        let value = createData(event.eventid, (event.description).trim(), event_start_date, event, event.livestart);
        list.push(value);
      }
    }
    
    SetRows(list);
    setPage(0);
  }

  return (
    <>
      <Breadcrumb translations={props.translations} setFilter={props.setFilter} BreadcrumbData={BreadcrumbData} />
      <div className='content-mgmt'>
        <div className="main-wrp">
          <Grid className="mgmt_header">
            <Grid container className="row">
              <Grid item lg={10} xs={12}>
                <label className="label-hd">
                  Add ON24 Live Events <span className='user'> {rows.length} events</span>
                </label>
              </Grid>
              <Grid item lg={2} xs={12}>
                <div className='search_bar searchBx'>
                  <Autocomplete
                    open={open}
                    onOpen={() => { if (searchTerm) { setOpen(true); } }}
                    onClose={() => setOpen(false)}
                    inputValue={searchTerm}
                    onInputChange={(e, value) => {
                      setSearchTerm(value);
                      if (value == null) {
                        setFilteredEvents([]);
                      } else {
                        let newEventsData = eventData.filter(function (item) {
                          let username = item.description.toLowerCase();
                          return username.startsWith(value.toLowerCase());
                        });
                        setFilteredEvents(newEventsData);

                        const found = eventData.some(el => el.description.toLowerCase() === value.toLowerCase());
                        if (!found) { setNewEventTerm(value); } else { setNewEventTerm(''); }
                      }
                      if (!value) { setOpen(false); }
                    }}
                    value={searchTerm}
                    onChange={handleSearchTerm}
                    onKeyDown={handleSearchTerm}
                    freeSolo
                    options={filteredEvents.length > 0 || newEventTerm !== '' ? filteredEvents : eventData}
                    getOptionLabel={(option) => typeof option === 'string' ? option : option.description}
                    renderInput={(params) =>
                      <TextField {...params} placeholder="Search On24 Event" name="search_name" size="small"
                        InputLabelProps={{ shrink: false }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                          endAdornment: <InputAdornment onClick={() => searchItem()} position="end"><IconButton className="mp0"><SearchIcon /></IconButton></InputAdornment>
                        }}
                      />}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <TableContainer>
            <Table className='table'>
              <EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={rows.length} />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    return (
                      <TableRow hover
                        onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.event_id}
                        selected={isItemSelected}
                      >
                        <TableCell align="left">{row.event_id}</TableCell>
                        <TableCell align="left">{row.event_desc}</TableCell>
                        <TableCell align="left">{row.event_start_time}</TableCell>
                        <TableCell align="center">
                          <img onClick={() => navigate('/event-edit/' + row.event_id, { state: { action: 'live', data: row.action } })} className='actnBtn' src={require('../../../public/img/GreyEdit@3x.png')} alt='Edit' />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    {rows.length <= 0 ?
                      <TableCell align="center" colSpan={8}>{fetchDataStatus ? 'No Records Found.' : 'Loading...'}</TableCell> :
                      <TableCell colSpan={8} />
                    }
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  )
}
