/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Grid from '@material-ui/core/Grid';
import RightArrowIcon from '../../../public/img/right-arrow.svg';
import LeftArrowIcon from '../../../public/img/left-arrow.svg';
import { useLocation } from 'react-router-dom';

const BannerSlider = (props) => {
    const sliderRef = useRef();
    const location = useLocation();

    /** Carousel Next Arrow  */
    const NextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <Grid onClick={onClick}>
                <img src={RightArrowIcon}
                    className={className} style={{
                        ...style, background: '#FFFFFF',
                        border: '1px solid #D52B1D',
                        color: '#D52B1D',
                        borderRadius: '50%',
                        fontSize: '36px',
                        marginRight: '-1%',
                        padding: '4px 4px 4px 3px',
                        height: '18px',
                    }} alt="" />
            </Grid>
        );
    }
    /** Carousel Previous Arrow  */
    const PrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <Grid onClick={onClick}>
                <img src={LeftArrowIcon}
                    className={className} style={{
                        ...style, background: '#FFFFFF',
                        border: '1px solid #D52B1D',
                        color: '#D52B1D',
                        borderRadius: '50%',
                        fontSize: '36px',
                        marginLeft: '-1%',
                        padding: '4px 4px 4px 3px',
                        height: '18px',
                    }} alt="" />
            </Grid>
        );
    }
    /** Carousel settings  */
    const settingsBanner = {
        dots: true,
        infinite: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    infinite: false,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    infinite: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    infinite: false,
                }
            }
        ]
    };
    React.useEffect(() => {
        sliderRef.current.slickPrev();
    }, [props.filterId])
    React.useEffect(() => {
        sliderRef.current.slickPrev();
    }, [location]);
    const GetURLWWWReplace =(url)=> {
        let codeHost = window.location.host ;
        let _url = new URL(url);
        let wwwCodeHostReplace = codeHost.replace('www.', '');
        let www_urlReplace = _url.host.replace('www.', '');
        if(wwwCodeHostReplace.toLocaleLowerCase() === www_urlReplace.toLocaleLowerCase()){
            let redirectURI =window.location.protocol+ '//'+  window.location.host + _url.pathname;
            return redirectURI
        }
        else{
            return url;
        }
        // if(window.location.host)
    }
    const [isBool, SetIsBool] = React.useState(true);
    React.useEffect(()=> {
        if(document.getElementsByClassName("slick-prev").length) {
            if(isBool) {
                setTimeout(() => {
                    document.getElementsByClassName("slick-prev")[0]?.click();
                    SetIsBool(false);
                }, 1000)
            }
        }
    }, [props.dictionary,props.bannerDictionaryOrder]);
    return (
        <Grid className=''>
            <Slider ref={sliderRef} {...settingsBanner}>
                {props.bannercheck && props.dictionary && Object.keys(props.dictionary).length>0 && props.bannerDictionaryOrder
                && Object.keys(props.bannerDictionaryOrder).length>0?
                 props.bannerDictionaryOrder.map((key) => {
                    if(props.dictionary && props.dictionary[key] && typeof props.dictionary[key]['displayfile'] !== "undefined" && props.dictionary[key]['displayfile'].length !== 0) {
                        if(props.dictionary[key]['url']){
                            if(props.dictionary[key]['target']){
                                return (<Grid key={key}>
                                    {props.dictionary[key]['url'].length !== 0 && 
                                        <a rel="noreferrer" target="_blank" href={GetURLWWWReplace(props.dictionary[key]['url'])}>
                                            {props.dictionary[key]['displayfile'].length !== 0 ?
                                                <img src={props.dictionary[key]['displayfile']} className='simg' alt='img' />:""
                                            }
                                        </a>
                                    }
                                </Grid>)
                            }else{ 
                                return (<Grid key={key}>
                                    {props.dictionary[key]['url'].length !== 0 && 
                                        <a  href={props.dictionary[key]['url']}>
                                            {props.dictionary[key]['displayfile'].length !== 0 ?
                                                <img src={props.dictionary[key]['displayfile']} className='simg' alt='img' />:""
                                            }
                                        </a>
                                    }
                                </Grid>)
                            }
                        } else {
                            return (<Grid key={key}>
                                {props.dictionary[key]['displayfile'].length !== 0 ?
                                    <img src={props.dictionary[key]['displayfile']} className='simg' alt='img' />:""
                                }
                            </Grid>)
                        }
                    }
                    return true;
                }) : ('')}
            </Slider>
        </Grid>
    );
}

export default BannerSlider;