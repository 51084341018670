import React from 'react';
import Grid from '@material-ui/core/Grid';
import videoplayIcon from '../../../../public/img/icons/video.svg';
import podcastIcon from '../../../../public/img/icons/podcast.svg';
import playlistIcon from '../../../../public/img/icons/playlist.svg';
import eventIcon from '../../../../public/img/icons/live.svg';
import classes from './styles.module.css';

const ThumbnailIcons = [
  { type: 'video', icon: videoplayIcon, className: 'video' },
  { type: 'playlist', icon: playlistIcon, className: 'playlist' },
  { type: 'podcast', icon: podcastIcon, className: 'podcast' },
  { type: 'event', icon: eventIcon, className: 'event' },
];

const Icon = ({ contentType }) => {
  return (
    <Grid
      item
      xs={3}
      className={classes['icon-wrapper']}
      container
      justifyContent='center'
      alignItems='flex-end'
    >
      <Grid
        item
        xs={8}
        container
        justifyContent='center'
        alignItems='flex-end'
        direction='row'
        className={classes['icon-innder-wrapper']}
      >
        {ThumbnailIcons.map(({ type, icon, className }) =>
          contentType === type ? (
            <img
              key={type}
              src={icon}
              className={`${classes['icon']} ${className}`}
              alt='icon'
            />
          ) : null
        )}
      </Grid>
    </Grid>
  );
};
export default Icon;
