import React, { useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import videoThumb from '../../../public/img/video.png';
import { HomeService } from '../../home/Service/homeService';
import { isValidURL } from '../../../helpers/urlHelpers';
import classes from './styles.module.css';
import Icon from './Icon';
import NewLabel from './NewLabel';

const Thumbnail = ({ content, onClick, showNewLabel, watchedContent }) => {
  const { user_thumbnail, thumbnail, content_type, duration } = content;
  const [thumbnailImage, SetThumbnailImage] = React.useState('');

  const durationText = useMemo(() => {
    if (!parseInt(duration)) {
      return '';
    }
    return duration / 3600 >= 1
      ? new Date(duration * 1000).toISOString().slice(11, 19)
      : new Date(duration * 1000).toISOString().slice(14, 19);
  }, [duration]);

  React.useEffect(() => {
    let content_thumbnail = user_thumbnail;
    if (content_type === 'playlist' && !isValidURL(thumbnail)) {
      content_thumbnail = thumbnail;
    }

    const fetchThumbnail = async () => {
      let service = new HomeService();
      const fetchedThumbnail =
        content_thumbnail?.length > 0
          ? await service.GetImageFromURL(content_thumbnail)
          : thumbnail;

      if (fetchedThumbnail) {
        SetThumbnailImage(fetchedThumbnail);
      }
    };

    fetchThumbnail();
  }, [user_thumbnail, thumbnail, content_type]);

  return (
    <Grid container className={classes['thumbnail-wrapper']} onClick={onClick}>
      {showNewLabel && <NewLabel content={content} watchedContent={watchedContent} />}
      <Icon contentType={content_type} />
      {thumbnailImage && (
        <img
          src={thumbnailImage.length > 0 ? thumbnailImage : videoThumb}
          className={classes['thumbnail-img']}
          alt='img'
        />
      )}
      {durationText && <span className={classes['time']}>{durationText}</span>}
    </Grid>
  );
};
export default Thumbnail;
