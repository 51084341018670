// return (await axios.put(dataUrl.data, renderResult)).data;
import axios from 'axios';

class TAModel {
    constructor(_ta, _cat1, _cat2) {
        this.ta = _ta;
        this.cat1 = _cat1;
        this.cat2 = _cat2
    }
}
class ContentService {

    constructor(jwtToken) {
        this.baseUrl = process.env.REACT_APP_BASE_URL;
        this.key = '';
        this.auth_Header = {
            headers: {
                'Content-Type': 'application/json',
                'authorizationtoken': jwtToken ? jwtToken : localStorage.getItem('jwtToken')
            }
        };
    }
    async GetImage(key) {
        try {
            if (key != null && key.length > 0) {
                let data = await axios.post(this.baseUrl + '/content/api/aws/GetPresignedURIGet', { key: key }
                    , this.auth_Header);
                return (await axios.get(data.data)).data//data.data.binarySring;

            }
            else {
                return null;
            }

        } catch (err) {
            return null;
        }

    }
    
    async readAndUploadFileToS3(file, fileKey = '') {
        try {
            // Read the file as data URL
            const fileData = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(new Error('File reading failed: ' + error));
                reader.readAsDataURL(file);
            });

            // Get presigned URL for uploading the file
            const dataUrlResponse = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/content/api/aws/GetPresignedUrlPutObject`,
                { key: fileKey, body: fileData },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'authorizationtoken': localStorage.getItem('jwtToken')
                    }
                }
            );

            const presignedUrl = dataUrlResponse.data;

            // Upload the file using the presigned URL
            const uploadFileResponse = await axios.put(presignedUrl, fileData);
            return uploadFileResponse.data;

        } catch (error) {
            console.error('Error during file upload process:', error.message || error);
            throw error;
        }
    }

    async GetSearchVideosByQuery(query) {
        let response = await axios.post(this.baseUrl + '/content/api/video/GetSearchListVideosRecent/' + query, { url: 'uri' }, this.auth_Header);
        let list = [];
        if (Array.isArray(response.data)) {
            for (let i = 0; i < response.data.length; i++) {
                if (response.data[i].status != 'deleted') {
                    list.push(response.data[i]);
                }
            }
        }

        return list;
    }
    async GetValidURLCheck(uri) {
        let response = await axios.post(this.baseUrl + '/content/api/video/GetURLstatus', { url: uri }, this.auth_Header);
        return response.data;
    }
    async GetContent(id) {
        let response = await axios.post(this.baseUrl + '/content/api/video/GetVideosByQuery?id=' + id, {}, this.auth_Header);
        let list = [];
        if (Array.isArray(response.data)) {
            for (let i = 0; i < response.data.length; i++) {
                if (response.data[i].status != 'deleted') {
                    list.push(response.data[i]);
                }
            }
        }

        return list;
    }
    async UpdateContent(model) {
        let response = await axios.post(this.baseUrl + '/content/api/video/UpdateContent', model, this.auth_Header);
        return response.data;
    }
    async GetVideoByCountry(country) {
        let response = await axios.post(this.baseUrl + '/content/api/video/GetVideoByCountry', country, this.auth_Header);
        let list = [];
        if (Array.isArray(response.data)) {
            for (let i = 0; i < response.data.length; i++) {
                if (response.data[i].status != 'deleted') {
                    list.push(response.data[i]);
                }
            }
        }

        return { data: list };
    }
    async GetVideoByCountryCountData(country) {
        let response = await axios.post(this.baseUrl + '/content/api/video/GetVideoByCountryCountData', country, this.auth_Header);
        let list = [];
        if (Array.isArray(response.data)) {
            for (let i = 0; i < response.data.length; i++) {
                if (response.data[i].status != 'deleted') {
                    list.push(response.data[i]);
                }
            }
        }

        return { data: list };
    }
    async CheckIfContentStatusHasChanged(previousValue, currentValue, affiliateCodes, model) {
        for (let i = 0; i < affiliateCodes.length; i++) {
            if (previousValue && currentValue && previousValue[affiliateCodes[i]] && currentValue[affiliateCodes[i]]) {
                if (previousValue[affiliateCodes[i]].status != currentValue[affiliateCodes[i]].status) {
                    await this.SendEmailToOwnerAndSecondOwner(model)
                    return true;
                }
            }
        }
    }
    async SendEmailToOwnerAndSecondOwner(model) {
        let approval_status = (model.status == "Pending") ? "pending" : "approved";
        let status = model.status;
        let obj = {
            'mail_type': 'content_approved',
            'to': model.ownerSecondOwnerMail,
            'video_name': model.title,
            'approval_status': approval_status,
            'status': model.status,
            'country': model.country,
            'created_at': model.created,
            'video_link': model.link,
            'subject': 'Content ' + status + ' : ' + model.title
        }
        return axios.post(process.env.REACT_APP_BASE_URL + '/content/api/aws/SendEmail', obj, this.auth_Header);
    };
    async SendEmailToOwnerSecondaryOwner(model) {
        let approval_status = model.status.toLowerCase();
        let obj = {
            'mail_type': 'content_approved',
            'to': model.email_list,
            'video_name': model.title,
            'approval_status': approval_status,
            'status': model.status,
            'country': model.country,
            'created_at': model.created,
            'video_link': model.link,
            'subject': 'Content ' + model.status + ' : ' + model.title
        }
        return axios.post(process.env.REACT_APP_BASE_URL + '/content/api/aws/SendEmail', obj, this.auth_Header);
    }
    async SendEmail(title, country, created, link, publisherEmail) {
        let obj = {
            'mail_type': 'content_uploaded',
            'to': publisherEmail,
            'video_name': title,
            'country': country,
            'created_at': created,
            'video_link': link,
            'subject': 'Content Uploaded : ' + title
        }
        return axios.post(process.env.REACT_APP_BASE_URL + '/content/api/aws/SendEmail', obj, this.auth_Header);
    };

    async GetAllContent(param) {
        if (param) {
            return await axios.post(process.env.REACT_APP_BASE_URL + '/content/api/video/GetVideosByQuery?' + param, {}, this.auth_Header);
        }
        else {
            return await axios.post(process.env.REACT_APP_BASE_URL + '/api/video/GetVideosByQuery?category_id=All', {}, this.auth_Header);
        }

    };
    async GetFilteredContent(param) {
        let q = '?'
        if (param.title) {
            q += 'title=' + param.title
        }
        if (param.country) {
            q += '&country=' + param.country
        }
        if (param.created_by) {
            q += '&created_by=' + param.created_by
        }
        if (param.start) {
            q += '&start=' + param.start
            q += '&end=50'
        }
        if (param) {
            return await axios.post(process.env.REACT_APP_BASE_URL + '/content/api/video/GetFilteredContent' + q, {}, this.auth_Header);
        }
        else {
            return await axios.post(process.env.REACT_APP_BASE_URL + '/api/video/GetFilteredContent?category_id=All', {}, this.auth_Header);
        }

    };

    async GetVideosByQueryCountData(param, created_by) {
        return await axios.post(process.env.REACT_APP_BASE_URL + '/content/api/video/GetVideosByQueryCountData', { created_by: created_by }, this.auth_Header);

    };
    async GetAllQuiz(obj) {
        if (obj) {
            return axios.post(this.baseUrl + `/management/api/quiz/GetAllQuiz`, obj, this.auth_Header);
        }
        else {
            return axios.post(this.baseUrl + `/management/api/quiz/GetAllQuiz`, {}, this.auth_Header);
        }
    };

    async GetAllQuizAttempts(obj) {
        return axios.post(this.baseUrl + `/management/api/user/GetAllQuizAttempts`, { module_name: "Content" }, this.auth_Header);
    };

    async GetWatchReport(obj) {
        return axios.post(this.baseUrl + `/content/api/report/GetWatchReport`, obj, this.auth_Header);
    };

    async GetWatchReportCount(obj) {
        return axios.post(this.baseUrl + `/content/api/report/GetWatchReportCount`, obj, this.auth_Header);
    };

    async GetContentReport(qs = '', body = {}) {
        return axios.post(this.baseUrl + `/content/api/video/GetContentReport` + qs, body, this.auth_Header);
    };
    async GetContentReports(qs = '', body = {}) {
        return axios.post(this.baseUrl + `/content/api/report/GetContentReport` + qs, body, this.auth_Header);
    };


    async GetAllSpecialities() {
        return await axios.post(this.baseUrl + '/management/api/country/GetSpecialtyByQuery', {}, this.auth_Header);
    }
    async GetAllDetails() {
        return axios.post(this.baseUrl + '/management/api/category/GetAllDetails', {}, this.auth_Header);
    };

    async GetAllSpecialitiesByAffiliateId(affiliate_id) {
        return await axios.post(this.baseUrl + '/management/api/country/GetSpecialtyByQuery?country_code=' + affiliate_id, {}, this.auth_Header);
    }

    async GetAllUsers(param) {
        if (param) {
            return axios.post(this.baseUrl + '/management/api/user/GetUsersIdByRoles', { "roles": ["2", "3", "4"] }, this.auth_Header);
        }
        else {
            return axios.post(this.baseUrl + '/management/api/user/GetUsersIdByRoles', { "roles": ["2", "3", "4"] }, this.auth_Header);
        }

    };
    async GetAllHubs() {
        return axios.post(this.baseUrl + '/management/api/user/GetHubsByQuery', {}, this.auth_Header);
    };
    async GetPublishersByCountriesCodes(param) {
        return axios.post(this.baseUrl + '/management/api/user/GetPublishersByCountriesCodes?' + param, {}, this.auth_Header);
    };

    async GetPublishersByCountry(param) {
        return axios.post(this.baseUrl + '/management/api/user/GetPublishersByCountry?' + param, {}, this.auth_Header);
    };

    async GetAllAffiliates() {
        return axios.post(this.baseUrl + '/management/api/user/GetAffiliateByQuery', {}, this.auth_Header);
    };
    async UploadFileToS3WithBufferAlteration(key, renderResult) {
        let _key = key;
        let _result = renderResult;
        let dataUrl = await axios.post(this.baseUrl + '/content/api/aws/GetPreSignedUrlBufferAlteration', { key: _key }, this.auth_Header);
        let url = dataUrl.data;
        await axios.put(url, _result)
        return dataUrl;
    }
    async CreateContent(model) {
        return axios.post(this.baseUrl + '/content/api/video/CreateContent', model, this.auth_Header);
    };

    async GetAllTherapeuticAreas() {
        return axios.post(this.baseUrl + '/management/api/category/GetTherapeuticAreaByQuery?del=false', {}, this.auth_Header);
    }
    async GetAllBrands() {
        return axios.post(this.baseUrl + '/management/api/category/GetCategory1ByQuery?del=false', {}, this.auth_Header);
    };
    async GetAllIndications() {
        return axios.post(this.baseUrl + '/management/api/category/GetCategory2ByQuery?del=false', {}, this.auth_Header);
    };
    async GetAllArea() {
        return axios.post(this.baseUrl + '/management/api/category/GetTherapeuticAreaByQuery?del=false', {}, this.auth_Header);
    };
    async GetVVPMDetails(body) {
        return axios.post(this.baseUrl + '/management/api/vvpm/GetVVPMInformaiton', body, this.auth_Header);
    };
    async GetVVPMDetailsSubType(body = {}) {
        return axios.post(this.baseUrl + '/management/api/vvpm/GetSubtypeVVPM', body, this.auth_Header);
    };
    async GetVVPMDetailsClassfication(body = {}) {
        return axios.post(this.baseUrl + '/management/api/vvpm/GetClassificationVVPM', body, this.auth_Header);
    };

    async GetSpeakersByQuery() {
        return axios.post(this.baseUrl + '/management/api/speaker/GetSpeakersByQueryPaginationCout', {}, this.auth_Header);
    };
    async GetCategory2fromIds(ids) {
        let data = await axios.post(this.baseUrl + '/management/api/category/GetCategory2ByTaIds',
            ids, this.auth_Header);
        return data.data;
    }
    async GetCategory1fromIds(ids) {
        let data = await axios.post(this.baseUrl + '/management/api/category/GetCategory1ByTaIds',
            ids, this.auth_Header);
        return data.data;
    }
    async GetTAfromTAIds(ids) {
        let data = await axios.post(this.baseUrl + '/management/api/category/GetTherapeuticAresByTaIds',
            ids, this.auth_Header);
        return data.data;
    }
    async GetCategoryByCountry() {
        return axios.post(this.baseUrl + '/management/api/country/GetCountryIndicationByQuery', {}, this.auth_Header);
    };

    async deleteContentById(id) {
        let data = axios.post(this.baseUrl + '/content/api/video/DeleteContentById/' + id, {}, this.auth_Header);
        axios.post(this.baseUrl + '/content/api/playlist/UpdatePlaylistStatus?content_id=' + id, {}, this.auth_Header);
        return data;
    };

    async GetSpeakersById(ids) {
        return axios.post(this.baseUrl + '/management/api/speaker/GetSpeakersById', ids, this.auth_Header);
    };

    async UploadFileToS3(key, renderResult) {
        let _key = key;
        let _result = renderResult;
        let dataUrl = await axios.post(this.baseUrl + '/content/api/aws/GetPresignedUrlPutObject', { key: _key }, this.auth_Header);
        await axios.put(dataUrl.data, renderResult);
        return dataUrl;
    }

    async GetS3FileURL(key) {
        try {
            if (key != null && key.length > 0) {
                let data = await axios.post(this.baseUrl + '/content/api/aws/GetPresignedURIGet', { key: key.trim() }, this.auth_Header);
                return (await data.data)//data.data.binarySring;
            } else {
                return null;
            }
        } catch (err) {
            return null;
        }
    }

    async GetS3File(key, fromscreen = '') {
        try {
            if (key != null && key.length > 0) {
                let data = await axios.post(this.baseUrl + '/content/api/aws/GetPresignedURIGet', { key: key.trim() }, this.auth_Header);
                
                if (fromscreen == '') {
                    return (await axios.get(data.data)).data
                } else {
                    return (await data.data)//data.data.binarySring;
                }
            } else {
                return null;
            }
        } catch (err) {
            return null;
        }
    }
    async GetAllLibrary() {
        return axios.post(this.baseUrl + '/management/api/library/GetAllLibrary', {}, this.auth_Header);
    };

    async UpdateS3ImagesErrorLogs(obj) {
        if (!localStorage.getItem('jwtToken')) {
            throw new Error('Access Token Not Available');
        }
        let response = await axios.post(process.env.REACT_APP_BASE_URL + '/content/api/aws/UpdateS3ImagesErrorLogs', obj, this.auth_Header);
        return response.data;
    }
    async UpdateContentStatus(obj) {
        return axios.post(this.baseUrl + '/content/api/video/UpdateContentStatus', obj, this.auth_Header);
    };
    async GetAllSpeakerByQuery() {
        return await axios.post(this.baseUrl + '/management/api/speaker/GetSpeakersByQueryPaginationCout', {}, this.auth_Header);
    };
    async FilterContent(data) {
        return await axios.post(this.baseUrl + '/content/api/video/GetFilteredContentByPayload', data, this.auth_Header);

    }
}

export { ContentService }