import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Singleselect from './Singleselect';
import { ContentService } from '../Service/content-service';

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: `${theme.palette.common.white} !important`,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        border: '1px solid #ffffff',
        padding: 0,
        margin: 0
    },
    [`& .${tooltipClasses.arrow}`]: {
        "&:before": {
            border: "1px solid #ffffff"
        },
        color: `#ffffff !important`,

    }
}));

const CustomSwitch = withStyles({
    switchBase: {
        color: '#f9f9f9',
        '&$checked': {
            color: '#FF6862!important',
        },
        '&$checked + $track': {
            backgroundColor: '#E02F2B!important',
        },
    },
    checked: {},
    track: {
        backgroundColor: '#F2F4F7!important'
    },
})(Switch);

const ContentQuiz = ({clearQuiz, ...props}) => {
    const [quizList, SetQuizList] = React.useState([]);
    const [selectedQuiz, SetselectedQuiz] = React.useState([]);
    const [mandatoryState, setMandatoryState] = React.useState(false)

    let service = new ContentService();
    const GetQuizDetails = () => {
        service.GetAllQuiz().then((data) => {
            let result = data.data;
            SetQuizList(result);
        }).catch((err) => {});
    }
    React.useEffect(() => {
        GetQuizDetails();
    }, []);
    React.useEffect(() => {
        if (props.quizDetails?.quiz_id && quizList.length) {
            let quiz = quizList.find((q) =>
                q.quiz_id === props.quizDetails.quiz_id
            )
            quiz.label = quiz?.quiz_title
            quiz.key = quiz?.quiz_id

            SetselectedQuiz([quiz])
        } else {
            SetselectedQuiz([])
        }
        setMandatoryState(props?.quizDetails?.is_mandatory)
    }, [props.quizDetails, quizList]);

    return (
        <div className='quiz-section'>
            <div className='quiz-header'>
                <div className='content-field'>
                    <label className='input-label'>
                        Quiz
                    </label>
                </div>
                <div className='switch-section'>
                    <FormControlLabel disabled={!props.enableFields}
                        control={<CustomSwitch
                            className='custom-switch'
                            checked={mandatoryState}
                            onChange={(e) => {
                                const list = JSON.parse(JSON.stringify(props.quizDetails))
                                list.is_mandatory = e.target.checked
                                props.SetQuizDetails(list)
                                setMandatoryState(e.target.checked)
                            }}
                            name="content-quiz" />}
                        label="Content Quiz Mandatory"
                    />
                    <CustomTooltip
                        title={
                            <React.Fragment>
                                <div>Once this is enabled user will not be able to </div>
                                <div>skip the content and user can attempt </div>
                                <div>the quiz after the end of the content</div>
                            </React.Fragment>
                        }
                        placement='bottom'
                        arrow>
                        <HelpOutlineIcon style={{ color: '#A4A2A2' }} />
                    </CustomTooltip>
                </div>
            </div>
            <div>
                <Grid container>
                    <Grid className='content-field' item md={4} sm={12}>
                        <Singleselect
                            disabled={!props.enableFields}
                            title={'Quiz Sheet'}
                            placeholder="Select Quiz"
                            tags={selectedQuiz}
                            open={true}
                            list={quizList.length > 0 ? quizList.map(x => ({ label: x.quiz_title, key: x.quiz_id, passing_score: x.passing_score })) : []}
                            onClear={clearQuiz}
                            handleSelected={(value) => {
                                const list = JSON.parse(JSON.stringify(props.quizDetails))
                                list.quiz_id = value[0].key
                                list.passing_score = value[0].passing_score
                                list.quiz_credit = ''
                                props.SetQuizDetails(list)
                                SetselectedQuiz(value)
                            }} />
                    </Grid>
                    <Grid className='content-field' item md={4} sm={12}>
                        <label className='input-label'>
                            Score to Pass
                        </label>
                        <TextField
                            disabled
                            readOnly={true}
                            className='text-input'
                            variant="outlined"
                            placeholder="0"
                            size="small"
                            onChange={(event) => {
                            }}
                            InputLabelProps={{ shrink: false }}
                            value={props?.quizDetails?.passing_score || 0} />
                    </Grid>
                    <Grid className='content-field' item md={4} sm={12}>
                        <label className='input-label'>
                            Quiz Credit
                        </label>
                        <TextField
                            disabled={!props.enableFields}
                            className='text-input'
                            variant="outlined"
                            placeholder="0"
                            size="small"
                            onChange={(event) => {
                                props.setcontentlost(true)
                                const list = JSON.parse(JSON.stringify(props.quizDetails))
                                list.quiz_credit = event.target.value
                                props.SetQuizDetails(list)
                            }}
                            InputLabelProps={{ shrink: false }}
                            value={props.quizDetails?.quiz_credit} />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
export default ContentQuiz