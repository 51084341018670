/* eslint-disable eqeqeq */
import React from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from 'react-router-dom';
import EditPopup from '../Components/EditPopUp';
import editIcon from '../../../public/img/BlueEdit@3x.png';
import deleteIcon from '../../../public/img/RedClose@3x.png';
import { QuizService } from '../services/quiz-service';
import '../quiz.css';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import Modal from '@material-ui/core/Modal';
import Alert from '@material-ui/lab/Alert';
import { FormHelperText, FormLabel, FormControl } from '@material-ui/core';
import useCustomForm from '../../../hooks/useCustomForm';

const QuizDetail = (props) => {
  const modalFunc = React.useRef(null);
  // eslint-disable-next-line no-unused-vars
  const words = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
  const [openModal, setOpenModal] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [ansVal, SetansVal] = React.useState('');
  const [oldAns, SetAns] = React.useState('');
  const [editType, SeteditType] = React.useState('');
  const [getValue, SetgetValue] = React.useState('');
  const [activeIndex, SetactiveIndex] = React.useState(0);
  const [activeSubIndex, SetactiveSubIndex] = React.useState(0);
  const [quizId, SetquizId] = React.useState('');
  let selectorValue = useSelector((state) => state.session);
  const [sessionState, SetSessionState] = React.useState(selectorValue);
  const [openDelConfirmModal, setopenDelConfirmModal] = React.useState(false);
  const [ansIndex, setAnsIndex] = React.useState('');
  const [ansSubIndex, setAnsSubIndex] = React.useState('');
  const [openQueDelConfirmModal, setopenQueDelConfirmModal] =
    React.useState(false);
  const [queIndex, setQueIndex] = React.useState('');
  const [ansPopup, SetansPopup] = React.useState('Answer');
  const [quePopup, SetquePopup] = React.useState('Question');
  const [isDisabled, setIsDisabled] = React.useState(false);

  React.useEffect(() => {
    let uri = window.location.href;
    let id = uri.split('/')[uri.split('/').length - 1];
    const FinalQuestions = [
      {
        question: 'Question',
        answers: [
          { reveal: 'Answer', correct: true },
          { reveal: 'Answer', correct: false },
          { reveal: 'Answer', correct: false },
          { reveal: 'Answer', correct: false },
        ],
      },
    ];
    if (id !== 'quiz-detail') {
      let service = new QuizService();
      let obj = { quiz_id: id };

      service.GetAllQuiz(obj).then((data) => {
        if (data && data.data.length === 0) {
          navigate('/home');
        }
        let result = data.data[0];
        reset({
          quizTitle: result.quiz_title,
          scoreToPass: result.passing_score,
          successMessage: result.success_msg,
          failureMessage: result.error_msg,
          quizDescription: result.quiz_description,
        });
        SetquizId(result.quiz_id);
        setValue('QnA', result.questions);
      });
    } else {
      setValue('QnA', FinalQuestions);
    }
  }, []);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });
  const {
    formData,
    errors: customErrors,
    handleChange,
    setValue,
    validateForm,
    inputRefs,
  } = useCustomForm(
    {
      QnA: [],
    },
    {
      QnA: ['required', 'quizAtleastTwoAns', 'quizAtleastOneCorrectAns'],
    }
  );

  let quizIdFromLink =
    window.location.pathname.split('/')[
      window.location.pathname.split('/').length - 1
    ];
  /**Breadcrumb for user profile */
  const BreadcrumbData = [
    {
      value: props.translations['Home'] ? props.translations['Home'] : 'Home',
      path: '/home',
    },
    { value: 'Quiz Management', path: '/quiz-management' },
    {
      value: 'Quiz Detail',
      path: '/quiz-management/quiz-detail/' + quizIdFromLink,
    },
  ];

  const handleClose = () => {
    setOpenModal(false);
  };

  const onSubmit = (data) => {
    if (!validateForm()) {
      return;
    }
    setIsDisabled(true);
    let service = new QuizService();

    const model = {
      quiz_title: data.quizTitle,
      passing_score: data.scoreToPass,
      success_msg: data.successMessage,
      error_msg: data.failureMessage,
      questions: JSON.stringify(formData.QnA),
      created_at: new Date(),
      created_by: sessionState.sessionValue.payload.user.lilly_id,
      updated_at: new Date(),
      updated_by: sessionState.sessionValue.payload.user.lilly_id,
      quiz_description: data.quizDescription,
    };
    if (quizId != '') {
      model.quiz_id = quizId;
      model.updated_by = sessionState.sessionValue.payload.user.lilly_id;
    }
    setIsDisabled(false);

    service.UpdateQuiz(model).then((data) => {
      navigate('/quiz-management');
    });
  };

  const handleAddQuestion = () => {
    let mainData = [...formData.QnA];
    let sampleQuestion = {
      question: 'Question',
      answers: [
        { reveal: 'Answer', correct: true },
        { reveal: 'Answer', correct: false },
        { reveal: 'Answer', correct: false },
        { reveal: 'Answer', correct: false },
      ],
    };
    mainData.push(sampleQuestion);
    handleChange('QnA', mainData);
  };

  const deleteQuestion = (index) => {
    let mainData = [...formData.QnA];
    delete mainData[index];
    mainData.splice(index, 1);
    setopenQueDelConfirmModal(false);
    handleChange('QnA', mainData);
  };

  const addNewAns = (index) => {
    let mainData = [...formData.QnA];
    let sampleAns = { reveal: 'Answer', correct: false };
    mainData[index].answers.push(sampleAns);
    handleChange('QnA', mainData);
  };

  const editPopup = (val1) => {
    SetansVal(val1);
    setOpenModal(true);
  };

  const editCorrect = (index, subindex, newValue) => {
    let mainData = [...formData.QnA];
    mainData[index].answers[subindex].correct = newValue;
    handleChange('QnA', mainData);
  };

  const editAnswer = (index, subindex, newValue) => {
    let mainData = [...formData.QnA];
    mainData[index].answers[subindex].reveal = newValue;
    handleChange('QnA', mainData);
    SetansVal('');
  };

  const editQuestion = (index, newValue) => {
    let mainData = [...formData.QnA];
    mainData[index].question = newValue;
    handleChange('QnA', mainData);
    SetansVal('');
  };

  const deleteAnswer = (index, subindex) => {
    let mainData = [...formData.QnA];
    delete mainData[index].answers[subindex];
    mainData[index].answers.splice(subindex, 1);
    setopenDelConfirmModal(false);
    handleChange('QnA', mainData);
  };

  const arrowUp = (index) => {
    let mainData = [...formData.QnA];
    let up = mainData[index];
    let down = mainData[index - 1];
    mainData.splice(index, 1, down);
    mainData.splice(index - 1, 1, up);
    handleChange('QnA', mainData);
  };

  const arrowDown = (index) => {
    let mainData = [...formData.QnA];
    let down = mainData[index];
    let up = mainData[index + 1];
    mainData.splice(index, 1, up);
    mainData.splice(index + 1, 1, down);
    handleChange('QnA', mainData);
  };

  const updateQnA = (value) => {
    SetgetValue(value);
  };

  React.useEffect(() => {
    if (editType === 'Answer') {
      if (getValue != undefined && getValue != '' && getValue != null)
        editAnswer(activeIndex, activeSubIndex, getValue);
    } else {
      if (getValue != undefined && getValue != '' && getValue != null)
        editQuestion(activeIndex, getValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValue]);

  const handleAnsDelConfirmClose = () => {
    setopenDelConfirmModal(false);
  };

  const handleQueDelConfirmClose = () => {
    setopenQueDelConfirmModal(false);
  };

  const openAnsDelConfirm = (index, subindex) => {
    setAnsIndex(index);
    setAnsSubIndex(subindex);
    setopenDelConfirmModal(true);
  };

  const openQueDelConfirm = (index) => {
    setQueIndex(index);
    setopenQueDelConfirmModal(true);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid>
        <Modal open={openDelConfirmModal} onClose={handleAnsDelConfirmClose}>
          <Grid className='paper modalstyle'>
            <Grid className='modal-title'>
              <button
                className='closeModal'
                onClick={() => handleAnsDelConfirmClose()}
              ></button>
            </Grid>
            <Grid className='modal-body'>
              <div className='m20'>
                <Alert severity='warning'>
                  {ansPopup} will be deleted, are you sure?
                </Alert>
                {/* <Alert severity="warning">click OK to continue deleting.</Alert> */}
              </div>
            </Grid>
            <Grid className='modal1FoBtn modal-footer'>
              <button
                type='button'
                onClick={() => handleAnsDelConfirmClose()}
                className='modalBtn'
              >
                Cancel
              </button>
              <button
                type='button'
                onClick={() => deleteAnswer(ansIndex, ansSubIndex)}
                className='modalBtn'
              >
                OK
              </button>
            </Grid>
          </Grid>
        </Modal>

        <Modal open={openQueDelConfirmModal} onClose={handleQueDelConfirmClose}>
          <Grid className='paper modalstyle'>
            <Grid className='modal-title'>
              <button
                className='closeModal'
                onClick={() => handleQueDelConfirmClose()}
              ></button>
            </Grid>
            <Grid className='modal-body'>
              <div className='m20'>
                <Alert severity='warning'>
                  {quePopup} will be deleted, are you sure?
                </Alert>
                {/* <Alert severity="warning">click OK to continue deleting.</Alert> */}
              </div>
            </Grid>
            <Grid className='modal1FoBtn modal-footer'>
              <button
                type='button'
                onClick={() => handleQueDelConfirmClose()}
                className='modalBtn'
              >
                Cancel
              </button>
              <button
                type='button'
                onClick={() => deleteQuestion(queIndex)}
                className='modalBtn'
              >
                OK
              </button>
            </Grid>
          </Grid>
        </Modal>
        <Breadcrumb
          translations={props.translations}
          setFilter={props.setFilter}
          BreadcrumbData={BreadcrumbData}
        />
        <Modal open={openModal} onClose={handleClose}>
          <EditPopup
            handleClose={handleClose}
            editType={editType}
            valueName={ansVal}
            updateQnA={updateQnA}
            oldAns={oldAns}
          />
        </Modal>

        <Grid className='main-wrp'>
          <Grid container spacing={2} className='outer'>
            <Grid item xs={12} lg={12} className='header'>
              <Typography variant='h6'>Enter Your Quiz Details</Typography>
            </Grid>

            <Grid item xs={12} lg={12} className='greyBox'>
              <Grid container spacing={2} justifyContent='space-around'>
                <Grid item xs={12} lg={5}>
                  <FormControl
                    className='formControl'
                    fullWidth={true}
                    error={!!errors.quizTitle}
                  >
                    <FormLabel
                      htmlFor='quizTitle'
                      className='font-weight-bold'
                      required
                      error
                    >
                      Quiz Title
                    </FormLabel>

                    <TextField
                      className='quizDetailsField'
                      error={!!errors.quizTitle}
                      placeholder='Quiz Title{max. 80 characters}'
                      variant='outlined'
                      size='small'
                      {...register('quizTitle', {
                        required: 'Quiz Title is required',
                        maxLength: {
                          value: 80,
                          message: 'Quiz Title cannot exceed 80 characters',
                        },
                      })}
                    />
                    {errors.quizTitle && (
                      <FormHelperText>
                        {errors.quizTitle.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} lg={5}>
                  <FormControl
                    className='formControl'
                    fullWidth={true}
                    error={!!errors.scoreToPass}
                  >
                    <FormLabel
                      htmlFor='scoreToPass'
                      className='font-weight-bold'
                      required
                      error
                    >
                      Score To Pass
                    </FormLabel>
                    <TextField
                      className='quizDetailsField'
                      error={!!errors.scoreToPass}
                      placeholder='[0-100]'
                      variant='outlined'
                      size='small'
                      {...register('scoreToPass', {
                        required: 'Score to pass is required',
                        pattern: {
                          value: /^(100(\.0{1,2})?|(\d{1,2}(\.\d{1,2})?))$/,
                          message: 'Enter a valid percentage (0-100%)', // Regex for percentage validation
                        },
                        validate: {
                          rangeCheck: (value) => {
                            // If it's not empty and doesn't contain the '%' symbol, parse as a number
                            if (value) {
                              const numValue = parseFloat(value);
                              if (numValue < 0 || numValue > 100) {
                                return 'Percentage must be between 0 and 100';
                              }
                            }
                            return true; // Valid value
                          },
                        },
                      })}
                    />
                    {errors.scoreToPass && (
                      <FormHelperText>
                        {errors.scoreToPass.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2} justifyContent='space-around'>
                <Grid item xs={12} lg={5}>
                  <FormControl
                    className='formControl'
                    fullWidth={true}
                    error={!!errors.quizDescription}
                  >
                    <FormLabel
                      htmlFor='quizDescription'
                      className='font-weight-bold'
                      required
                      error
                    >
                      Quiz Description
                    </FormLabel>
                    <TextField
                      className='quizDetailsField'
                      error={!!errors.quizDescription}
                      placeholder='Quiz Description{max. 200 characters}'
                      variant='outlined'
                      size='small'
                      {...register('quizDescription', {
                        required: 'Quiz Desciption is required',
                        maxLength: {
                          value: 200,
                          message:
                            'Quiz Desciption cannot exceed 200 characters',
                        },
                      })}
                    />
                    {errors.quizDescription && (
                      <FormHelperText>
                        {errors.quizDescription.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={5}>
                  <FormControl
                    className='formControl'
                    fullWidth={true}
                    error={!!errors.successMessage}
                  >
                    <FormLabel
                      htmlFor='successMessage'
                      className='font-weight-bold'
                      required
                      error
                    >
                      Success Message
                    </FormLabel>
                    <TextField
                      className='quizDetailsField'
                      error={!!errors.successMessage}
                      placeholder='Enter Success Message{max. 200 characters}'
                      variant='outlined'
                      size='small'
                      {...register('successMessage', {
                        required: 'Success Message is required',
                        maxLength: {
                          value: 200,
                          message:
                            'Success Message cannot exceed 200 characters',
                        },
                      })}
                    />
                    {errors.successMessage && (
                      <FormHelperText>
                        {errors.successMessage.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2} justifyContent='space-around'>
                <Grid item xs={12} lg={5}>
                  <FormControl
                    className='formControl'
                    fullWidth={true}
                    error={!!errors.failureMessage}
                  >
                    <FormLabel
                      htmlFor='failureMessage'
                      className='font-weight-bold'
                      required
                      error
                    >
                      Failure Message
                    </FormLabel>
                    <TextField
                      className='quizDetailsField'
                      error={!!errors.failureMessage}
                      placeholder='Enter Failure Message{max. 200 characters}'
                      variant='outlined'
                      size='small'
                      {...register('failureMessage', {
                        required: 'Failure Message is required',
                        maxLength: {
                          value: 200,
                          message:
                            'Failure Message cannot exceed 200 characters',
                        },
                      })}
                    />
                    {errors.failureMessage && (
                      <FormHelperText>
                        {errors.failureMessage.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={5}></Grid>
              </Grid>
              <Grid>
                <Grid
                  container
                  justifyContent='flex-end'
                  onClick={() => {
                    handleAddQuestion();
                    SeteditType('Question');
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <Button className='addIconBtn'>
                    <img
                      src={require('../../../public/img/Add@3x.png')}
                      alt='Add Question'
                    />
                    Add Question
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={12}>
              <div className="quizsection" ref={(el) => (inputRefs.current['QnA'] = el)} tabIndex={-1}>
                {customErrors.QnA?.type === 'required' && (
                  <FormHelperText error>
                    Please add at least one question
                  </FormHelperText>
                )}
                {customErrors.QnA?.type === 'quizAtleastTwoAns' && (
                  <FormHelperText error>
                    Please add at least two answers for each question
                  </FormHelperText>
                )}
                {customErrors.QnA?.type === 'quizAtleastOneCorrectAns' && (
                  <FormHelperText error>
                    Please add at least one correct answer for each question
                  </FormHelperText>
                )}
              </div>
              {formData.QnA.map((qa, index) => {
                return (
                  <FormGroup className='quesForm' key={index}>
                    <div className='childDrop quizsection'>
                      Q{index + 1}: {qa?.question}
                      <div className='childDropInnr'>
                        <img
                          className='iconChild'
                          src={editIcon}
                          alt='Edit'
                          onClick={() => {
                            SeteditType('Question');
                            SetactiveIndex(index);
                            editPopup(qa?.question);
                            SetAns(qa?.question);
                          }}
                        />
                      </div>
                    </div>
                    <Grid container justifyContent='flex-end'>
                      {formData.QnA?.length == 1 ? (
                        <Button disabled style={{ cursor: 'pointer' }}>
                          <img
                            src={require('../../../public/img/downArrow.png')}
                            alt='DownArrow'
                          />
                        </Button>
                      ) : index + 1 == 1 ? (
                        <Button
                          style={{ cursor: 'pointer' }}
                          onClick={() => arrowDown(index)}
                        >
                          <img
                            src={require('../../../public/img/downArrow.png')}
                            alt='DownArrow'
                          />
                        </Button>
                      ) : index + 1 == formData.QnA?.length ? (
                        <Button
                          style={{ cursor: 'pointer' }}
                          onClick={() => arrowUp(index)}
                        >
                          <img
                            src={require('../../../public/img/UpArrow.png')}
                            alt='UpArrow'
                          />
                        </Button>
                      ) : (
                        <>
                          <Button
                            style={{ cursor: 'pointer' }}
                            onClick={() => arrowUp(index)}
                          >
                            <img
                              src={require('../../../public/img/UpArrow.png')}
                              alt='UpArrow'
                            />
                          </Button>
                          <Button
                            style={{ cursor: 'pointer' }}
                            onClick={() => arrowDown(index)}
                          >
                            <img
                              src={require('../../../public/img/downArrow.png')}
                              alt='DownArrow'
                            />
                          </Button>
                        </>
                      )}
                    </Grid>
                    {qa?.answers.map((ans, subindex) => {
                      return (
                          <div className='childDrop quizsection' key={subindex}>
                            {words[subindex]}{' '}
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={ans.correct}
                                  onClick={() => {
                                    editCorrect(index, subindex, !ans.correct);
                                  }}
                                />
                              }
                              label={ans.reveal}
                            />
                            <span className='childDropInnr'>
                              <img
                                className='iconChild'
                                src={editIcon}
                                alt='Edit'
                                onClick={() => {
                                  SeteditType('Answer');
                                  SetactiveIndex(index);
                                  SetactiveSubIndex(subindex);
                                  editPopup(ans.reveal);
                                  SetAns(ans.reveal);
                                }}
                              />
                              <img
                                className='iconChild'
                                src={deleteIcon}
                                alt='Delete'
                                onClick={() => {
                                  openAnsDelConfirm(index, subindex);
                                  SetansPopup(ans.reveal);
                                }}
                              />
                            </span>
                          </div>
                      );
                    })}

                    <Grid>
                      {qa?.answers?.length != 8 ? (
                        <Grid container style={{ cursor: 'pointer' }}>
                          <Button
                            className='addAnswerBtn'
                            onClick={() => addNewAns(index)}
                          >
                            <img
                              src={require('../../../public/img/Add@3x.png')}
                              alt='Add Answer'
                            />
                            Add Answer
                          </Button>
                        </Grid>
                      ) : null}
                      <Grid
                        container
                        justifyContent='flex-end'
                        style={{ cursor: 'pointer' }}
                      >
                        <Button
                          className='deleteQue'
                          onClick={() => {
                            openQueDelConfirm(index);
                            SetquePopup(qa.question);
                          }}
                        >
                          <img
                            src={require('../../../public/img/RemoveIcon.png')}
                            alt='Delete'
                          />
                        </Button>
                      </Grid>
                    </Grid>
                  </FormGroup>
                );
              })}
            </Grid>

            <Grid container className='content grey_row'>
              <Grid
                container
                className='row_inner_box'
                justifyContent='space-around'
              >
                <Grid item lg={6} md={6} xs={6} className='text-left'>
                  <Button
                    variant='outlined'
                    className='custom-quiz-btn createBtn'
                    onClick={() => navigate('/quiz-management')}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item lg={6} md={6} xs={6} className='text-right'>
                  <button className='modalBtn' type='submit'>
                    Save
                  </button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default QuizDetail;
