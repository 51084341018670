import React from 'react';

import LillyLogo from '../../../public/img/event_LillyLogo.png';
import LillyPlayLogo from '../../../public/img/LogoLillyPlayWhite.png';
import Logo1 from '../../../public/img/es1.png';
import Logo2 from '../../../public/img/es2.png';
import Logo3 from '../../../public/img/es3.png';
import Logo4 from '../../../public/img/es4.png';
import Logo5 from '../../../public/img/es5.png';
import Logo6 from '../../../public/img/es6.png';


const Spain = (props) => {
    function InitializePlayer(kalturaId) {
        let widgetSetting = {
            'targetId': 'kaltura_player_1582810304',
            'wid': '_1759891',
            'uiconf_id': '32105511',
            'cache_st': '1582810304',
            'entry_id': kalturaId,
            'flashvars': {
                'autoPlay': false,
                'autoMute': true,
                'allowMutedAutoPlay': true
            }
        };
        window.kWidget.embed(widgetSetting);
    }
    
    React.useEffect(() => {
        let head = document.head;
        let link1 = document.createElement("link");
        link1.type = "text/css";
        link1.rel = "stylesheet";
        link1.href = "https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css";
        head.appendChild(link1);

        let link2 = document.createElement("link");
        link2.type = "text/css";
        link2.rel = "stylesheet";
        link2.href = window.location.origin +"/css/landing3.css";
        head.appendChild(link2);
        // document.getElementById('link10').disabled = true
        InitializePlayer('1_rxcd1on9');
        
        return () => { 
            head.removeChild(link1); 
            head.removeChild(link2);
        }
    }, []);
    
  return (
    <>

    <div className="header container-fluid">
        <div className="row">
            <div className="logo-pagina col-6 text-left"><img src={LillyPlayLogo} alt="logo" /></div>
            <div className="logo col-6 text-right"><img src={LillyLogo} alt="lilli_logo" /></div>
        </div>
    </div>
    <div className="container-fluid buttons-container-fluid">
        <div className="row register_inicie_button">
            <div className="col-md-5 offset-md-1 text-center registrese_ahora_button">
                <div className="button">Si es la primera vez que accede <br /><a href name="landing-Register" onClick={props.passportRegister} className="es-register">REGÍSTRESE AHORA</a></div>
            </div>
            <div className="col-md-5 text-center inicie_sesion_button">
                <div className="button">Si ya está registrado<br /><a href name="landing-Login" onClick={props.passportLogin} className="es-login">INICIE SESIÓN</a></div>
            </div>
        </div>
    </div>
    <div className="testata container-fluid">
        <div className="row">
            <div className="col-md-5 offset-md-1 banner-text-white text-white">
                <div className="title">
                    <h1 className="promotional-video-title" title="Descubra Lilly Play">Descubra Lilly Play,<br />
                        la plataforma de vídeos formativos específica para médicos</h1>
                </div>
            </div>
            <div className="col-md-5">
                <div>
                    <div id="kaltura_player_1582810304" className="kWidgetIframeContainer first-video"></div>
                </div>
            </div>
        </div>
    </div>

    <div className="container">
        <div className="row text-center lp-box">
            <div className="col-md-10 offset-md-1 subtitle">Todo lo que puede encontrar en Lilly Play:</div>
            <div className="col-md-4 borderbox">
                <img src={Logo1} alt="Reumatologia" />
                <div className="title-box">
                    <h3>Reumatología</h3>
                </div>
                <div className="desc-box">
                    <h4>Diagnóstico, testimonios de pacientes, tratamientos, efectividad y seguridad, y preguntas
                        frecuentes en estas patologías tratadas por expertos en <b>Artritis Reumatoide</b> o <b>Artritis
                            Psoriásica</b></h4>
                </div>

            </div>
            <div className="col-md-4 borderbox">
                <img src={Logo2} alt="dermatologia" />
                <div className="title-box">
                    <h3>Dermatología</h3>
                </div>
                <div className="desc-box">
                    <h4>Expertos nacionales e internacionales comparten información científica y su punto
                        de vista sobre temas relacionados con la <b>psoriasis</b>, como la eficacia, la seguridad y las
                        comorbilidades.</h4>
                </div>

            </div>
            <div className="col-md-4 borderbox">
                <img src={Logo3} alt="Diabetes" />
                <div className="title-box">
                    <h3>Diabetes</h3>
                </div>
                <div className="desc-box">
                    <h4>Efectividad, seguridad, dispositivos, insulina, <b>arGLP-1...</b> Los líderes de
                        opinión en diabetes de <b>todo el mundo</b>, resumen las últimas evidencias
                        científicas publicadas.</h4>
                </div>

            </div>
        </div>
        <div className="row text-center lp-box">
            <div className="col-md-4 borderbox">
                <img src={Logo4} alt="Oncologia" />
                <div className="title-box">
                    <h3>Oncología</h3>
                </div>
                <div className="desc-box">
                    <h4><b>Efectividad, seguridad, fármacos biológicos</b> y muchos otros temas tratados
                        por líderes de opinión. ¡Descubra ahora todo el contenido de los
                        <b>vídeos</b> desarrollado por <b>LillyOncología</b> para profesionales
                        sanitarios!
                    </h4>
                </div>

            </div>
            <div className="col-md-4 borderbox">
                <img src={Logo5} alt="Osteoporosis" />
                <div className="title-box">
                    <h3>Osteoporosis</h3>
                </div>
                <div className="desc-box">
                    <h4>Contiene recursos sobre productos y servicios en el campo de la <b>osteoporosis</b>, orientados
                        a los profesionales médicos.</h4>
                </div>

            </div>
            <div className="col-md-4">
                <img src={Logo6} alt="lilly_logo" />
                <div className="title-box">
                    <h3>Neurología</h3>
                </div>
                <div className="desc-box">
                    <h4>Webinars con líderes de opinión nacionales e internacionales, videos sobre el impacto de la
                        migraña en la vida de los pacientes y otros contenidos de interés dirigidos al neurólogo.
                    </h4>
                </div>

            </div>
        </div>
    </div>
    <div className="banner_footer container-fluid"></div>
    <div className="lp-footer container-fluid">
        <div className="row">
            <div className="col-sm-6 text-left">
                <a rel="noreferrer" href="https://pages.mc.lilly.com/page.aspx?qs=472529ec60bdf32af430c85722cdc248dc418818e65637a5a021a6c0f0f22106" target="_blank">Copyright © 2025 Eli Lilly and Company. All rights reserved</a>
            </div>
            <div className="col-sm-6 text-right">
                <a rel="noreferrer" href="https://web.mc.lilly.com/General-con-registro-4-Cookies" target="_blank">Política de Cookies</a> | 
                <a rel="noreferrer" href="https://www.lillyprivacy.com/es-es/hcp" target="_blank">Política de privacidad de datos</a> |
                <a rel="noreferrer" href="https://web.mc.lilly.com/General-con-registro-2-Terminos-y-Condiciones" target="_blank">Términos y Condiciones de Uso</a> | 
                <a rel="noreferrer" href="https://web.mc.lilly.com/accessibilidad-lilly" target="_blank">Declaración de Accesibilidad</a>| 
                <a href data-triggers-cookie-settings="true" onClick={props.cookieSettingBtnHandler}>Configuración de las cookies</a> | PP-LILLY-ES-0611
            </div>
        </div>
    </div>
    </>
  )
}

export default Spain