import React, { useEffect, useState } from 'react';
import newTagIcon from '../../../../public/img/new-img/NEW_REDIndicate@2x.png';
import classes from './styles.module.css';

const NewLabel = ({ content, watchedContent }) => {
  const [isNewLabel, setIsNewLabel] = useState(true);
  const { content_id, created_at, content_type } = content;

  useEffect(() => {
    if (content_type === 'playlist') {
      return;
    }
    const isContentWatchedOrWatching = watchedContent?.some(
      (content) => content.content_id === content_id
    );

    if (isContentWatchedOrWatching) {
      setIsNewLabel(false);
    }
  }, [watchedContent, content_id, content_type]);

  useEffect(() => {
    // Check if the content is older than 7 days
    const currDate = new Date();
    const contentDate = new Date(created_at);
    const isWeekAgo = (currDate - contentDate) / (1000 * 3600 * 24) > 7;

    if (isWeekAgo) {
      setIsNewLabel(false);
    }
  }, [created_at]);

  if (!isNewLabel) {
    return null;
  }
  return (
    <img src={newTagIcon} className={classes['new-added-label']} alt='img' />
  );
};
export default NewLabel;
