import React, { forwardRef, Fragment } from 'react';
import { FormHelperText, Grid } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const ContentTherapeuticArea = forwardRef(({categoryTree, onCategorySelect, errors}, ref) => {

  return (
    <Grid container className="content white_row">
      <Grid container className="content_inner_box">
        <Grid item lg={12} xs={12}>
          <FormControl component="fieldset" error={!!errors.selectedBrands}>
            <FormLabel component="legend" required ref={(el) => (ref.current['selectedBrands'] = el)} tabIndex={-1}>Therapeutic Areas, Category 1 and Category 2 (Category 1 is mandatory field)</FormLabel>
            {!!errors.selectedBrands && <FormHelperText>Please select at least one category 1</FormHelperText>}
            <div className='ml20'>
              <>
                {categoryTree.length > 0 ?
                  <TreeView className='contentTreeview' defaultCollapseIcon={<ExpandMoreIcon className='iconSvg' />} defaultExpandIcon={<ExpandMoreIcon className='iconSvg' />}>
                    {categoryTree.map((treeItemData, index) => {
                      return (
                        <Fragment key={index}>
                          <TreeItem className={`${index}`} nodeId={treeItemData?.id?.toString()} key={index} label={
                            <>
                              <div className='childDrop'>{treeItemData?.name ? treeItemData.name : null}</div>
                            </>
                          }>
                            {treeItemData.children.length > 0 ? treeItemData.children.map((treeItemChildren, innerIndex) => {
                              return (
                                <TreeItem nodeId={"treeItemChildren"+treeItemChildren.id.toString()} key={innerIndex} label={
                                  <>
                                    <div className='childDrop'>
                                      <FormControlLabel control={
                                        <Checkbox value={treeItemChildren.name} name="categories"
                                          checked={treeItemChildren.isChecked === true}
                                          onChange={(event) => onCategorySelect(treeItemChildren.id, event.target.checked)}
                                        />
                                      } />
                                      {treeItemChildren?.name ? treeItemChildren.name : null}
                                    </div>
                                  </>
                                }
                                >
                                  {treeItemChildren.children.length > 0 ? treeItemChildren.children.map((treeItemSubchildren, innerIndex2) => {
                                    return (
                                      <TreeItem nodeId={"treeItemSubchildren"+treeItemSubchildren?.id} key={innerIndex2} label={
                                        <>
                                          <div className='subchildDrop'>
                                            <FormControlLabel control={
                                              <Checkbox value={treeItemSubchildren.name} name="categories"
                                                checked={treeItemSubchildren.isChecked === true}
                                                onChange={(event) => onCategorySelect(treeItemSubchildren.id, event.target.checked)}
                                              />
                                            } />
                                            {treeItemSubchildren?.name ? treeItemSubchildren.name : null}
                                          </div>
                                        </>
                                      }
                                      />
                                    )
                                  }) : null}
                                </TreeItem>
                              )
                            }) : null}
                          </TreeItem>
                        </Fragment>
                      );
                    })}
                  </TreeView>
                : <Grid>No Data Found</Grid>}
              </>
            </div>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  )
});
export default ContentTherapeuticArea