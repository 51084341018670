/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import React, { Fragment } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import { useNavigate } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Alert from '@material-ui/lab/Alert';
import '../user-management/user-management.css';

import { UserProfileService } from '../Services/user-profile-service';

const UserProfile = (props) => {
    let userProfileService = new UserProfileService();
    const navigate = useNavigate();
    const [lillyId, SetLillyId] = React.useState('');
    const [name, Setname] = React.useState('');
    const [lastName, SetLastName] = React.useState('');
    const [country, Setcountry] = React.useState('');
    const [email, Setemail] = React.useState('');
    const [roles, SetRoles] = React.useState([]);
    const [globalId, SetglobalId] = React.useState([]);
    const [selectedRoles, SetSelectedROles] = React.useState([]);
    const [hubAffiliateDict, SethubAffiliateDict] = React.useState({});
    const [hubs, SetHubs] = React.useState([]);
    const [renderCheck, SetRenderCheck] = React.useState(false);
    const [selectedAffiliates, SetSelectedAffiliates] = React.useState([]);
    const [hubCheckbox, SetHubCheckbox] = React.useState({});
    const [activeUserId, SetactiveUserId] = React.useState({});
    const [hcpId, SethcpId] = React.useState('');
    const [publisherId, SetpublisherId] = React.useState('');
    const [hideshowcountry, Sethideshowcountry] = React.useState(false);
    const [alterstateshow, Setalterstateshow] = React.useState(false);
    const [hubsArray, SethubsArray] = React.useState([]);
    const [cancelPopup, SetcancelPopup] = React.useState(false);
    const [ChangeArray, setChangeArray] = React.useState(false);

    /**Function to fetch hubs as per affiliate */
    const CheckHubs = (hubId) => {
        let affiliates =  Object.keys(hubAffiliateDict[hubId]);
        for (let i = 0; i < affiliates.length; i++) {
            if(!selectedAffiliates.includes(affiliates[i])){
                return false;
            }
        }
        return true;
    }

    /**Function alters all the affiliate states */
    const AlterAffiliateState = (affiliate, hub) => {
        setChangeArray(true);
        let presentAffiliate = selectedAffiliates;
        let checkUncehckTest = true;
        if (presentAffiliate.includes(affiliate)) {
            checkUncehckTest = false;
        }
        let list = [];
        for (let i = 0; i < presentAffiliate.length; i++) {
            if (presentAffiliate[i] != null) {
                if (affiliate != presentAffiliate[i]) {
                    list.push(presentAffiliate[i]);
                }
            }

        }
        if (checkUncehckTest) {
            list.push(affiliate);
        }
        if (list != null) {
            SetSelectedAffiliates(list);
        }
        // SetSelectedAffiliates(list);
        let listofAffiliates = hubAffiliateDict[hub.hub_id];
        let selectedAffiliateIds = Object.keys(listofAffiliates);
        let checkList = [];
        for (let i = 0; i < (selectedAffiliateIds).length; i++) {
            if (list.includes(parseInt(selectedAffiliateIds[i]))) {
                checkList.push(true);
            } else {
                checkList.push(false);
            }
        }
        if (!checkList.includes(false)) {
            let tempHubCheckBox = hubCheckbox;
            tempHubCheckBox[hub.hub_id] = true;
            SetHubCheckbox(tempHubCheckBox);
        } else {
            let tempHubCheckBox = hubCheckbox;
            tempHubCheckBox[hub.hub_id] = false;
            SetHubCheckbox(tempHubCheckBox);
        }
    }

    /**Function alters all the roles states */
    const AlterRolesState = (role) => {
        setChangeArray(true);
        if (selectedRoles.includes(role)) { ///uncheck
            SetSelectedROles(selectedRoles.filter(item => item !== role))
        } else { //check
            if (role == hcpId) {
                SetSelectedROles([role]);
            } else {
                SetSelectedROles([...selectedRoles.filter(item => item !== hcpId), role]);
            }
        }
        Setalterstateshow(true)
    }
    React.useEffect(() => {
        //if(alterstateshow){
        if (selectedRoles.includes(publisherId)) {
            Sethideshowcountry(true);
        } else {
            Sethideshowcountry(false);
        }
        Setalterstateshow(false)
        // }
    }, [selectedRoles, alterstateshow, roles])
    /**Function submits all the data for the selected user */
    const handleSubmit = () => {
        let country = []
        if(selectedRoles.includes(2) || selectedRoles.includes(4)){
            country.push('IN')
        }
        let model = {
            country_code: selectedRoles.includes(publisherId) ? selectedAffiliates : country?country: [],
            octa_id: '',
            customer_id: '',
            lilly_id: lillyId,
            user_name: name,
            role_ids: selectedRoles,
            user_email: email,
            email_id: email,
            global_id: globalId,
            specialty: {},
            therapeutic_area: [],
            profession: {},
            city: '',
            avatar_attachment_id: '',
            affiliate_code: 'avc',
            identifiers: '',
            status_flag: 'Active',
            created_on: new Date(),
            created_by_user_id: 0,
            updated_on: new Date(),
            updated_by_user_id: 0,
            id: activeUserId
        }
        userProfileService.UpdateUser(model)
            .then((data) => {
                props.setupdateBool(true);
                navigate('/user-management');
            })
            .catch((err) =>{})
    }

    /**Function fetches all the data for the selected affiliate */
    function GetAllAffiliates(hubs, selectedAffiliates) {
        userProfileService.GetAffiliateByQuery().then((data) => {
            let affiliates = data.data;
            if (affiliates.length > 0) {
                if (hubs.length > 0) {
                    let hubDict = {}
                    let hubCheckbox = {}
                    for (let k = 0; k < hubs.length; k++) {
                        let affiliateDict = {};
                        let checkList = [];
                        for (let i = 0; i < affiliates.length; i++) {
                            if (hubs[k].country_codes.includes(affiliates[i].country_code)) {
                                affiliateDict[affiliates[i].country_code] = affiliates[i];
                                if (selectedAffiliates != null) {
                                    if (selectedAffiliates.includes(affiliates[i].country_code)) {
                                        checkList.push(true);
                                    }
                                    else {
                                        checkList.push(false);
                                    }
                                } else {
                                    checkList.push(false);
                                }

                            }
                        }
                        if (checkList.includes(false)) {
                            hubCheckbox[hubs[k].hub_id] = false;
                        }
                        else {
                            hubCheckbox[hubs[k].hub_id] = true;
                           
                            SethubsArray(hubsArray => [...hubsArray,hubs[k].hub_id] )
                        }
                        hubDict[hubs[k].hub_id] = affiliateDict
                    }
                    SetHubCheckbox(hubCheckbox);
                    SethubAffiliateDict(hubDict);
                    SetRenderCheck(true);
                }
            }
        }).catch((err) => {});
    }

    /**Function fetches all the data of hub */
    function GetAllHubs(affiliateIds) {
        userProfileService.GetHubsByQuery().then((data) => {
            SetHubs(data.data);
            GetAllAffiliates(data.data, affiliateIds);
           
            // SethubsArray([...hubsArray, hubid])

        }).catch((err) => {});
    }

    /**Function fetches roles of selected user */
    function GetUserROles() {
        userProfileService.GetRolesByQuery().then((data) => {
            for (let i = 0; i < data.data.length; i++) {
                if (data.data[i].role_name == 'hcp') {
                    SethcpId(data.data[i].role_id)
                }
                if (data.data[i].role_name == 'publisher') {
                    SetpublisherId(data.data[i].role_id)
                }
            }
            Setalterstateshow(true)
            SetRoles(data.data);
        }).catch((err) => {});
    }

    /**Function fetches user information */
    function GetUserInfo() {
        let uri = window.location.href;
        let lillyId = uri.split('/')[uri.split('/').length - 1];
        userProfileService.GetUsersByQuery('id='+lillyId).then((data) => {
            if(data && data.data.length === 0){
                document.location.href = '/home';
            }
            let user = data.data;
            if (user.length > 0) {
                for (let i = 0; i < user.length; i++) {
                    let idToSet = user[i].okta_id?user[i].okta_id : user[i].lilly_id
                    SetLillyId(idToSet);
                    Setname(user[i].first_name);
                    SetLastName(user[i].last_name);
                    Setcountry('');
                    Setemail(user[i].user_email);
                    SetglobalId(user[i].global_id);
                    SetSelectedROles(user[i].role_ids);
                    if (user[i].country_code != null) {
                        SetSelectedAffiliates(user[i].country_code);
                    } else {
                        SetSelectedAffiliates([]);
                    }
                    GetAllHubs(user[i].country_code)
                    SetactiveUserId(user[i].id)
                }
            }
        }).catch((err) => {
        })
    }
    React.useEffect(() => {
        window.scrollTo(0, 0);
        GetUserInfo();
        GetUserROles();
    }, []);

    /**Breadcrumb for user profile */
    let userProfile = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
    const BreadcrumbData = [
        { value: props.translations['Home'] ? props.translations['Home'] : 'Home', path: '/home' },
        { value: 'User Management', path: '/user-management' },
        { value: 'User Profile', path: '/user-profile/' + userProfile }
    ];

    const AlterParentAffiliateState = (ids, hubid, state, event) => {

        let affiliates =  Object.keys(hubAffiliateDict[hubid]);
        let presentList = Object.assign([],selectedAffiliates);
        let existingList = presentList.filter((v)=> affiliates.includes(v));
        if(!event.target.checked){
            let newList = presentList.filter((v)=> !affiliates.includes(v));
            SetSelectedAffiliates(newList);
        }
        else{
            for(let i =0;i<affiliates.length;i++){
                presentList.push(affiliates[i]);
            }
            SetSelectedAffiliates(presentList);
        }
       
    }

    React.useEffect(() => {
    }, [selectedAffiliates]);

    const cancelButton = () => {
        if (ChangeArray) {
            SetcancelPopup(true)
        } else {
            navigate('/user-management')
        }
    }

    return (renderCheck ?
        <Grid>
            <Modal open={cancelPopup} onClose={() => SetcancelPopup(false)}>
                <Grid className='paper modalstyle uservalidationPopup'>
                    <Grid className="modal-title">
                        <button className='closeModal' onClick={() => SetcancelPopup(false)}></button>
                    </Grid>
                    <Grid className="modal-body">
                        <div className="m20">
                            <Alert severity="warning">{props.translations['Your Changes will be lost!'] ? props.translations['Your Changes will be lost!'] : 'Your Changes will be lost!'}</Alert>
                        </div>
                    </Grid>
                   
                    <Grid className="modal1FoBtn modal-footer ">
                        <button type="button" onClick={() => SetcancelPopup(false)} className="modalBtn ">Cancel</button>
                        <button type="button" onClick={() => navigate('/user-management')
                        } className="modalBtn ">OK</button>
                    </Grid>
                </Grid>
            </Modal>
            <Breadcrumb translations={props.translations} setFilter={props.setFilter} BreadcrumbData={BreadcrumbData} />
            <Grid className="main-wrp">
                <Grid container spacing={2} className='outer'>
                    <Grid item xs={12} lg={12} className='header header-style'>
                        <Typography variant="h6">User Profile</Typography>
                    </Grid>
                    <Grid item xs={12} lg={12} className='box-width content '>
                        <Grid container spacing={2} className='grey_row width'>
                            <Grid item xs={12} lg={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={6} lg={4} className='txtCntrSm'>
                                        <FormLabel component="legend">Employee ID</FormLabel>
                                        <TextField
                                            id="standard-full-width"
                                            // label="Employee ID"
                                            className='textfiledBx labeIp-user'
                                            placeholder="Enter Employee ID"
                                            value={lillyId}
                                            fullWidth
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={4} className='txtCntrSm'>
                                        <FormLabel >First Name</FormLabel>
                                        <TextField
                                            id="standard-full-width"
                                            // label="First Name"
                                            className='textfiledBx labeIp-user'
                                            placeholder="Enter First Name"
                                            fullWidth
                                            value={name}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={4} className='txtCntrSm'>
                                        <FormLabel >Last Name</FormLabel>
                                        <TextField
                                            id="standard-full-width"
                                            // label="Last Name"
                                            className='textfiledBx labeIp-user'
                                            placeholder="Enter Last Name"
                                            fullWidth
                                            value={lastName}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={4} className='txtCntrSm'>
                                        <FormLabel >Title</FormLabel>
                                        <TextField
                                            id="standard-full-width"
                                            // label="Title"
                                            className='textfiledBx labeIp-user'
                                            placeholder="Enter Title"
                                            fullWidth
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={4} className='txtCntrSm'>
                                        <FormLabel >Email</FormLabel>
                                        <TextField
                                            id="standard-full-width"
                                            // label="Email"
                                            className='textfiledBx labeIp-user'
                                            placeholder="Enter Email"
                                            fullWidth
                                            value={email}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={4} className='txtCntrSm'>
                                        <FormLabel >Country</FormLabel>
                                        <TextField
                                            id="standard-full-width"
                                            // label="Country"
                                            className='textfiledBx labeIp-user'
                                            placeholder="Enter Country"
                                            fullWidth
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={4} className='txtCntrSm'>
                                        <FormLabel>Language</FormLabel>
                                        <TextField
                                            id="standard-full-width"
                                            // label="Language"
                                            className='textfiledBx labeIp-user'
                                            placeholder="Enter Language"
                                            fullWidth
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={true}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid>
                                    <label className='listhd'>Roles</label>
                                    <ul className='country_list'>
                                        {roles.length > 0 ? roles.map((role) => {
                                            return <li className='country_list_inner' key={role.role_id}><FormControlLabel
                                                value={role.role_name}
                                                className='labelTxt-user'
                                                control={<Checkbox onChange={(event) => {
                                                    AlterRolesState(role.role_id);
                                                }}
                                                    checked={selectedRoles.includes(role.role_id)}
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }} />}
                                                label={role.role_name}
                                                labelPlacement="end"
                                            />
                                            </li>
                                        }) : ('')}


                                    </ul>
                                </Grid>
                                {hideshowcountry &&

                                    <Grid>

                                        <label className='listhd'>Publish in</label>
                                        <ul className='country_list'>
                                            {hubs.length > 0 ? hubs.map((hub) => {
                                                return (<Fragment key={hub.hub_id}>
                                                    <Grid className='hubs'>
                                                        <li className='country_list_inner'>
                                                            <FormControlLabel
                                                                value={hub.hub_name}
                                                                className='labelTxt-user'
                                                                checked={CheckHubs(hub.hub_id)}
                                                                control={<Checkbox onChange={(event) => {
                                                                    
                                                                    AlterParentAffiliateState(hubAffiliateDict[hub.hub_id], hub.hub_id, hubCheckbox[hub.hub_id], event);
                                                                }}
                                                                    inputProps={{ 'aria-label': 'secondary checkbox' }} />}
                                                                label={hub.hub_name}
                                                                labelPlacement="end"
                                                            />
                                                        </li>
                                                    </Grid>
                                                    <Grid className='affiliates'>
                                                        {Object.values(hubAffiliateDict[hub.hub_id]).length > 0 ? Object.values(hubAffiliateDict[hub.hub_id]).map((affiliate) => {
                                                            
                                                            return <li className='country_list_inner' key={affiliate.country_code}>
                                                                <FormControlLabel
                                                                    value={affiliate.country_name}
                                                                    className='labelTxt-user'
                                                                    checked={selectedAffiliates.includes(affiliate.country_code)}
                                                                    // Checked={true}
                                                                    control={<Checkbox onChange={(event) => {
                                                                       
                                                                        AlterAffiliateState(affiliate.country_code, hub);
                                                                    }}
                                                                        inputProps={{ 'aria-label': 'secondary checkbox' }} />}
                                                                    label={affiliate.country_name}
                                                                    labelPlacement="end"
                                                                />
                                                            </li>
                                                        }) : ('')
                                                        }
                                                    </Grid></Fragment>)
                                            }) : ('')}


                                        </ul>

                                    </Grid>
                                }

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={12} className='footer'>
                        <Grid item xs={12} lg={6}>
                            <button className='cancelBtn' onClick={() => cancelButton()}>Cancel</button>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <button type='submit' className='submitBtn' onClick={handleSubmit}>Submit</button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid> : ('')
    );
}

export default UserProfile;
