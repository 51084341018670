/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import UploadBox from './UploadBox';
import { CountryService } from '../Services/country-service';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`${index}`} aria-labelledby={`${index}`} {...other} className='pdl-0'>
      {value === index && (<div className="tabPanelWrapper">{children}</div>)}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function UploadTabs({ device_type, bannersDict, SetBannersDict, therapeuticAreaMap,
  fixedMenus, SetBannerMap, bannerMap, SetFixedMenus, SetDictionaryOrder, dictionaryOrder, taBannerOrderDict,
  SeturltextPopup, SeturltextPopupBool,
  SettaBannerOrderDict, countryUrlValidationDict, SetcountryUrlValidationDict, countryBannerValidationDict, SetcountryBannerValidationDict, SetcountryBannerValidationBool }) {

  const [tabValue, setTabValue] = React.useState(false);
  const [chipData, setChipData] = React.useState([]);
  const [orderList, SetOrderList] = React.useState([0, 1, 2, 3]);
  const [startingTab, SetStartingTab] = React.useState(0);
  const [draggingELement, SetDraggingElement] = React.useState('');
  const [initialRunCheck, SetInitialRunCheck] = React.useState(true);
  /**Check on therpatic area if data exists */
  const touchItem = useRef();
  const touchOverItem = useRef();
  React.useEffect(() => {
    let list = [];
    list.push({
      label: 'Home',
      key: 'TA0',
      status: true,
      id: 'TA0'
    });
    for (let i = 0; i < fixedMenus.length; i++) {
      if (typeof fixedMenus[i] == 'number' || true) {
        let ta = therapeuticAreaMap[fixedMenus[i]];
        if (ta) {
          list.push({
            label: ta.therapeutic_area_name,
            key: ta.therapeutic_area_id,
            status: true,
            id: fixedMenus[i]
          });
        }
      }
    }
    setChipData(list);
  }, [fixedMenus, therapeuticAreaMap]);
  React.useEffect(() => {
    if (Array.isArray(dictionaryOrder)) {
      if (dictionaryOrder.length > 0) {
        SetOrderList(dictionaryOrder);
      }
    }
  }, [dictionaryOrder])
  React.useEffect(() => {
    if (bannerMap) {
      if (Object.keys(bannerMap).length > 0) {
        if (initialRunCheck) {
          setTabValue(Object.keys(bannerMap)[0]);
          SetInitialRunCheck(false)
        }
      }
    }
  }, [bannerMap]);
  React.useEffect(() => {
    SetDictionaryOrder(orderList);
    if (taBannerOrderDict) {
      let taDict = Object.assign({}, taBannerOrderDict);
      taDict[tabValue] = orderList;
      SettaBannerOrderDict(taDict);
    }
  }, [orderList])
  React.useEffect(() => {
    let list = [];
    let startDictTAOrderMap = Object.assign({}, taBannerOrderDict);
    for (let i = 0; i < fixedMenus.length; i++) {
      let ta = therapeuticAreaMap[fixedMenus[i]];
      if (ta) {
        startDictTAOrderMap[ta.therapeutic_area_id] = orderList;
        list.push({
          label: ta.therapeutic_area_name,
          key: i,
          status: true
        });
      }
    }
    setChipData(list);
  }, []);
  React.useEffect(() => {
    let countryService = new CountryService();
    Promise.all([
      countryService.GetVideosData(),
      countryService.GetPlaylistData()
    ]).then(([videos, playlists]) => {
      let allpromiseData = [];
      let chipDataSet = [];
      if (videos.length > 0 || playlists.length > 0) {
        if (videos.length > 0) {
          allpromiseData = [...videos];
        }
        if (playlists.length > 0) {
          allpromiseData = [...playlists];
        }
        const unique = [...new Set(allpromiseData.map(item => item.category_id))];
        unique.forEach((elem, index) => {
          chipDataSet.push({
            key: index,
            label: elem,
            status: true
          });
        })
      }
    }).catch((err) => {
    });
  }, [setChipData]);
  const dragStart = (e, number, element) => {
    SetStartingTab(number);
    SetDraggingElement(element)
  }
  const dragend = (e, number) => {
  }
  const dragover = (e, number) => {
    e.preventDefault();
    return false;
  }
  const dragenter = (e, number) => {
    
  }
  const dragleave = (e, number) => {
  }
  const dragMove = (e, number) => {
  }
  const touchStart = (e, position) => {
    touchItem.current = position;
  };
  const touchMove = (e, position) => {
    // what elemet are we over
    var element = document.elementFromPoint(
      e.touches[0].clientX,
      e.touches[0].clientY
    );
    if (!isNaN(parseInt((element.className).match(/\d+/)[0], 10) )) {
      touchOverItem.current = parseInt((element.className).match(/\d+/)[0], 10) ;
    }
  };
  const touchEnd = (e, position) => {
    if(touchOverItem.current){

      e.stopPropagation();
      let newArrray = SwipeArrayLocations(touchItem.current, touchOverItem.current);
      SetOrderList(newArrray);
      touchItem.current = null;
      touchOverItem.current = null;
      return false;
    }
    // setList(copyListItems);
  };
  const drop = (e, number, setterState) => {
    e.stopPropagation();
    let newArrray = SwipeArrayLocations(startingTab, number);
    setterState(newArrray);
    return false;
  }
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    let dictionaryMaping = Object.assign({}, bannerMap);
    if (!(newValue in bannerMap)) {
      let subDict = {};
      subDict[0] = {};
      subDict[1] = {};
      subDict[2] = {};
      subDict[3] = {};
      dictionaryMaping[newValue] = subDict;
      SetBannerMap(dictionaryMaping)
    } else {
      if (taBannerOrderDict) {
        if (Array.isArray(taBannerOrderDict[newValue])) {
          if (taBannerOrderDict[newValue].length > 0) {
            SetOrderList(taBannerOrderDict[newValue])
          }
        } else {
          let newTAOrderDictionary = Object.assign({}, taBannerOrderDict);
          newTAOrderDictionary[newValue] = orderList;
          SettaBannerOrderDict(newTAOrderDictionary);
        }
      }
      for (let i = 0; i < 4; i++) {
        if (!(i in dictionaryMaping[newValue])) {
          dictionaryMaping[newValue][i] = {};
          dictionaryMaping[newValue][i]['displayfile'] = '';
        }
      }
      SetBannerMap(dictionaryMaping);
    }
  };
  const SwipeArrayLocations = (value1, value2) => {
    let list = [];
    for (let i = 0; i < orderList.length; i++) {
      if (orderList[i] === value1) {
        list.push(value2);
      } else if (orderList[i] === value2) {
        list.push(value1);
      } else {
        list.push(orderList[i]);
      }
    }
    return list;
  }

  const GetCheckingConditionValue = (selectedValue) => {
    if (bannerMap[selectedValue]) {
      let flag = false;
      if (bannerMap[selectedValue]) {
        let keys = Object.keys(bannerMap[selectedValue]);
        if (Array.isArray(keys)) {
          if (keys.length > 0) {
            keys.forEach((key) => {
              if (bannerMap[selectedValue][key]?.displayfile?.length > 0 || bannerMap[selectedValue][key]?.file?.length > 0) {
                flag = true;
              }
            })
          }
        }
      }
      return flag;
    } else {
      return false;
    }
  }

  return (
    <div className='tab-root'>
      <Tabs value={tabValue} scrollButtons="auto" variant="scrollable" className='hideScroller'>
        {chipData.map((data) => {
          return (
            <div key={data.key}>
              <div onClick={(e) => { return handleChange(e, data.key) }}>
                <Tab className={(tabValue === data.id ? ' banner_chip_selected' : 'banner_chip_non_selected')} key={data.key} label={data.label} id={data.key} value={data.key} aria-controls={data.key} />
              </div>
              <div>
                {GetCheckingConditionValue(data.key) ? <span className="active">Active</span> : <span className="notactive">Not Active</span>}
              </div>
            </div>
          );
        })}
      </Tabs>
      {chipData.map((data, index) => {
        let tab_id = data.key;
        return (
          <TabPanel key={tab_id} value={tabValue} index={data.key} className="tabPanel">
            <Grid container spacing={2} className="tabPanelInner">
              {orderList.map((number, i) => {
                return (
                  <Grid item xs={12} sm={3} md={3} lg={3} key={i} className="tabBox">
                    <div draggable="true" className={i + " box "}
                      onDragStart={(e) => { return dragStart(e, number, 'tab'); }}
                      onDragEnd={(e) => { return dragend(e, number) }}
                      onDragEnter={(e) => { return dragenter(e, number) }}
                      onDragOver={(e) => { return dragover(e, number) }}
                      onDragLeave={(e) => { return dragleave(e, number) }}

                      onDrop={(e) => { if (draggingELement === 'tab') { return drop(e, number, SetOrderList) } }}>
                      <UploadBox tab_id={tab_id} banner_id={number} device_type={device_type}
                        bannersDict={bannersDict} SetBannersDict={SetBannersDict} SetBannerMap={SetBannerMap}
                        bannerMap={bannerMap} selectedTavalue={tabValue}
                        countryUrlValidationDict={countryUrlValidationDict} SetcountryUrlValidationDict={SetcountryUrlValidationDict}
                        countryBannerValidationDict={countryBannerValidationDict} SetcountryBannerValidationDict={SetcountryBannerValidationDict}
                        SeturltextPopup={SeturltextPopup} SeturltextPopupBool={SeturltextPopupBool} SetcountryBannerValidationBool={SetcountryBannerValidationBool} />
                    </div>
                  </Grid>
                )
              })}
            </Grid>
          </TabPanel>
        );
      })}
    </div>
  );
}
