import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import '../home.css';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import PIInfo from '../../shared/PIInfo';
import { HomeService } from '../Service/homeService';
import Thumbnail from '../../shared/Thumbnail';
import meetSpeakerIcon from '../../../public/img/meetSpeakerIcon.svg';
import { BlackTriangleShowMore } from '../../shared/BlackTriangle';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const Content = ({ content, slides, filter, newLabelWatchingContent, newLabelWatchedContent, translations }) => {
    const navigate = useNavigate();
    const watchedContent = [...newLabelWatchingContent, ...newLabelWatchedContent];
    const [speakerNames, setSpeakerNames] = useState([]);
    const [popupState, setPopupState] = useState(false);
    const [SpeakerArray, setSpeakerArray] = useState([]);
    const titleElementRef = useRef();
    const speakerNameRef = useRef();
    const isTitleTruncated = titleElementRef.current && titleElementRef.current.scrollHeight > titleElementRef.current.clientHeight;
    const isSpeakerNameTruncated = speakerNameRef.current && speakerNameRef.current.scrollWidth > speakerNameRef.current.clientWidth;

    const menuSearch = (value) => {
        navigate('/speaker-detail/' + value);
    }

    const contentClickHandler = useCallback((content) => {
        const homeService = new HomeService();
        const isPlaylist = content.content_type === 'playlist';
        const contentURL = isPlaylist 
            ? `/content-watch/${content.kaltura_id}-${content.playlist_id}/${content.playlist_id}` 
            : `/content-watch/${content.kaltura_id}/${content.content_id}`;
        const contentId = isPlaylist ? content.playlist_id : content.content_id;
        homeService.VideoSelectedGAEvent(contentId, content.title);
        homeService.NavigatedToVideoEvent(contentId, 'Navigated to video from homepage', filter);
        navigate(contentURL);
    }, [filter, navigate]);

    useEffect(() => {
        let speaker = content?.speakers_id || [];
        let list = [];
        
        for (let i = 0; i < speaker.length; i++) {
            if (speaker[i]) {
                list.push(speaker[i]);
            }
        }
      
        setSpeakerArray(list);
    }, [content.speakers_id]);

    const tagTitle = useMemo(() => {
        switch (content.content_type) {
            case 'video':
                return 'Video';
            case 'playlist':
                return 'Playlist';
            case 'podcast':
                return translations['Podcast'] ? translations['Podcast'] : 'Podcast';
            default:
                return '';
        }
    }, [content.content_type, translations]);

    const tagClassName = useMemo(() => {
        switch (content.content_type) {
            case 'video':
                return 'videoTag';
            case 'playlist':
                return 'playlistTag';
            case 'podcast':
                return 'podcastTag';
            default:
                return 'contentTag';
        }
    }, [content.content_type]);

    return (
        <>
            <Dialog open={popupState} scroll='body' maxWidth='md' TransitionComponent={Transition} onClose={() => setPopupState(false)} disableEscapeKeyDown className='speakerModal'>
                <DialogTitle className='modal-top'>
                    <Grid className="modal-title border0 alignCenter">
                        <img src={meetSpeakerIcon} alt="speakerIcon" />
                        <button className='closeModal' onClick={() => setPopupState(false)}></button>
                    </Grid>
                </DialogTitle>
                <DialogContent className='modal-middle'>
                    <Grid>
                        <Paper className='speakerTitle'> {translations['Speakers:'] ? translations['Speakers:'] : 'Speakers:'}</Paper>
                        {speakerNames.length > 0 ? speakerNames.map((speaker, index) => (
                            <Paper className='upNameSpeaker' key={index} onClick={() => menuSearch(speaker.toString())}>{speaker}</Paper>
                        )) : null}
                    </Grid>
                </DialogContent>
                <DialogActions className='modal-bottom text-center border0'>
                    <button type="button" className="modalBtn" onClick={() => setPopupState(false)}>{translations['Close'] ? translations['Close'] : 'Close'}</button>
                </DialogActions>
            </Dialog>
            <Grid id="contentElementId" className={slides === 3 ? 'sOuterlg' : 'sOuter'}>
                <Thumbnail content={content} onClick={() => contentClickHandler(content)} showNewLabel={true} watchedContent={watchedContent} />
                <Grid container>
                    <Grid item xs={12} lg={12}>
                        <Paper className={tagClassName} onClick={() => contentClickHandler(content)}>{tagTitle}</Paper>
                    </Grid>
                </Grid>
                <Tooltip title={isTitleTruncated ? content.title : ''} placement='bottom'>
                    <Paper ref={titleElementRef} className='cont' onClick={() => contentClickHandler(content)}><BlackTriangleShowMore title={content != null ? content.title : 'NA'} /></Paper>
                </Tooltip>
                {slides === 3 ?
                    <Paper className='contDate'>
                        {/* {props.content != null ? (new Date(props.content.created_at)).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) : ''} */}
                    </Paper> : null}
                <Paper className='upNameOuter'>
                    {SpeakerArray != null && SpeakerArray.length > 0 ?
                        SpeakerArray.length > 1 ? <Grid className='upName' onClick={() => { setSpeakerNames(SpeakerArray); setPopupState(true); }}>{translations['Meet the Speakers'] ? translations['Meet the Speakers'] : 'Meet the Speakers'}</Grid> : <Tooltip title={isSpeakerNameTruncated ? SpeakerArray[0] : ''} placement='bottom'><Paper ref={speakerNameRef} className='upName' onClick={() => menuSearch(SpeakerArray[0])}>{SpeakerArray[0]}</Paper></Tooltip>
                        : null}
                </Paper>
                <Paper className="cont-piinfo-home">
                    {content?.category_1_id?.length ? <PIInfo category1Ids={content.category_1_id} /> : ""}
                </Paper>
            </Grid>
        </>
    );
}

export default Content;