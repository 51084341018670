/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { EventService } from '../Services/event-service';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const ContentSpecialties = (props) => {
  let eventService = new EventService();

  const [specialities, SetSpecialities] = React.useState([]);
  const [specialtyAffiliateIdMap, SetSpecialtyAffiliateIdMap] = React.useState({});
  const [displaySpecialties, SetDisplaySpecialties] = React.useState({});
  const [countryCodes, SetCountryCodes] = React.useState([]);

  React.useEffect(() => {
    eventService.GetAllSpecialities().then((data) => {
      if (Array.isArray(data.data)) {
        let jsondata = data.data;
        let dict = {};
        for (let i = 0; i < jsondata.length; i++) {
          let spec = jsondata[i];
          dict[spec.specialty_id] = spec;
        }
        SetSpecialities(jsondata);
      }
    }).catch((err) => {});
  }, []);

  React.useEffect(() => {
    let country_codes = [];
    for (let i = 0; i < props.affiliates.length; i++) {
      country_codes.push(props.affiliates[i].country_code);
    }
    SetCountryCodes(country_codes);

    let dict = Object.assign({}, specialtyAffiliateIdMap);
    let displaySpecialtyDict = Object.assign({}, displaySpecialties);
    for (let i = 0; i < props.affiliates.length; i++) {
      let affiliate = props.affiliates[i];
      if (!dict.hasOwnProperty(affiliate.country_code)) {
        dict[affiliate.country_code] = []
        for (let k = 0; k < specialities.length; k++) {
          let curentSpecialty = specialities[k];
          if (curentSpecialty.country_code === affiliate.country_code) {
            (dict[affiliate.country_code]).push(curentSpecialty);
          }
        }
      }
      if (!displaySpecialtyDict.hasOwnProperty(affiliate.country_code)) {
        displaySpecialtyDict[affiliate.country_code] = [];
        for (let i = 0; i < props.specialties.length; i++) {
          displaySpecialtyDict[props.specialties[i]['country_code']] = [];
          for (let j = 0; j < specialities.length; j++) {
            if (specialities[j].country_code === props.specialties[i]['country_code']) {
              if (props.specialties[i]['value'].includes(specialities[j].specialty_id)) {
                displaySpecialtyDict[props.specialties[i]['country_code']].push(specialities[j]);
              }
            }
          }
        }
      }
    }
    SetSpecialtyAffiliateIdMap(dict);
    let arr = {}
    if (props.specialties && props.specialties.length == 0) {

      for (let i = 0; i < props.affiliates.length; i++) {
        arr[props.affiliates[i].country_code] = [];
      }
      SetDisplaySpecialties(arr);

    } else {
      SetDisplaySpecialties(displaySpecialtyDict);
    }
  }, [props.affiliates]);

  const handlerSelect = (values, affiliate_id) => {
    let specialitiesArr = [];
    for (let i = 0; i < values.length; i++) {
      specialitiesArr.push(values[i].specialty_id);
    }
    let oldSpecialities = [...props.specialties];
    if (oldSpecialities.length > 0) {
      for (let i = 0; i < oldSpecialities.length; i++) {
        if (oldSpecialities[i].country_code === affiliate_id) {
          oldSpecialities.splice(i, 1);
        }
        if (typeof oldSpecialities[i] !== 'undefined') {
          if (!countryCodes.includes(oldSpecialities[i].country_code)) {
            oldSpecialities.splice(i, 1);
          }
        }
      }
    }
    oldSpecialities.push({ country_code: affiliate_id, value: specialitiesArr })
    let array = [];
    let dict = {};
    for(let i = 0 ; i <props.affiliates.length; i++){
      dict[props.affiliates[i].country_code] = [];

    }
    for (let i = 0; i < oldSpecialities.length; i++) {

      if (oldSpecialities[i].value.length > 0) {
        array.push(oldSpecialities[i])

        for (let j = 0; j < specialities.length; j++) {
          if (specialities[j].country_code === oldSpecialities[i].country_code) {
            if (oldSpecialities[i].value.includes(specialities[j].specialty_id)) {
              dict[oldSpecialities[i].country_code].push(specialities[j]);
            }
          }
        }
      }
    }
    props.SetSpecialties(array);
    SetDisplaySpecialties(dict);

  }

  return (
    <>
      {props.affiliates?.length > 0 ?
        <Grid container className="content white_row">
          <Grid item lg={12} xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Restricted to specialties (Public by default)</FormLabel>
              <div className='ml20 tableData'>
                {props.affiliates.length > 0 ? props.affiliates.map((item, k) => (
                  <Grid container key={item.country_code}>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                      {item.country_name}
                    </Grid>
                    <Grid item lg={9} md={9} sm={9} xs={12}>
                      <FormControl variant="outlined" className='formControl select' size="small">

                        {Array.isArray(displaySpecialties[item.country_code]) ?
                          Array.isArray(specialtyAffiliateIdMap[item.country_code]) ?
                            <Autocomplete
                              multiple
                              size='small'
                              id="tags-standard"
                              options={specialtyAffiliateIdMap[item.country_code]}
                              getOptionLabel={(option) => option.specialty_name}
                              defaultValue={displaySpecialties[item.country_code]}
                              value={displaySpecialties[item.country_code]}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Select Speciality"
                                />
                              )}
                              onChange={(event, newValue) => { handlerSelect(newValue, item.country_code); }}
                            />
                            : ""
                          : ('No Speciality added')}

                      </FormControl>
                    </Grid>
                  </Grid>
                )) : ""}
              </div>
            </FormControl>
          </Grid>
        </Grid>
        : ('')}
    </>
    
  )
}
export default ContentSpecialties