/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { SpeakerService } from '../Services/speaker-service';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { HomeService } from '../../../home/Service/homeService'
import { FormHelperText, Grid } from '@material-ui/core';
import { ContentService } from '../../Service/content-service';
import { useForm } from 'react-hook-form';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
const SpeakerCreate = (props) => {
    let speakerService = new SpeakerService();
    let contentService = new ContentService();
    const [s3UploadCheck, SetS3UploadCheck] = React.useState(false);
    const [imageSizeCheck, SetImageSizeCheck] = React.useState(false);
    const [imageTypeCheck, SetImageTypeCheck] = React.useState(false);
    const [s3UploadErrorCheck, SetS3UploadErrorCheck] = React.useState(false);
    const [imageDimensionCheck, SetImageDimensionCheck] = React.useState(false);
    const [speakerPhoto, SetSpeakerPhoto] = React.useState('');
    const [isDisabled, setIsDisabled] = React.useState(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            speaker_id: 0,
            speaker_photo: '',
            speaker_name: '',
            speaker_curriculum: '',
            speaker_hospital: '',
            speaker_url: ''
        },
        mode: 'onChange',
    });

    React.useEffect(() => {
        if (props.speakerID) {
            speakerService.GetSpeakerByQueryCreateEdit({ speakerID: props.speakerID }).then((data) => {
                let speakers = data.data;
                if (speakers.length > 0) {
                    let speaker = speakers[0];
                    (new HomeService()).GetImageFromURL(speaker.avatar_attachment_id).then((data) => {
                        setImgPreview(data);
                    });
                    reset({
                        speaker_id: props.speakerID,
                        speaker_name: speaker.name,
                        speaker_curriculum: speaker.curriculum,
                        speaker_hospital: speaker.hospital,
                        speaker_url: speaker.url
                    });
                }
            }).catch((err) => {
            })
        }
    }, []);

    const onSubmit = (data) => {
        setIsDisabled(true);
        if (speakerPhoto) { // while update speaker_photo key will be added if image updated
            data = { ...data, ['speaker_photo']: speakerPhoto }
        }
        speakerService.UpdateSpeaker(data).then((data) => {
            props.closePopUp(false);
            props.reloadData();
        }).catch((err) => { })
    }

    // Image Upload Preview 
    const [imgPreview, setImgPreview] = React.useState('');
    const imgPreviewHandler = (file) => {

        SetS3UploadCheck(false);
        SetImageDimensionCheck(false);
        SetImageSizeCheck(false);
        SetImageTypeCheck(false);
        SetS3UploadErrorCheck(false);

        if (file) {
            //Check for file size - 100KB 
            let imageByteSize = file.size;
            if (imageByteSize > 102400) { //100KB Limit
                SetS3UploadCheck(true);
                SetImageSizeCheck(true);
                return;
            } else {
                SetImageSizeCheck(false);
            }

            //Check for file type
            let imageType = file.type;
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];
            if (!validImageTypes.includes(imageType)) {
                SetS3UploadCheck(true);
                SetImageTypeCheck(true);
                return;
            } else {
                SetImageTypeCheck(false);
            }

            //speakerService.LoadFile(file, file.name);
            const reader = new FileReader();
            let key = 'Country/Banner/' + (new Date()).getTime().toString() + '-' + file.name;
            reader.onload = () => {
                let image = new Image();
                image.onload = function () {
                    let width = this.width;
                    let height = this.height;
                    if (!(width === 1280 && height === 720)) {
                        SetS3UploadCheck(true);
                        SetImageDimensionCheck(true);
                    } else {
                        speakerService.UploadFileToS3(key, reader.result).then((data) => {
                            SetSpeakerPhoto(key);
                            setImgPreview(reader.result);
                        }).catch(async (err) => {
                            let errorObj = {
                                user_id: props.created_by,
                                thumbnail_name: key,
                                log_event_type: "Speaker",
                                thumbnail_size: imageByteSize,
                                thumbnail_height: height,
                                thumbnail_width: width,
                                thumbnail_type: imageType,
                                record_id: props.id,
                                error_code: err.status ? err.status : '',
                                error_message: err.message
                            }
                            try {
                                await contentService.UpdateS3ImagesErrorLogs(errorObj);
                            } catch (saveerr) { }
                            SetS3UploadCheck(true);
                            SetS3UploadErrorCheck(true);
                        });
                    }
                }
                image.src = reader.result;
            }
            reader.readAsDataURL(file);
        }
    }

    const [open, setOpen] = React.useState(true);

    const handleClose = (event, reason) => {
        if (reason === "backdropClick") {
            handleBackdropClick(event);
        } else {
            setOpen(false); props.closePopUp(false);
        }
    };

    const handleBackdropClick = (event) => {
        event.stopPropagation();
        return false;
    };

    return (
        <Dialog open={open}
            scroll='body'
            maxWidth='sm'
            TransitionComponent={Transition}
            className='speaker-modal'
            onClose={handleClose}
            disableEscapeKeyDown
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle className='modal-title-2'>
                    {props.speakerID ? 'Speaker Modify:' : 'Create New Speaker:'}
                    <button className='closeModal' onClick={() => props.closePopUp(false)}></button>
                </DialogTitle>
                <DialogContent className='modal-body'>
                    <FormControl component="fieldset" className='formControl' error={s3UploadCheck}>
                        <input
                            accept="image/*"
                            className='hidden'
                            id="contained-button-file"
                            name="speaker_photo"
                            type="file"
                            onChange={(event) => { imgPreviewHandler(event.target.files[0]) }}
                        />
                        <label htmlFor="contained-button-file">
                            <Button className="speaker_upload_btn" variant="contained" color="primary" component="span">
                                {(imgPreview) ? <div className="text-center p-3"><img src={imgPreview} alt="imgpreview" style={{ minHeight: "210px", maxWidth: "200px" }} /></div> : 'Upload Speaker Photo'}
                            </Button>
                        </label>
                        {(s3UploadCheck && imageSizeCheck) && <FormHelperText>The thumbnail you selected does not meet the standard of file size. The file is too large. Please, select another thumbnail which size is less than 100KB</FormHelperText>}
                        {(s3UploadCheck && imageTypeCheck) && <FormHelperText>The thumbnail you selected does not meet the standard of thumbnail format. The file format is not allowed by system. Please, select another thumbnail which formats are: .jpg, .jpeg, .png.</FormHelperText>}
                        {(s3UploadCheck && imageDimensionCheck) && <FormHelperText>The thumbnail you selected does not meet the standard of thumbnail dimensions. The file is not of correct dimension. Please, select another thumbnail which dimensions are 1280x720px</FormHelperText>}
                        {(s3UploadCheck && s3UploadErrorCheck) && <FormHelperText>The thumbnail you selected could not be uploaded. Please, try again later. If you submit now, Lilly Play will use the default thumbnail.</FormHelperText>}
                    </FormControl>
                    <FormControl component="fieldset" className='formControl'>
                        <FormLabel htmlFor='speaker_name' className='font-weight-bold' required>Speaker Name</FormLabel>
                        <TextField variant="outlined" placeholder="Speaker Name" size="small" error={!!errors.speaker_name} helperText={!!errors.speaker_name && 'Speaker Name is required.'} {...register('speaker_name', { required: true })} />
                    </FormControl>
                    <FormControl component="fieldset" className='formControl'>
                        <FormLabel htmlFor='speaker_curriculum' className='font-weight-bold' required>Curriculum vitae</FormLabel>
                        <TextField variant="outlined" placeholder="Speaker curriculum" size="small" multiline minRows={3} error={!!errors.speaker_curriculum} helperText={!!errors.speaker_curriculum && 'Curriculum vitae is required.'} {...register('speaker_curriculum', { required: true })} />
                    </FormControl>
                    <FormControl component="fieldset" className='formControl'>
                        <FormLabel htmlFor='speaker_hospital' className='font-weight-bold'>Workplace</FormLabel>
                        <TextField variant="outlined" placeholder="Speaker workplace" size="small" {...register('speaker_hospital')} />
                    </FormControl>
                    <FormControl component="fieldset" className='formControl'>
                        <FormLabel htmlFor='speaker_url' className='font-weight-bold'>Website</FormLabel>
                        <TextField variant="outlined" placeholder="Speaker url" size="small" {...register('speaker_url')} />
                    </FormControl>
                </DialogContent>
                <DialogActions className='modal-footer'>
                    <button type="button" className="modalBtn" onClick={() => { props.closePopUp(false); }}>Cancel</button>
                    <button type="submit" className="modalBtn" disabled={isDisabled}>OK</button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
export default SpeakerCreate;






