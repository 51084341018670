import React, { forwardRef } from 'react';
import {
  FormHelperText,
  FormLabel,
  Grid,
  FormControlLabel,
  FormControl,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { userCreateService } from '../Services/user-create-service';
import { Controller, useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  ulist: {
    listStyle: 'none',
    paddingLeft: '0px',
    marginLeft: '20px',
    width: '100%',
  },
  ulistInner: {
    display: 'inline-block',
    width: '25%',
  },
}));

const UserCreate = forwardRef((props, ref) => {
  const classes = useStyles();
  const [roles, SetRoles] = React.useState([]);
  const [disabled, setDisabled] = React.useState(false);
  let Usercreateservice = new userCreateService();

  React.useEffect(() => {
    /**Function will fetch all the roles that could be associated with user */
    Usercreateservice.GetRolesByQuery()
      .then((data) => {
        SetRoles(data.data);
      })
      .catch((err) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      role_ids: [],
    },
  });
  const nameSegregator = (str) => {
    if (/\s/.test(str)) {
      return {
        first_name: str.substring(0, str.lastIndexOf(' ')),
        last_name: str.substring(str.lastIndexOf(' ') + 1),
      };
    }

    return {
      first_name: str,
    };
  };
  /**Function submits all the data for the created user */
  const onSubmit = (data) => {
    let model = {
      ...data,
      affiliate_code: [],
      octa_id: '',
      customer_id: '',
      user_name: '',
      first_name: '',
      last_name: '',
      global_id: 0,
      specialty: {},
      therapeutic_area: [],
      profession: {},
      city: '',
      avatar_attachment_id: '',
      identifiers: '',
      status_flag: 'Active',
      created_on: new Date(),
      created_by_user_id: 0,
      updated_on: new Date(),
      updated_by_user_id: 0,
      ...nameSegregator(data.user_name),
    };

    Usercreateservice.CreateNewUser(model)
      .then((data) => {
        props.closePopUp(false);
        props.reloadData();
      })
      .catch((err) => {
        console.error('Server Error Recieved', err);
      });
    setDisabled(true);
  };

  return roles.length > 0 ? (
      <form onSubmit={handleSubmit(onSubmit)} ref={ref}>
        <Grid className='paper modalstyle'>
          <Grid className='modal-title-2'>
            Create New User:
            <button
              className='closeModal'
              onClick={() => props.closePopUp(false)}
            ></button>
          </Grid>
          {/**Create User Profile Section */}
          <Grid className='modal-body'>
            <FormControl className='formControl' fullWidth={true}>
              <FormLabel
                htmlFor='lilly_id'
                className='font-weight-bold'
                required
              >
                Lilly System Id
              </FormLabel>
              <TextField
                variant='outlined'
                size='small'
                error={!!errors.lilly_id}
                helperText={!!errors.lilly_id && 'User ID is required.'}
                {...register('lilly_id', { required: true })}
              />
            </FormControl>
            <FormControl
              className='formControl'
              fullWidth={true}
              error={!!errors.user_email}
            >
              <FormLabel
                htmlFor='user_email'
                className='font-weight-bold'
                required
              >
                User Email Address
              </FormLabel>
              <Controller
                name='user_email'
                control={control}
                rules={{
                  required: 'Email is required',
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+\.)?lilly\.com$/,
                    message: 'Enter a valid email address',
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.user_email}
                    variant='outlined'
                    size='small'
                  ></TextField>
                )}
              ></Controller>
              {!!errors.user_email && (
                <FormHelperText>{errors.user_email.message}</FormHelperText>
              )}
            </FormControl>
            <FormControl className='formControl' fullWidth={true}>
              <FormLabel
                htmlFor='user_name'
                className='font-weight-bold'
                required
              >
                User Name
              </FormLabel>
              <TextField
                variant='outlined'
                size='small'
                error={!!errors.user_name}
                helperText={!!errors.user_name && 'User Name is required.'}
                {...register('user_name', { required: true })}
              />
            </FormControl>
            <FormControl className='formControl' error={!!errors.role_ids}>
              <FormLabel
                htmlFor='role_ids'
                className='font-weight-bold'
                required
              >
                Role Ids :
              </FormLabel>
              <ul className={classes.ulist}>
                {roles.length > 0
                  ? roles.slice(1, 4).map((role) => {
                      return (
                        <li className={classes.ulistInner + ' ulistInner'} key={role.role_id}>
                          <Controller
                            name='role_ids'
                            control={control}
                            rules={{ required: role.role_id }}
                            render={({ field }) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    {...field}
                                    value={role.role_id}
                                    checked={field.value.includes(role.role_id)}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        field.onChange([
                                          ...field.value,
                                          role.role_id,
                                        ]);
                                      } else {
                                        field.onChange(
                                          field.value.filter(
                                            (id) => id !== role.role_id
                                          )
                                        );
                                      }
                                    }}
                                  />
                                }
                                label={role.role_name}
                              />
                            )}
                          />
                        </li>
                      );
                    })
                  : ''}
              </ul>
              {!!errors.role_ids && (
                <FormHelperText>Role Id is required.</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid className='modal1FoBtn modal-footer' container justifyContent='flex-end'>
            <button
              type='button'
              className='modalBtn'
              onClick={() => {
                props.closePopUp(false);
              }}
            >
              Cancel
            </button>
            {
              <button type='submit' className='modalBtn' disabled={disabled}>
                OK
              </button>
            }
          </Grid>
          {/**End Create User Profile Section */}
        </Grid>
      </form>
  ) : (
    ''
  );
});
export default UserCreate;
