/* eslint-disable no-sequences */
import React, { useCallback } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import RightArrowIcon from '../../public/img/right-arrow.svg';
import LeftArrowIcon from '../../public/img/left-arrow.svg';
import { useNavigate } from "react-router-dom";
import { BlackTriangleHome } from '../shared/BlackTriangle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Thumbnail from './Thumbnail';
import meetSpeakerIcon from '../../public/img/meetSpeakerIcon.svg';
import PIInfo from './PIInfo';
import { HomeService } from '../home/Service/homeService';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});



const CarouselSlider = (props) => {
    const navigate = useNavigate();
    /** Carousel Next Arrow  */
    const NextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <Grid onClick={onClick}>
                <img src={RightArrowIcon}
                    className={className} style={{
                        ...style, background: '#FFFFFF',
                        border: '1px solid #D52B1D',
                        color: '#D52B1D',
                        borderRadius: '50%',
                        marginTop: '-4%',
                        fontSize: '36px',
                        marginRight: '-1%',
                        padding: '4px 4px 4px 3px',
                        height: '18px',
                        display: 'inline-block',
                    }} alt="" />
            </Grid>
        );
    }
    /** Carousel Previous Arrow  */
    const PrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <Grid onClick={onClick}>
                <img src={LeftArrowIcon}
                    className={className} style={{
                        ...style, background: '#FFFFFF',
                        border: '1px solid #D52B1D',
                        color: '#D52B1D',
                        borderRadius: '50%',
                        marginTop: '-4%',
                        fontSize: '36px',
                        marginLeft: '-1%',
                        padding: '4px 4px 4px 3px',
                        height: '18px',
                        display: 'inline-block',
                    }} alt="" />
            </Grid>
        );
    }
    /** Carousel settings  */
    const settings = {
        dots: false,
        infinite: false,
        speed: 1000,
        slidesToShow: props.slides,
        slidesToScroll: 5,
        initialSlide: 0,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    centerMode: false,
                    variableWidth: true,
                    infinite: false,
                    arrows: true,
                    nextArrow: <NextArrow />,
                    prevArrow: <PrevArrow />,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 0,
                    variableWidth: true,
                    infinite: false,
                    arrows: true,
                    nextArrow: <NextArrow />,
                    prevArrow: <PrevArrow />,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    centerMode: false,
                    variableWidth: true,
                    infinite: false,
                    arrows: true

                }
            }
        ]
    };

    /**Search Filter Sidebar */
    const [speakerNames, setSpeakerNames] = React.useState([]);
    const [popupState, setPopupState] = React.useState(false);
    const menuSearch = (value) => {
        navigate('/speaker-detail/' + value);
    }

    const time = new Date('2022-08-03');
    time.setSeconds(time.getSeconds() + 600); // 10 minutes timer

    const contentClickHandler = useCallback((content) => {
        const homeService = new HomeService();
    
        const isPlaylist = content.content_type === 'playlist';
        const contentURL = isPlaylist 
            ? `/content-watch/${content.kaltura_id}-${content.playlist_id}/${content.playlist_id}` 
            : `/content-watch/${content.kaltura_id}/${content.content_id}`;
    
        const contentId = isPlaylist ? content.playlist_id : content.content_id;
    
        homeService.VideoSelectedGAEvent(contentId, null);
        homeService.NavigatedToVideoEvent(contentId, 'Navigated to video from homepage', props.filter);
        navigate(contentURL);
    }, [props.filter, navigate]);

    
    return (
        /** Carousel Slider Section  */
        <>
            <Dialog open={popupState} scroll='body' maxWidth='md' TransitionComponent={Transition} onClose={() => setPopupState(false)} disableEscapeKeyDown className='speakerModal'>
                <DialogTitle className='modal-top'>
                    <Grid className="modal-title border0 alignCenter">

                        <img src={meetSpeakerIcon} alt="speakerIcon" />

                        <button className='closeModal' onClick={() => setPopupState(false)}></button>
                    </Grid>

                </DialogTitle>
                <DialogContent className='modal-middle'>
                    <Grid>
                        <Paper className='speakerTitle'> {props.translations['Speakers:'] ? props.translations['Speakers:'] : 'Speakers:'}</Paper>

                        {speakerNames.length > 0 ? speakerNames.map((speaker, index) => {
                            return (<Paper className='upNameSpeaker' key={index} onClick={() => menuSearch(speaker.toString())}>{speaker}</Paper>
                            );
                        }) : null}

                    </Grid>
                </DialogContent>
                <DialogActions className='modal-bottom text-center border0'>
                    <button type="button" className="modalBtn" onClick={() => setPopupState(false)}>Close</button>
                </DialogActions>
            </Dialog>
            <Grid className='slickLgSec'>
                <Slider {...settings}>
                    {props.contentData.length > 0 && typeof props.contentData !== 'string' ?
                        props.contentData.map((content, index) => {
                            let contentTitle = content.content_type === 'video' ? content.title :
                                content.content_type === 'playlist' ? content.title :
                                    content.content_type === 'podcast' ? content.title : null;
                            // let contentSpeakers = content.content.speakers_id ? content.speakers_id : [];
                            let contentSpeakers =[];
                            if(content.speakers_id){
                                for(let i = 0; i<content.speakers_id.length; i++){
                                    if(content.speakers_id[i]){
                                        contentSpeakers.push(content.speakers_id[i])
                                    }
                                }
                            }
                            let content_category1 = content.content_type === 'playlist' ? content.category1_id : content.category_1_id;
                            return (
                                <Grid className={props.slides === 3 ? 'sOuterlg' : 'sOuter'} key={index}>
                                    <Thumbnail content={content} onClick={() => contentClickHandler(content)}/>
                                    <Grid container>
                                        <Grid item xs={12} lg={12}>
                                            <Paper className={content.content_type + 'Tag'} onClick={() => contentClickHandler(content)}>{content.content_type}</Paper>
                                        </Grid>
                                    </Grid>
                                    <Paper className='cont' onClick={() => contentClickHandler(content)}>{<BlackTriangleHome title={contentTitle} />}</Paper>
                                    <Paper className='upNameOuter'>

                                        {contentSpeakers != null && contentSpeakers.length > 0 ?
                                            contentSpeakers.length > 1 ? <Grid className='upName' onClick={() => { setSpeakerNames(contentSpeakers); setPopupState(true); }}>{props.translations['Meet the Speakers'] ? props.translations['Meet the Speakers'] : 'Meet the Speakers'}</Grid> : <Paper className='upName' onClick={() => menuSearch(contentSpeakers[0])}>{contentSpeakers[0]}</Paper>
                                            : null}

                                    </Paper>
                                    <Grid className="cont-piinfo">
                                        {content_category1?.length ? <PIInfo category1Ids={content_category1} /> : ""}
                                    </Grid>
                                </Grid>
                            )
                        })
                        : <Grid className='notDataFound'>{props.translations['No Content Found'] ? props.translations['No Content Found'] : 'No Content Found'}</Grid>}
                </Slider >
            </Grid>
        </>
    );
}

export default CarouselSlider;