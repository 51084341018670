import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import './content-show.css';
import Breadcrumb from '../../layout/breadcrumb';
import Paper from '@material-ui/core/Paper';
import { ContentShowService, HomeServiceBaseMatchingModel } from './Service/content-show-service';
import ContentList from '../shared/ContentList/ContentList';
import LiveEvent from '../home/Components/LiveEvent';
import { useSelector } from 'react-redux';
import { UserBrandService } from '../home/Service/userBrandService';
import loaderNewGif from '../../public/img/new-loader.png';
export let util = { loadProducts: null };


const ContentShow = (props) => {
    let contentshowService = new ContentShowService();
    const [videoData, setVideoData] = React.useState([]);
    const [hasMore, setHasmore] = useState(false);
    const [lastPosition, setLastPosition] = useState(5);
    const [startPosition, setStartPosition] = useState(0);
    const [allvideoData, setAllvideoData] = React.useState([]);
    const [showLoader, setShowLoader] = React.useState(true);
    const [LiveEvents, SetLiveEvents] = React.useState([]);
    const [eventListenerTrigger, SetEventListenerTrigger] = React.useState(false);
    let selectorValue = useSelector(state => state.session);
    const [sessionState, SetSessionState] = React.useState(selectorValue);
    const [taIdsArrData, SetTaIdsArrData] = React.useState([]);
    const [brandIdsArrData, SetBrandIdsArrData] = React.useState([]);
    const [indIdsArrData, SetIndIdsArrData] = React.useState([]);
    const [userBrandFilterData, SetUserBrandFilterData] = React.useState(false);
    const[brandCompletion,SetBrandCompletion]= React.useState(false);
    /**Function will load Load Next list of contents on scroll */
    React.useEffect(() => {
        SetSessionState(selectorValue);
    }, [selectorValue])

     useEffect(() => {
       function getUserSelectedBrandData() {
        let userBrandService = new UserBrandService();
       userBrandService.getUserSelectedBrands(sessionState, SetTaIdsArrData, SetBrandIdsArrData, SetIndIdsArrData, SetUserBrandFilterData).then((data)=> {
        SetBrandCompletion(true);
       });
      }
      getUserSelectedBrandData()
    }, [])
    
    util.loadProducts = async () => {
        const paginateData = await contentshowService.getContentListCount(startPosition, lastPosition, props.filterId , props.selectedCountry , props.selectedLibraryid, GetHomeServiceModel());
        if (paginateData.data.length === lastPosition-startPosition +1) {
            const tempData = paginateData.data;
            setVideoData((prev) => [...prev, ...tempData]);
            setLastPosition(lastPosition + 100);
            setStartPosition(lastPosition + 1);
            setHasmore(true);

        } else {
          const tempData = paginateData.data;
            setVideoData((prev) => [...prev, ...tempData]);
            setHasmore(false);
        }
        return true;

    };
    const GetAffiliateCodesArray = () => {
      let list = []
      if (sessionState) {
        if (sessionState.sessionValue.payload) {
          if (Array.isArray(sessionState.sessionValue.payload.affiliate)) {
            let affiliates = sessionState.sessionValue.payload.affiliate
            for (let i = 0; i < affiliates.length; i++) {
              if (affiliates[i]) {
                list.push(affiliates[i].country_code);
              }
            }
          }
        }
      }
      return list;
    }
    const GetHomeServiceModel = (specialty = '', profession = '') => {
        let countryName = localStorage.getItem('country') ?? '';
        let localeName = localStorage.getItem('lang') ?? '';
        let loginRoute = ''
        if (sessionState) {
          if (sessionState.sessionValue.payload) {
            loginRoute = sessionState.sessionValue.payload.loginroute;
            if (sessionState.sessionValue.payload.decodedToken) {
              if (sessionState.sessionValue.payload.decodedToken) {
                let _spec = sessionState.sessionValue.payload.decodedToken.specialty?sessionState.sessionValue.payload.decodedToken.specialty:"";
                let _prof = sessionState.sessionValue.payload.decodedToken.profession?sessionState.sessionValue.payload.decodedToken.profession:"";
                let taArray = [];
                if(props.filterId.includes(',')){
                  taArray = props.filterId.split(',');
                }else{
                  taArray.push(props.filterId);
                }
                if(sessionState?.sessionValue?.payload?.user?.language){
                  localeName = sessionState?.sessionValue?.payload?.user?.language;
                  if(countryName == 'Switzerland'){
                      if(localeName == 'de-CH'){
                        localeName = 'German'
                      }else if(localeName == 'fr_CH'){
                        localeName = 'French'
                      }
                  }else{
                    if(localeName == 'nl-BE'){
                      localeName = 'Dutch'
                    }else if(localeName == 'fr-BE'){
                      localeName = 'French'
                    }
                  }
                }
                let affiliateArray = GetAffiliateCodesArray();
            let affiliateValue = Array.isArray(affiliateArray) && affiliateArray.length > 0 ? affiliateArray : props.selectedCountry;
                let model = new HomeServiceBaseMatchingModel(affiliateValue, props.selectedLibraryid, _spec, _prof, taArray, 'Active',brandIdsArrData,indIdsArrData, countryName, localeName, loginRoute);
                return model;
              }
            }
          }
        }
        let taFilter = props.filterId;
        if(typeof taFilter == 'string'){
          taFilter = taFilter.split(',')
        }
        let affiliateArray = GetAffiliateCodesArray();
            let affiliateValue = Array.isArray(affiliateArray) && affiliateArray.length > 0 ? affiliateArray : props.selectedCountry;
        let model = new HomeServiceBaseMatchingModel(affiliateValue, props.selectedLibraryid, specialty, profession, taFilter, 'Active', brandIdsArrData,indIdsArrData, '', countryName, localeName, loginRoute);
        return model;
      }

    util.fetchList = () => {
        setShowLoader(true);
        contentshowService.getContentListCount(startPosition, lastPosition, props.filterId, props.selectedCountry , props.selectedLibraryid, GetHomeServiceModel()).then(resp => {
            if (resp.data?.length > 0) {
                let tempData = (resp.data);
                setHasmore(true);
                setVideoData(tempData);
                setLastPosition(lastPosition + 101);
                setStartPosition(lastPosition+1);
            }
        }).catch(err => {  });
        contentshowService.getContentList(props.filterId, props.selectedCountry , props.selectedLibraryid, GetHomeServiceModel()).then(resp => {
            if (resp.data?.length > 0) {
                setAllvideoData(resp.data);
            }
            setShowLoader(false);
        }).catch(err => { });
        return true;
    }
    const getLiveEvent = () =>{
        if(props.filterId !== ''){
            contentshowService.GetLiveEvents(props.filterId,props.selectedCountry,props.selectedLibraryid).then((liveEventsData) => {
                SetLiveEvents(liveEventsData);
              }).catch((err) => {  })
        }else{
            SetLiveEvents([]);
        }
    } 
    const ScrollingTrigger = ()=> {
      let triggerHeigth = parseInt(parseInt(document.documentElement.scrollHeight) * 0.8);
      if(window.scrollY > triggerHeigth){
        if(hasMore){
        }
        
      }
    }
    useEffect(() => {
  }, [eventListenerTrigger, startPosition, lastPosition, hasMore]);
    useEffect(() => {
        if(props.filterId !== ''){
            window.scrollTo(0,0);
            setLastPosition(100);
            setStartPosition(0);
            if(brandCompletion){
              util.fetchList();
            }
            getLiveEvent()    
        }else{
            setShowLoader(false);
            SetLiveEvents([]);
            setAllvideoData([])
            setVideoData([]);
        }
    }, [props.filterId, sessionState, brandCompletion]);
    
    /**Breadcrumb for content list section */
    const BreadcrumbData = [
        { value: props.translations['Home'] ? props.translations['Home'] : 'Home', path: '/home' },
        { value: props.translations['Most Recent'] ? props.translations['Most Recent'] : 'Most Recent Contents', path: '/content-show' },
    ]
    return (
        <Grid>
            <Breadcrumb translations={props.translations} setFilter={props.setFilter} BreadcrumbData={BreadcrumbData} filter={props.filter} />
            <Grid className='outer-wrapper'>
                <Paper className='topTag'>{props.translations['Most Recent Contents'] ? `${props.translations['Most Recent Contents']}` : 'Most Recent Contents'}
                {
                  !showLoader && (
                    <>
                      <span className='verticalLine'>|</span> <span>{parseInt(allvideoData.length) + parseInt(LiveEvents.length)} {parseInt(allvideoData.length) + parseInt(LiveEvents.length) < 2 && (props.selectedCountry === 'BR' || props.selectedCountry === 'FR') ? props.translations['content found'] ? props.translations['content found'] : 'content found' : props.translations['contents found'] ? props.translations['contents found'] : 'contents found' }</span> 
                    </>
                  )
                } 
                </Paper>
                {LiveEvents.length > 0 ? LiveEvents.map((eventData, index) => {
                  return (<LiveEvent event={eventData} translations={props.translations} key={index}/>)
                }) : null}
                {
                  showLoader ? <Grid className='text-center'><img src={loaderNewGif} className='loadSvg rotate' alt='icon' /></Grid> : 
                  <ContentList
                      breadcrumbData={BreadcrumbData}
                      contentDataCount={allvideoData.length}
                      contentData={videoData}
                      loadProducts={()=> {
                        util.loadProducts()
                      }}
                      hasMore={hasMore}
                      showLoader={showLoader}
                      translations={props.translations}
                      filter={props.filter}
                  />
                }
            </Grid>
        </Grid>
    );
}

export default ContentShow;
