/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useNavigate } from "react-router-dom";
import TherapeuticArea from '../Components/TherapeuticArea';
import { useSelector, useDispatch } from 'react-redux';
import '../user.css';
import ModalDialog from '../Components/ModalDialog';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { UserProfileService } from '../Services/user-profile-service';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Modal from '@material-ui/core/Modal';
import Alert from '@material-ui/lab/Alert';
import countryListJson from '../../../CountryPassport/CountryPassport.json';
import { SessionService } from '../../login/Services/SessionService';
import { setSession } from '../../../Session/sessionSlice';
import * as jose from  'jose';
import {useQuery } from 'react-query'

import axios from 'axios';
// import { getPassportToken, getSSOToken } from '../../login/Services/login-service';

const IntUserProfile = (props) => {
    const [jsonObj, SetjsonObj] = React.useState(countryListJson);
    function getCode(country_name){
        return country_name!=undefined ? jsonObj.find(o => o.country_code === country_name) : '';
    }

    let userProfileService = new UserProfileService();
    const modalFunc = React.useRef(null);
    const [token, SetToken] = React.useState();
    const [title, SetTitle] = React.useState('');
    const [language, SetLang] = React.useState({});
    const [languageList, SetLangList] = React.useState([]);
    const [exceptionPassportCountries, SetExceptionPassportCountries] = React.useState(["Australia", "New Zealand"]);
    const [country, SetCountry] = React.useState('');
    const [selectedCategories, SetSelectedCategories] = React.useState([]);
    const [roleHCP, setHcpRole] = React.useState(false);
    const [UserDataTA, setUserDataTA] = React.useState([]);
    const [UserData, setUserData] = React.useState([]);
    const [isExistingUser, setIsExistingUser] = React.useState(false);
    const [isPassportLogin, setIsPassportLogin] = React.useState(false);
    const [cancelPopup, SetcancelPopup] = React.useState(false);
    const [isTherapeuticDirty, SetIsTherapeuticDirty] = React.useState(false);
    let passportLang = localStorage.getItem("passportLanguage");
    const currentStore = useSelector(state => state.session);
    const [userSession, SetuserSession] = React.useState(currentStore);
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [formData, setFormData] = React.useState({
        user_id: "",
        user_photo: '',
        user_fname: '',
        user_lname: '',
        user_title: '',
        user_email: '',
        user_country: '',
        user_lang: '',
        user_role: ''
    });

    let selectorValue = useSelector(state => state.session);
    const translationReduxState = useSelector(state => state.translaton);
    const [sessionState, SetSessionState] = React.useState(selectorValue);
    const [janrainCode, SetjanrainCode] = React.useState('');
    const [localeCode, SetlocaleCode] = React.useState('');
    const [href, Sethref] = React.useState('');

    const getAffiliateByQuery = async (query) => {
        if(!query){query=''}
        return !isLoading ?  finalAffiliateByQueryData : null;
    }; 

    const fetchAffiliateByQueryData = async (query) => {
         if(!query){query=''}
            let data = await axios.post(userProfileService.baseUrl +`/management/api/user/GetAffiliateByQuery?` + query, {}, userProfileService.authHeaders);
            return (data);
        

    }  

    const {data : finalAffiliateByQueryData, isLoading} = useQuery({
        queryKey: [country],
        queryFn: async () => {
            let country_code = sessionState.sessionValue.payload.affiliate[0].country_code;
            return await fetchAffiliateByQueryData("country_code=" + country_code)

        },

    })
    React.useEffect(() => {
        SetSessionState(selectorValue);
        // const [languageList, SetLangList] = React.useState(["French", "Dutch" , "German"]);
        if(sessionState.sessionValue && sessionState.sessionValue.payload && sessionState.sessionValue.payload.affiliate[0] && sessionState.sessionValue.payload.affiliate[0].country_name){
            // let arr = []

            // arr.push({locale:'',language:''})
            // if(sessionState.sessionValue.payload.affiliate[0].country_name == 'Switzerland'){
            //      arr.push({locale:'fr-FR',language:"French"})

            //     arr.push({locale:'de-CH',language:"German"})
            // }else{
            //     arr.push({locale:'nl-BE',language:"Dutch"})
            //     arr.push({locale:'fr-FR',language:"French"})
            // }
            // SetLangList(arr)
        }
    }, [selectorValue, sessionState])
    React.useEffect(() => {
        if (sessionState.sessionValue.payload.affiliate[0] && sessionState.sessionValue.payload.affiliate[0].country_code != "") {
            let country_code = sessionState.sessionValue.payload.affiliate[0].country_code;
            getAffiliateByQuery("country_code=" + country_code).then((data) => {
                if(data){                let countries = data.data;
                // let jcode = countries[0].janrain_code
                // SetjanrainCode(jcode)
                SetlocaleCode(countries[0].locale)
                SetCountry(sessionState.sessionValue.payload.affiliate[0].country_name);
                // SetLang(getCode(country_code).language);
                let locale;
                if (countries[0].locale) {
                    locale = countries[0].locale;
                } else {
                    locale = localStorage.getItem('lang')
                }


                let url = process.env.REACT_APP_PASSPORT_BASE_URL + locale + "/editprofile?flow=p&access_token=" + token + "&url=" + window.location.origin + "/my-profile/";

            
                Sethref(url)
            }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [janrainCode, localeCode, token, sessionState,finalAffiliateByQueryData])

    React.useEffect(() => {
        if (sessionState.sessionValue?.payload?.loginroute) {
            SetToken(sessionState.sessionValue.payload.token);
            if (sessionState.sessionValue.payload.loginroute == "passport") {
                setFormData({
                    user_id: sessionState.sessionValue.payload.decodedToken.id,
                    user_fname: sessionState.sessionValue.payload.decodedToken.firstName,
                    user_lname: sessionState.sessionValue.payload.decodedToken.lastName,
                    user_title: '',
                    user_email: sessionState.sessionValue.payload.decodedToken.email,
                    user_country: sessionState.sessionValue.payload.affiliate[0].country_name,
                    user_lang: getCode(sessionState.sessionValue.payload.affiliate[0].country_code).language,
                    user_role: sessionState.sessionValue.payload.roles
                });
                setHcpRole(true);
                setIsPassportLogin(true);
                let param = "id=" + sessionState.sessionValue.payload.user.id
                // alert("sdf")
                userProfileService.GetUsersByQuery(param).then((data) => {
                    // alert(country)
                    if (data.data.length != 0) {
                        setIsExistingUser(true);
                        // SetLang(sessionState.sessionValue.payload.user.language);
                        // alert(country)
                        
                        if (country === "Switzerland" || country === "Belgium") {
                            let arr = [];
                            if (data.data[0].language == "de-CH") {
                                arr.push({locale:'de-CH',language:"German"})
                                SetLang({locale:'de-CH',language:"German"})
                            }
                            else if (data.data[0].language == "fr_CH") {
                                arr.push({locale:'fr_CH',language:"French"})
                                SetLang({locale:'fr_CH',language:"French"})
                            }
                            else if (data.data[0].language == "nl-BE") {
                                arr.push({locale:'nl-BE',language:"Nederlands"})
                                SetLang({locale:'nl-BE',language:"Nederlands"})

                            }else if (data.data[0].language == "fr-BE") {
                                arr.push({locale:'fr-BE',language:"Français"})
                                SetLang({locale:'fr-BE',language:"Français"})
                            }
                            else{
                                SetLang({locale:'',language:props.translations['Language'] ? props.translations['Language'] : 'Language'})
                            }
                            let arr1 = []
                            // arr1.push({locale:'',language:props.translations['Language'] ? props.translations['Language'] : 'Language'})
                        if(sessionState.sessionValue.payload.affiliate[0].country_name == 'Switzerland'){
                            arr1.push({locale:'de-CH',language:"German"})
                            arr1.push({locale:'fr_CH',language:"French"})
                        }else{
                            arr1.push({locale:'nl-BE',language:"Nederlands"})
                            arr1.push({locale:'fr-BE',language:"Français"})
                        }
                        SetLangList(arr1)
                        }
                        let userTA = data.data[0].therapeutic_area;
                        let categories = userTA && Array.isArray(userTA) ? userTA : [];
                        let categoriesArr = [];
                        let checkboxTrue = [];
                        if (categories?.length > 0) {
                            for (let i = 0; categories.length > i; i++) {
                                let ta_id = categories[i].therapeutic_area_id;
                                let brand_id = categories[i].brand_id;
                                let ind_id = categories[i].indication_id;
                                let UID = ta_id + "_" + brand_id;
                                if (ind_id !== null) { UID += "_" + ind_id; }
                                categoriesArr.push(UID);
                                checkboxTrue[brand_id] = true;
                            }
                        }
                        setUserDataTA(checkboxTrue);
                        SetSelectedCategories(categoriesArr);
                        setUserData(data.data[0]);
                    } else {
                        setIsExistingUser(false);
                    }
                }).catch((err) => {});
            } else {
                let idToSet = sessionState.sessionValue.payload.user?.okta_id?sessionState.sessionValue.payload.user?.okta_id : sessionState.sessionValue.payload.user?.lilly_id
                let idToSet2 = idToSet?idToSet:sessionState.sessionValue.payload.user?.id 
                setFormData({
                    user_id: idToSet2,
                    user_fname: sessionState.sessionValue.payload.user?.first_name,
                    user_lname: sessionState.sessionValue.payload.user?.last_name,
                    user_title: '',
                    user_email: sessionState.sessionValue.payload.user.user_email,
                    user_country: sessionState.sessionValue.payload.affiliate[0]?.country_name,
                    user_lang: getCode(sessionState.sessionValue.payload.affiliate[0]?.country_code).language,
                    user_role: sessionState.sessionValue.payload.roles
                });
                for (let i = 0; i < sessionState.sessionValue.payload.roles.length; i++) {
                    if (sessionState.sessionValue.payload.roles[i].role_name == "hcp") {
                        setHcpRole(true);
                    }
                }
                let param = "user_email=" + sessionState.sessionValue.payload.user.user_email;
                userProfileService.GetUsersByQuery(param).then((data) => {
                    let userTA = data.data[0].therapeutic_area;
                    let categories = userTA && Array.isArray(userTA) ? userTA : [];
                    let categoriesArr = [];
                    let checkboxTrue = {};
                    if (categories.length > 0) {
                        for (let i = 0; categories.length > i; i++) {
                            let ta_id = categories[i].therapeutic_area_id;
                            let brand_id = categories[i].brand_id;
                            let ind_id = categories[i].indication_id;
                            let UID = ta_id + "_" + brand_id;
                            if (ind_id !== null) { UID += "_" + ind_id; }
                            categoriesArr.push(UID);
                            checkboxTrue[brand_id] = true;
                        }
                    }
                    setUserDataTA(checkboxTrue);
                    SetSelectedCategories(categoriesArr);
                    setUserData(data.data[0]);
                    setIsExistingUser(true);
                }).catch((err) => {});
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectorValue, sessionState, country]);

    const handleSubmit = () => {
        let categories = [];
        for (let i = 0; selectedCategories.length > i; i++) {
            const catIDs = selectedCategories[i].split("_");
            if (catIDs.length > 0) {
                categories.push({
                    therapeutic_area_id: catIDs[0] ? catIDs[0] : null,
                    brand_id: catIDs[1] ? catIDs[1] : null,
                    indication_id: catIDs[2] ? catIDs[2] : null
                });
            }
        }
        let languageSelected ="";
        if(language.locale){
            languageSelected = language.locale
        }
        let model = {
            id: UserData.id,
            octa_id: UserData.octa_id,
            therapeutic_area: categories,
            title: title,
            lang: languageSelected,
            email_id: formData.user_email
        };
        localStorage.setItem("languageSimulation",languageSelected)
        localStorage.setItem('lang',language?.locale)
        localStorage.setItem('MultiLang',language?.locale)
        // alert(sessionState.sessionValue.simulationCheck)
        userProfileService.UpdateUser(model).then((data) => {
            let jwtToken  = localStorage.getItem('jwtToken');
            let id_token = localStorage.getItem('id_token');
            if(id_token) {
                if(!sessionState.sessionValue.payload.simulationCheck){
                    let federateObject =jose.decodeJwt(id_token);
                    let sessionService = new SessionService(jwtToken);
                    let nameQuery = '?user_email=' + formData.user_email;
                    sessionService.GetSessionValue(currentStore, nameQuery, federateObject).then((sessionResponse) => {
                        sessionResponse.token = jwtToken;
                        dispatch(setSession(sessionResponse))
                    });
                }
                else{
                    let federateObject =jose.decodeJwt(id_token);
                    let sessionService = new SessionService(jwtToken);
                    let specialty= sessionState.sessionValue.payload.decodedToken.specialty;
                    let profession = sessionState.sessionValue.payload.decodedToken.profession;
                    let locale = sessionState.sessionValue.payload.locale;
                    let country =sessionState.sessionValue.payload.decodedToken.countryCode;
                    let nameQuery = '?okta_id=' + currentStore?.sessionValue?.payload?.user?.okta_id;
                    userProfileService.StartSimulation(country,locale,profession,specialty,dispatch).then((sessionResponse) => {
                        if(sessionResponse){
                            if(Object.keys(sessionResponse).length>0){
                                sessionResponse.token = jwtToken;
                                dispatch(setSession(sessionResponse))
                            }
                        }
                        
                    });
                }
                
                
            } else {
                let passportToken = localStorage.getItem('access_token');
                console.log("passportToken", passportToken);
                if (passportToken.length >= 2 && !sessionState.sessionValue.payload.simulationCheck) {
                    let decoded_accessToken =jose.decodeJwt(passportToken);
                    if(decoded_accessToken?.email) {
                        let sessionService = new SessionService(localStorage.getItem('jwtToken'));
                        sessionService.GetPassportSession(decoded_accessToken).then((passportSession) => {
                            passportSession.token = localStorage.getItem('jwtToken');
                            dispatch(setSession(passportSession));
                        })
                    }
                }
            }
        }).catch((err) => {});
        modalFunc.current();
    }

    /**Breadcrumb for user profile */
    const BreadcrumbData = [
        { value: props.translations['Home'] ? props.translations['Home'] : 'Home', path: '/home' },
        { value: 'My Profile', path: '/my-profile' }
    ];

    const cancelButton = () => {
        if (isTherapeuticDirty) {
            SetcancelPopup(true);
        } else {
            navigate('/home')
        }
    }

    React.useEffect(() => {
        let passportToken = window.location.href.split('=');
        let params = (new URL(window.location.href)).searchParams;
        passportToken = params.get('access_token')? params.get('access_token') : '';
        console.log("passportToken", passportToken);
        if (passportToken.length >= 2 && !window.location.href.includes('code')) {
            localStorage.setItem('access_token', passportToken);
            let decoded_accessToken =jose.decodeJwt(passportToken);
            
            localStorage.setItem('user_info', JSON.stringify(decoded_accessToken));
            if(decoded_accessToken?.email) {
                let sessionService = new SessionService(localStorage.getItem('jwtToken'));
                sessionService.PushHCPId(decoded_accessToken.customerId);
                sessionService.PushJanrainId(decoded_accessToken.id);
                sessionService.GetPassportSession(decoded_accessToken).then((passportSession) => {
                    passportSession.token = localStorage.getItem('jwtToken');
                    dispatch(setSession(passportSession));
                })
            }
        }
    }, []);

    return (
        <Grid>
            <Modal open={cancelPopup} onClose={() => SetcancelPopup(false)}>
                <Grid className='paper modalstyle uservalidationPopup'>
                    <Grid className="modal-title">
                        <button className='closeModal' onClick={() => SetcancelPopup(false)}></button>
                    </Grid>
                    <Grid className="modal-body">
                        <div className="m20">
                            <Alert severity="warning">{props.translations['All the changes will not be saved!'] ? props.translations['All the changes will not be saved!'] : 'Your Changes will be lost!'}</Alert>
                        </div>
                    </Grid>
                   
                    <Grid className="modal1FoBtn modal-footer ">
                        <button type="button" onClick={() => SetcancelPopup(false)} className="modalBtn ">{props.translations['Cancel'] ? props.translations['Cancel'] : 'Cancel'}</button>
                        <button type="button" onClick={() => navigate('/home')
                        } className="modalBtn ">{props.translations['OK'] ? props.translations['OK'] : 'OK'}</button>
                    </Grid>
                </Grid>
            </Modal>
            <Breadcrumb translations={props.translations} setFilter={props.setFilter} BreadcrumbData={BreadcrumbData} />
            <Grid container className="profileContainer">
                <Grid container className="profileHeader">
                    <Typography variant="h6">{props.translations['My Profile'] ? props.translations['My Profile'] : 'My Profile'}</Typography>
                </Grid>
                <Grid container item xs={12} lg={12} className='content'>
                    {country !== '' && !exceptionPassportCountries.includes(country) ?
                        isPassportLogin ? 
                        <Grid item xs={12} lg={4}>
                            {roleHCP ? <Button variant="outlined" href={href} className="editProfileBtn">{props.translations['Edit My Profile'] ? props.translations['Edit My Profile'] : 'Edit My Profile'}</Button> : <Grid className="noView"></Grid>}
                        </Grid> 
                        : ""
                    : ""}
                    <Grid container item xs={12} lg={8}>
                        <Grid container spacing={2} className='txtCntrSm'>

                            {!isPassportLogin ?
                                <Grid item xs={12} sm={6} md={6} lg={6} className='txtCntrSm'>

                                    <TextField
                                        id="standard-full-width"
                                        label="Employee ID"
                                        className='textfiledBx labeIp-user'
                                        placeholder="Enter Employee ID"
                                        value={formData.user_id || ''}
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={true}
                                        onChange={(event, newValue) => {
                                            setFormData(newValue);
                                        }}
                                    />
                                </Grid> : ("")}

                            <Grid item xs={12} sm={6} md={6} lg={6} className='txtCntrSm'>
                                <TextField
                                    id="standard-full-width"
                                    label={props.translations['First Name'] ? props.translations['First Name'] : 'First Name'}
                                    className='textfiledBx labeIp-user'
                                    placeholder={props.translations['First Name'] ? props.translations['First Name'] : 'Enter First Name'}
                                    fullWidth
                                    value={formData.user_fname || ''}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} className='txtCntrSm'>
                                <TextField
                                    id="standard-full-width"
                                    label={props.translations['Last Name'] ? props.translations['Last Name'] : 'Last Name'}
                                    className='textfiledBx labeIp-user'
                                    placeholder={props.translations['Last Name'] ? props.translations['Last Name'] : 'Enter Last Name'}
                                    fullWidth
                                    value={formData.user_lname || ''}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} className='txtCntrSm'>
                                <TextField
                                    id="standard-full-width"
                                    label={props.translations['Title'] ? props.translations['Title'] : 'Title'}
                                    className='textfiledBx labeIp-user'
                                    placeholder={props.translations['Title'] ? props.translations['Title'] : 'Enter Title'}
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={true}
                                />
                            </Grid>
                            {!isPassportLogin ? <Grid item xs={12} sm={6} md={6} lg={6} className='txtCntrSm'>
                                <TextField
                                    id="standard-full-width"
                                    label={props.translations['Email'] ? props.translations['Email'] : 'Email'}
                                    className='textfiledBx labeIp-user'
                                    placeholder="Enter Email"
                                    fullWidth
                                    value={formData.user_email || ''}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={true}
                                />
                            </Grid> : '' }
                            <Grid item xs={12} sm={6} md={6} lg={6} className='txtCntrSm'>
                                <TextField
                                    id="standard-full-width"
                                    label={props.translations['Country'] ? props.translations['Country'] : 'Country'}
                                    className='textfiledBx labeIp-user'
                                    placeholder="Enter Country"
                                    fullWidth
                                    value={formData.user_country}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} className='txtCntrSm languageForm'>
                                {country === "Switzerland" || country === "Belgium" ?
                                    <Grid><FormControl component="fieldset" className='formControl'>
                                        <FormLabel component="legend">{props.translations['Language'] ? props.translations['Language'] : 'Language'}</FormLabel>
                                        <Autocomplete
                                            options={languageList}
                                            value={language}
                                            getOptionLabel={(option) => typeof option === 'string' ? option : option.language!= '' && option.locale!= '' && language.language !='' ?option.language:props.translations['Language'] ? props.translations['Language'] : 'Language'  }
                                            onChange={(e, value) => {
                                                SetLang(value);
                                            }}
                                            label="Language"
                                            renderInput={(params) => <TextField {...params} placeholder={props.translations['Language'] ? props.translations['Language'] : 'Language'}name="speaker" size="small" InputLabelProps={{ shrink: false }} variant="outlined" />}
                                        />
                                    </FormControl>
                                    </Grid> :
                                    <TextField
                                        id="standard-full-width"
                                        label={props.translations['Language'] ? props.translations['Language'] : 'Language'}
                                        className='textfiledBx labeIp-user'
                                        placeholder="Enter Language"
                                        fullWidth
                                        value={formData.user_lang}
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={true}
                                    />}
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>


            </Grid>
            <Grid className="greySection">
               <Grid className="brandArea">
                    <TherapeuticArea SetIsTherapeuticDirty={SetIsTherapeuticDirty}  selectedCategories={selectedCategories} SetSelectedCategories={SetSelectedCategories} TADetails={UserDataTA} translations={props.translations} />
                </Grid>
                <Grid container item xs={12} lg={12} className='profileFooter'>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        {/* <button className='cancelBtn desktopOnly' onClick={() => {cancelButton(); navigate('/home')}}>{props.translations['Cancel'] ? props.translations['Cancel'] : 'Cancel'}</button> */}
                        <button id='desktop-btn' className='cancelBtn desktopOnly' onClick={() => {cancelButton()}}>{props.translations['Cancel'] ? props.translations['Cancel'] : 'Cancel'}</button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} className="alignBtn">
                        <button type='submit' className='submitBtn' onClick={handleSubmit}>{props.translations['Save'] ? props.translations['Save'] : 'Save'}</button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        {/* <button className='cancelBtn mobileOnly' onClick={() => {cancelButton(); navigate('/home')}}>{props.translations['Cancel'] ? props.translations['Cancel'] : 'Cancel'}</button> */}
                        <button id='mobile-btn' className='cancelBtn mobileOnly' onClick={() => {cancelButton()}}>{props.translations['Cancel'] ? props.translations['Cancel'] : 'Cancel'}</button>
                    </Grid>
                </Grid>
            </Grid>
            <ModalDialog modalFunc={modalFunc} displayMessage={props.translations['Your settings have been successfully saved!'] ? props.translations['Your settings have been successfully saved!'] : 'Your settings have been successfully saved!'}/>
        </Grid>
    )
}

export default IntUserProfile