import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ContentService } from '../Service/content-service';
import { KalturaService } from '../Service/KalturaService';
import Modal from '@material-ui/core/Modal';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import PreviewEmbedVideo from './PreviewEmbedVideo';
import KalturaPlayerWidget from './KalturaPlayerWidget';

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: `${theme.palette.common.white} !important`,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        border: '1px solid #ffffff',
        padding: 0,
        margin: 0
    },
    [`& .${tooltipClasses.arrow}`]: {
        "&:before": {
            border: "1px solid #ffffff"
        },
        color: `#ffffff !important`,

    }
}));

const PreviewAndType = ({updateS3UploadState, ...props}) => {
    let contentService = new ContentService();
    let kalturaService = new KalturaService();
    const [affiliateData, setAffiliateData] = React.useState([]);
    const [affiliateMap, SetAffiliateMap] = React.useState({});
    const [localUpload, SetlocalUpload] = React.useState(false);
    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarType, setSnackbarType] = React.useState('');
    const [snackbarMsg, setSnackbarMsg] = React.useState('')
    const [openEmbedSection, setOpenEmbedSection] = React.useState(false);
    const [thumbnailURL, setThumbnailURL] = React.useState('');

    React.useEffect(() => {
        GetUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.owner, props.secondOwner]);
    React.useEffect(() => {
        let listOfAffiliates = [];
        for (let i = 0; i < props.affiliates.length; i++) {
            if (affiliateMap[props.affiliates[i]]) {
                listOfAffiliates.push(affiliateMap[props.affiliates[i]]);
            }
        }
        setAffiliateData(listOfAffiliates);
    }, [props.affiliates, affiliateMap]);
    React.useEffect(() => {
        GetUsers();
        contentService.GetAllAffiliates().then((data) => {
            let jsondata = data.data;
            if (Array.isArray(jsondata)) {
                if (jsondata.length > 0) {
                    let dict = {};
                    for (let i = 0; i < jsondata.length; i++) {
                        dict[jsondata[i].country_code] = jsondata[i];
                    }
                    SetAffiliateMap(dict);
                }
            }


        }).catch((err) => {});
    }, []);

    const GetUsers = () => {
        contentService.GetAllUsers().then((data) => {
            let jsondata = data.data;
            let arr = []
            for (let i = 0; i < jsondata.length; i++) {
                if (jsondata[i].lilly_id != null && jsondata[i].lilly_id !== ''
                    && jsondata[i].first_name !== null && jsondata[i].first_name !== '' &&
                    jsondata[i].last_name !== null && jsondata[i].last_name !== '') {
                    jsondata[i].fullName = `${jsondata[i].first_name} ${jsondata[i].last_name}(${jsondata[i].lilly_id})`
                    arr.push(jsondata[i])
                }
            }
            props.setSecondOwnerArray(arr)
            if (props.id !== '') {
                let ownerId = props.owner;
                let secondOwnerId = props.secondOwner;
                let ownerSecondOwnerEmailList = [];
                for (let i = 0; i < jsondata.length; i++) {
                    if (jsondata[i].id === ownerId) {
                        ownerSecondOwnerEmailList.push(jsondata[i].user_email)
                    }
                    if (jsondata[i].id === secondOwnerId) {
                        props.setSecondOwnerVal(jsondata[i])
                        ownerSecondOwnerEmailList.push(jsondata[i].user_email)
                    }
                }
                props.SetOwnerSecondOwnerEmail(ownerSecondOwnerEmailList);
            }
        }).catch((err) => {});
    }

    const UploadImage = async (file) => {
        const key = 'Country/Banner/' + (new Date()).getTime().toString() + '-' + file.name;
        const imageByteSize = file.size;
        if (imageByteSize > 2097152) { //2MB Limit
            updateS3UploadState({status: 'error', error: {fileSize: true}});
            return;
        }

        const imageType = file.type;
        const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        if (!validImageTypes.includes(imageType)) {
            updateS3UploadState({status: 'error', error: {fileType: true}});
            return;
        }

        try {
            const fileData = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(new Error('File reading failed: ' + error));
                reader.readAsDataURL(file);
            });

            const imageData = await new Promise((resolve, reject) => {
                const image = new Image();
                image.onload = () => resolve(image);
                image.onerror = (error) => reject(new Error('Image reading failed: ' + error));
                image.src = fileData;
            });

            if (!(imageData.width === 1280 && imageData.height === 720)) {
                updateS3UploadState({status: 'error', error: {dimension: true}});
                return;
            }

            setThumbnailURL(fileData);
            kalturaService.UploadKalturaThumbnail(props.kaltura_id, file);
            updateS3UploadState({status: 'uploading', error: null});

            await contentService.readAndUploadFileToS3(file, key).then(() => {
                updateS3UploadState({status: 'idle', error: null});
                props.SetTempThumbnail(key);
            }).catch((error) => {
                updateS3UploadState({status: 'error', error: {upload: true}});
                contentService.UpdateS3ImagesErrorLogs({
                    user_id: props.created_by,
                    thumbnail_name: key,
                    log_event_type: "Content",
                    thumbnail_size: imageByteSize,
                    thumbnail_height: imageData.height,
                    thumbnail_width: imageData.width,
                    thumbnail_type: imageType,
                    record_id: props.id,
                    error_code: error.status || '',
                    error_message: error.message || ''
                });
            });

        } catch (error) { 
            updateS3UploadState({status: 'error', error: {upload: true}});
        }

        props.setcontentlost(true)
    }

    const validURL = (str) => {
        try {
            new URL(str);
            return true;
        }
        catch (err) {
            return false;
        }
    }
    React.useEffect(() => {
        const timer = setTimeout(() => {

            if (!validURL(`'${props.tempThumbnail}'`)) {
                contentService.GetS3File(props.tempThumbnail).then((data) => {
                    if (data) {
                        setThumbnailURL(data);
                    }
                })
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, [props.tempThumbnail]);

    const [openEmbedModal, setOpenEmbedModal] = React.useState(false);

    const handleUpdateVideo = async (file) => {
        let fileValidationArray = file.name.split('.');
        if (fileValidationArray.includes('mp3') ||
            fileValidationArray.includes('mp4')) {
            SetlocalUpload(true);
            props.SetShowPreviewOfVideo(false)
            const uploadToken = await kalturaService.GetKalturaUploadToken();
            const mediaEntry = await kalturaService.GetMediaEntry();
            if (uploadToken && mediaEntry){
                kalturaService.UploadFile(file, uploadToken, mediaEntry, props.SetKalturaId, props.SetThumbnailUrl, props.SetUploadPercentage, props.SetDuration, props.SetShowPreviewOfVideo);
                props.setcontentlost(true)
            }
            return
        } else {
            setSnackbarOpen(true);
            setSnackbarType('error');
            setSnackbarMsg('Content file accepts .mp3/.mp4 format');
            return
        }

    }

    const getFilename = (name) => {
        let imageString;
        const spitName = name.split('/')
        if (spitName) {
            imageString = spitName[2];
            let index = imageString?.indexOf('-');
            if (index !== -1) {
                imageString = imageString?.substring(index + 1);
            }
        }
        return imageString;

    }

    return (
        <>
            <Snackbar
                open={snackbarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={6000}
                onClose={() => { setSnackbarOpen(false) }}>
                <Alert
                    className={`snackbar ${snackbarType}`}
                    onClose={() => { setSnackbarOpen(false) }}
                    severity={`${snackbarType}`}>
                    {snackbarMsg}
                </Alert>
            </Snackbar>
            <Modal open={openEmbedModal} onClose={() => setOpenEmbedModal(false)}>
                <PreviewEmbedVideo kalturaId={props.kaltura_id} contentId={props.id} thumbnailURL={thumbnailURL} onClose={() => setOpenEmbedModal(false)}/>
            </Modal>
            <Grid container className="preview-section grey_row">
                <Grid className="content" item lg={12} sm={12} md={12} xs={12}>
                    <Typography className='ds_title'>
                        Enter your Video or Podcast Details
                    </Typography>
                </Grid>
            </Grid>
            <Grid container className="preview-section grey_row spacingissue" spacing={1}>
                <Grid item lg={6} sm={6} md={6} xs={12}>
                    <div className='thumbnail-videodetails'>
                        <div className='videos-header'>
                            {props.IskalturaUploaded && !localUpload ?
                                <>
                                    {!props.id ? <><div className='video-progress'>
                                        <span style={{ width: "100%" }}></span>
                                    </div>
                                        <div> 100% </div></> : ""}
                                </> :
                                <>
                                    {!props.id ? <> <div className='video-progress'>
                                        <span style={{ width: props.showPreviewOfVideo && props.uploadPercentage ? props.uploadPercentage + "%" : (!props.uploadPercentage ? props.uploadPercentage : props.uploadPercentage - 1) + "%" }}></span>
                                    </div>
                                        <div> {props.showPreviewOfVideo && props.uploadPercentage ? props.uploadPercentage : !props.uploadPercentage ? props.uploadPercentage : props.uploadPercentage - 1}% </div> </> :
                                        localUpload ? <>
                                            <div className='video-progress'>
                                                <span style={{ width: props.showPreviewOfVideo && props.uploadPercentage ? props.uploadPercentage + "%" : (!props.uploadPercentage ? props.uploadPercentage : props.uploadPercentage - 1) + "%" }}></span>
                                            </div>
                                            <div> {props.showPreviewOfVideo && props.uploadPercentage ? props.uploadPercentage : !props.uploadPercentage ? props.uploadPercentage : props.uploadPercentage - 1}% </div>
                                        </> : ""
                                    }
                                </>
                            }
                            <div className='video-info'>
                                Kaltura ID : <span>{props.kaltura_id}</span>
                            </div>
                        </div>
                        <div className='upload-section'>
                            {(props.showPreviewOfVideo && props.thumbnailUrl != '') ? <KalturaPlayerWidget kalturaId={props.kaltura_id} playerWrapperId='kaltura_video_player' thumbnailURL={thumbnailURL} className="kaltura_video"/> : ''}
                            <div className={props.enableFields && props.showPreviewOfVideo ? 'upload-thumbnail' : 'upload-thumbnail disabled'}>
                                <div className='upload-thubmail-file'>
                                    <input title="No file chosen" accept="image/*" name="thumbnail" id="thumbnail-button-file" multiple type="file" onChange={(event) => {
                                        UploadImage(event.target.files[0])
                                    }} />
                                    <label htmlFor={props.enableFields && props.showPreviewOfVideo ? 'thumbnail-button-file' : ''}>
                                        <svg className='uploadimage' width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19 13V14.2C19 15.8802 19 16.7202 18.673 17.362C18.3854 17.9265 17.9265 18.3854 17.362 18.673C16.7202 19 15.8802 19 14.2 19H5.8C4.11984 19 3.27976 19 2.63803 18.673C2.07354 18.3854 1.6146 17.9265 1.32698 17.362C1 16.7202 1 15.8802 1 14.2V13M15 6L10 1M10 1L5 6M10 1V13" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <span className='text'>Upload Thumbnail</span>
                                        {props.enableFields ?
                                            <ClickAwayListener onClickAway={() => { setTooltipOpen(false) }}>
                                                <CustomTooltip
                                                    arrow
                                                    placement="bottom"
                                                    open={tooltipOpen}
                                                    onOpen={() => setTooltipOpen(true)}
                                                    onClose={() => setTooltipOpen(false)}
                                                    title={
                                                        <React.Fragment>
                                                            <div>Dimension: 1280x720px</div>
                                                            <div>Format: jpg, png</div>
                                                            <div>Size: less than 2MB</div>
                                                        </React.Fragment>
                                                    }
                                                >

                                                    <InfoOutlinedIcon onClick={() => setTooltipOpen(true)} className='infoicon' />
                                                </CustomTooltip>
                                            </ClickAwayListener>
                                            : ''}
                                    </label>
                                    <div className='file-details'>
                                        <span>{props.tempThumbnail ? getFilename(props.tempThumbnail) : ""}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item lg={6} sm={6} md={6} xs={12} >
                    <div className='infosection'>
                        <div className='updateandlinks'>
                            {/* <div className={props.id !== '' && props.enableFields ? 'updatevideo' : 'updatevideo disabled'}> */}
                            <label className={props.id !== '' && props.enableFields ? 'updatevideo' : 'updatevideo disabled'} htmlFor='uploadFile'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17 5.1255C19.1213 6.67103 20.5 9.17456 20.5 12.0001C20.5 16.6945 16.6944 20.5001 12 20.5001H11.5M7 18.8747C4.87867 17.3292 3.5 14.8256 3.5 12.0001C3.5 7.30568 7.30558 3.5001 12 3.5001H12.5M13 22.4001L11 20.4001L13 18.4001M11 5.6001L13 3.6001L11 1.6001" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                                Update Video
                            </label>
                            {props.enableFields && props.id ?
                                <input id='uploadFile' hidden type="file" accept="audio/*,video/*" onChange={(e) => handleUpdateVideo(e.target.files[0])} />
                                : null}
                            {/* </div> */}

                            {props.id !== '' && props.isRolePublisher && props.embedvideovisible ?

                                <ClickAwayListener
                                    onClickAway={() => {
                                        setOpenEmbedSection(false)
                                    }}
                                >
                                    <div className={'embedvideo'}
                                        onClick={() => setOpenEmbedSection(!openEmbedSection)}>
                                        <div className='buttonsection'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16 18L22 12L16 6M8 6L2 12L8 18" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <span>
                                                Embed Video
                                            </span>
                                        </div>
                                        {openEmbedSection ?
                                            <ul class='dropdownsection'>
                                                {affiliateData.map((affiliate, index) => (
                                                    <li onClick={() => setOpenEmbedModal(true)} key={affiliate.country_code}>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16 18L22 12L16 6M8 6L2 12L8 18" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                        <span>Embed Video for {affiliate.country_name}</span></li>
                                                ))
                                                }
                                            </ul>
                                            : null}
                                    </div>
                                </ClickAwayListener>
                                : ""}

                        </div>
                        <div className='moreinfo'>
                            <div>
                                Status: - {props.id !== '' ? props.status : ''}
                            </div>
                            <div>
                                Upload Date: - {props.id !== '' ? props.recordingDate : ''}
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>

        </>
    )
}
export default PreviewAndType