import React from 'react';
import { FormHelperText, FormLabel, Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useTheme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { TranslationService } from '../services/translation-service';
import { Controller, useForm } from 'react-hook-form';

const TranslationCreate = (props) => {
  const [uniqueLocale, setuniqueLocale] = React.useState([]);
  const [uniqueKey, setuniqueKey] = React.useState([]);
  const activeUser = localStorage.getItem('userID');

  const theme = useTheme();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  React.useEffect(() => {
    let uLocale = [];
    let uText = [];
    for (let i = 0; i < props.data.length; i++) {
      if (!uLocale.includes(props.data[i].locale)) {
        uLocale.push(props.data[i].locale);
      }
      if (!uText.includes(props.data[i].key)) {
        uText.push(props.data[i].key);
      }
    }
    setuniqueLocale(uLocale);
    setuniqueKey(uText);
  }, [props.data]);

  /**Function submits all the data for the created user */
  const onSubmit = (formData) => {
    const data = {
      ...formData,
      status: 1,
      updated_by: activeUser,
      created_by: activeUser,
      created_at: new Date(),
      updated_at: new Date(),
    };
    const service = new TranslationService();
    service.translationCreate(data).then((data) => {
      props.closePopUp(false);
    });
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid className='paper modalstyle'>
        <Grid className='modal-title-2'>
          Translation Create:
          <button
            className='closeModal'
            onClick={() => props.closePopUp(false)}
          ></button>
        </Grid>
        <Grid className='modal-body'>
          <FormControl
            component='fieldset'
            fullWidth={false}
            className='formControl'
            size='small'
            error={!!errors.locale}
          >
            <FormLabel
              htmlFor='locale'
              className='font-weight-bold'
              required
            >
              Translation Locale
            </FormLabel>
            <Controller
              control={control}
              name='locale'
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  variant='outlined'
                  MenuProps={MenuProps}
                  value={field.value || ''}
                >
                  {uniqueLocale.map((locale, i) => (
                    <MenuItem
                      key={i}
                      value={locale}
                      style={getStyles(locale, locale, theme)}
                    >
                      {locale}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {!!errors.locale && (
              <FormHelperText>Translation locale is required.</FormHelperText>
            )}
          </FormControl>
          <FormControl
            component='fieldset'
            fullWidth={false}
            className='formControl'
            size='small'
            error={!!errors.key}
          >
            <FormLabel
              htmlFor='key'
              className='font-weight-bold'
              required
            >
              Translation Key
            </FormLabel>
            <Controller
              control={control}
              name='key'
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  variant='outlined'
                  MenuProps={MenuProps}
                  value={field.value || ''}
                >
                  {uniqueKey.map((locale, i) => (
                    <MenuItem
                      key={i}
                      value={locale}
                      style={getStyles(locale, locale, theme)}
                    >
                      {locale}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {!!errors.key && (
              <FormHelperText>Translation key is required.</FormHelperText>
            )}
          </FormControl>
          <FormControl
            component='fieldset'
            className='formControl'
            size='small'
          >
            <FormLabel
              htmlFor='text'
              className='font-weight-bold'
              required
            >
              Translation Text
            </FormLabel>
            <TextField
              className='textAreaStyle'
              variant='outlined'
              multiline
              minRows={3}
              error={!!errors.text}
              helperText={!!errors.text && 'Translation text is required.'}
              {...register('text', { required: true })}
            />
          </FormControl>
        </Grid>
        <Grid
          className='modal1FoBtn modal-footer'
          container
          justifyContent='flex-end'
        >
          <button
            type='button'
            className='modalBtn'
            onClick={() => {
              props.closePopUp(false);
            }}
          >
            Cancel
          </button>
          <button type='submit' className='modalBtn'>
            OK
          </button>
        </Grid>
      </Grid>
    </form>
  );
};
export default TranslationCreate;
